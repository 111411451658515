define("superfly/pods/application/components/tool-type-icon-distance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W3v1gaG2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M0,19.233l1.965-2.007L3.6,18.815l1.171-1.171L3.178,16.056l1.589-1.631,2.8,2.8L8.78,16.056l-2.8-2.8,1.589-1.589,1.589,1.589,1.213-1.213L8.78,10.453l1.589-1.589,2.8,2.8,1.171-1.213-2.76-2.8,1.589-1.589L14.76,7.652l1.213-1.213L14.341,4.85l1.631-1.589,2.76,2.8L19.944,4.85l-2.8-2.8L19.233,0,24,4.767,4.767,24Z\"],[11,\"transform\",\"translate(-13029.9 -5630.9)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/tool-type-icon-distance/template.hbs"
    }
  });

  _exports.default = _default;
});