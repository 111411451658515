define("superfly/services/analytics", ["exports", "ember-cli-analytics/services/analytics", "superfly/config/environment"], function (_exports, _analytics, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _analytics.default.extend({
    config: _environment.default
  });

  _exports.default = _default;
});