define("superfly/mixins/stitching-paywall-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    subscriptionManager: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var hasValidStitchingSubscription = Ember.get(this, 'subscriptionManager.hasValidStitchingSubscription');

      if (!hasValidStitchingSubscription) {
        this.transitionTo('frame.contact-sales');
      }

      this._super(transition);
    }
  });

  _exports.default = _default;
});