define("superfly/pods/shared/index/route", ["exports", "ember-ajax/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Shared',
    session: Ember.inject.service(),
    navigationManager: Ember.inject.service(),
    measurements: Ember.inject.service(),
    mainMap: Ember.inject.service(),
    model: function model(params, transition) {
      var _this = this;

      var shareId = Ember.get(transition, 'params.shared.shareId');
      return Ember.RSVP.hash({
        share: this.store.findRecord('share', shareId)
      }).catch(function (e) {
        if ((0, _errors.isUnauthorizedError)(e)) {
          transition.abort();

          _this.transitionTo('shared.locked', shareId);
        } else if ((0, _errors.isNotFoundError)(e)) {
          transition.abort();

          _this.transitionTo('shared.not-found', shareId);
        }
      });
    },
    afterModel: function afterModel(model) {
      Ember.set(this, 'session.shareToken', Ember.get(model, 'share.token'));
      return Ember.get(model, 'share.sharedFlight').then(function (result) {
        return Ember.RSVP.hash({
          overlays: Ember.get(result, 'overlays'),
          measurements: Ember.get(result, 'measurements'),
          stitchedAssets: Ember.get(result, 'stitchedAssets')
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var navigationManager = Ember.get(this, 'navigationManager');
      var measurements = Ember.get(this, 'measurements');
      var flight = Ember.get(model, 'share.sharedFlight');

      this._addTileOverlayToMap(flight);

      Ember.set(measurements, 'flight', flight);
      Ember.set(navigationManager, 'target', flight);
    },
    resetController: function resetController(controller, isExiting, transition) {
      this._super(controller, isExiting, transition);

      if (isExiting) {
        var measurements = Ember.get(this, 'measurements');
        measurements.clearShapesFromMap();
        measurements.clearActiveAction();
        Ember.set(measurements, 'flight', null);
      }
    },
    _addTileOverlayToMap: function _addTileOverlayToMap(flight) {
      var mapService = Ember.get(this, 'mainMap');
      var latestOverlay = Ember.get(flight, 'latestOverlay');

      if (latestOverlay) {
        mapService.addTileOverlay(latestOverlay);
      }
    }
  });

  _exports.default = _default;
});