define("superfly/pods/frame/sites/site/products/components/route-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    site: null,
    flight: null,
    isLinkProductsToSiteModalVisible: false,
    actions: {
      closeLinkProductsToSiteModal: function closeLinkProductsToSiteModal() {
        Ember.set(this, 'isLinkProductsToSiteModalVisible', false);
      },
      openLinkProductsToSiteModal: function openLinkProductsToSiteModal() {
        Ember.set(this, 'isLinkProductsToSiteModalVisible', true);
      }
    }
  });

  _exports.default = _default;
});