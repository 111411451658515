define("superfly/pods/frame/dashboard/folders/components/link-folder/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    integrations: Ember.inject.service(),
    isMenuVisible: false,
    tetherTarget: Ember.computed('id', function tetherTarget() {
      return "#".concat(Ember.get(this, 'elementId'), " a");
    }),
    actions: {
      toggleMenu: function toggleMenu() {
        this.send(Ember.get(this, 'isMenuVisible') ? 'closeMenu' : 'openMenu');
      },
      openMenu: function openMenu() {
        Ember.set(this, 'isMenuVisible', true);
      },
      closeMenu: function closeMenu() {
        Ember.set(this, 'isMenuVisible', false);
      }
    }
  });

  _exports.default = _default;
});