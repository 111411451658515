define("superfly/authorizers/custom", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    authorize: function authorize(data, callback) {
      callback('Authorization', data.accessToken);
    }
  });

  _exports.default = _default;
});