define("superfly/pods/frame/dashboard/flights/flight/components/share-flight/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    flight: null,
    passphrase: null,
    shareCreatedAt: null,
    isProtectionVisible: false,
    isModalVisible: false,
    isLoading: false,
    isLoadingAny: Ember.computed.or('isLoading', 'isLoadingSharing', 'isLoadingProtection'),
    isLoadingSharing: false,
    isLoadingProtection: false,
    share: Ember.computed.alias('flight.share'),
    isShowingUrl: Ember.computed.and('isSharingActive', 'url'),
    url: Ember.computed('shareCreatedAt', function url() {
      var id = Ember.get(this, 'share.id');
      return id ? "".concat(window.location.origin, "/shared/").concat(id) : null;
    }),
    modalContainerClass: Ember.computed('id', function containerClass() {
      return "".concat(Ember.get(this, 'componentCssClassName'), " custom-modal");
    }),
    isSharingActive: Ember.computed.bool('share.active'),
    isProtectionActive: Ember.computed.bool('share.password'),
    isToggleSharingDisabled: Ember.computed.or('isLoading'),
    isToggleProtectionDisabled: Ember.computed('isLoading', 'isSharingActive', function isToggleProtectionDisabled() {
      var _Ember$getProperties = Ember.getProperties(this, ['isLoading', 'isSharingActive']),
          isLoading = _Ember$getProperties.isLoading,
          isSharingActive = _Ember$getProperties.isSharingActive;

      return !(!isLoading && isSharingActive);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var password = Ember.get(this, 'flight.share.password');

      if (password) {
        Ember.set(this, 'passphrase', password);
        Ember.set(this, 'isProtectionVisible', true);
      }

      var share = Ember.get(this, 'share');

      if (share) {
        Ember.set(this, 'shareCreatedAt', Ember.get(share, 'createdAt'));
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.$('[data-toggle="tooltip"]').tooltip({
        animation: false,
        placement: 'right',
        delay: {
          show: 300,
          hide: 100
        },
        template: '<div class="tooltip quickbar-tooltip" role="tooltip"><div class="tooltip-container animated fade-in-right" style="position:relative;"><div class="arrow" style=" left: -5px;"></div><div class="tooltip-inner"></div></div></div>'
      });
    },
    enableSharing: function enableSharing() {
      var _this = this;

      var store = Ember.get(this, 'store');
      var flight = Ember.get(this, 'flight');
      var share = Ember.get(this, 'share.content');
      var shareIsNew = false;

      if (!share) {
        share = store.createRecord('share', {
          flight: flight
        });
        shareIsNew = true;
      }

      Ember.set(this, 'isLoadingSharing', true);
      Ember.set(share, 'active', true);
      return share.save().then(function () {
        if (shareIsNew) {
          Ember.set(_this, 'shareCreatedAt', Date.now());
        }
      }).catch(this.error).finally(function () {
        Ember.set(_this, 'isLoadingSharing', false);
        Ember.set(_this, 'share', share);
      });
    },
    disableSharing: function disableSharing() {
      var _this2 = this;

      var share = Ember.get(this, 'share.content');
      Ember.setProperties(this, {
        isLoadingSharing: true,
        isProtectionVisible: false,
        passphrase: null
      });
      Ember.setProperties(share, {
        active: false,
        password: null
      });
      return share.save().catch(this.error).finally(function () {
        return Ember.set(_this2, 'isLoadingSharing', false);
      });
    },
    enableProtection: function enableProtection() {
      Ember.set(this, 'isProtectionVisible', true);
    },
    disableProtection: function disableProtection() {
      Ember.set(this, 'isProtectionVisible', false);
      Ember.set(this, 'passphrase', null);
    },
    savePassphrase: function savePassphrase() {
      var _this3 = this;

      var share = Ember.get(this, 'share.content');

      if (Ember.get(this, 'isSharingActive')) {
        Ember.set(this, 'isLoadingProtection', true);
        Ember.set(share, 'password', Ember.get(this, 'passphrase'));
        return share.save().catch(this.error).finally(function () {
          return Ember.set(_this3, 'isLoadingProtection', false);
        });
      }
    },
    canCloseModal: Ember.computed('isLoadingAny', 'isProtectionVisible', 'passphrase', function canCloseModal() {
      var _Ember$getProperties2 = Ember.getProperties(this, ['isLoadingAny', 'isProtectionVisible', 'passphrase']),
          isLoadingAny = _Ember$getProperties2.isLoadingAny,
          isProtectionVisible = _Ember$getProperties2.isProtectionVisible,
          passphrase = _Ember$getProperties2.passphrase;

      if (isProtectionVisible) {
        return !isLoadingAny && passphrase;
      }

      return !isLoadingAny;
    }),
    onPassphraseChange: Ember.observer('passphrase', function onPassphraseChange() {
      Ember.run.debounce(this, 'savePassphrase', 600);
    }),
    error: function error() {
      Ember.get(this, 'notify').error("Couldn't change share settings");
    },
    actions: {
      share: function share() {
        Ember.set(this, 'isModalVisible', true);
      },
      close: function close() {
        Ember.set(this, 'isModalVisible', false);
      },
      copy: function copy() {
        var $em = this.$('.copy-board');
        $em[0].select();

        try {
          if (document.execCommand('copy')) {
            var $link = Ember.$('.link-animation');
            $link.show();
            Ember.run.later(this, function () {
              $link.hide();
            }, 500);
          }
        } catch (err) {//
        }
      },
      toggleSharing: function toggleSharing() {
        if (Ember.get(this, 'isSharingActive')) {
          this.disableSharing();
        } else {
          this.enableSharing();
        }
      },
      toggleProtection: function toggleProtection() {
        if (Ember.get(this, 'isProtectionVisible')) {
          this.disableProtection();
        } else {
          this.enableProtection();
        }
      }
    }
  });

  _exports.default = _default;
});