define("superfly/pods/auth/signup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qv3pmYPU",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"auth/signup/components/route-component\",null,[[\"model\",\"params\",\"appBeingUsed\",\"platformBeingUsed\",\"deviceString\",\"appVer\"],[[23,[\"model\"]],[23,[\"params\"]],[23,[\"appBeingUsed\"]],[23,[\"platformBeingUsed\"]],[23,[\"deviceString\"]],[23,[\"appVer\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/signup/template.hbs"
    }
  });

  _exports.default = _default;
});