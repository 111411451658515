define("superfly/models/folder", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    startDate: attr('date'),
    companyId: attr('number'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    name: attr('string'),
    folderId: attr('string'),
    isRoot: attr('boolean'),
    company: belongsTo('company'),
    folder: belongsTo('folder', {
      inverse: 'folders'
    }),
    folders: hasMany('folder', {
      inverse: 'folder'
    }),
    flights: hasMany('flight'),
    displayName: Ember.computed('name', function displayName() {
      var _Ember$getProperties = Ember.getProperties(this, ['id', 'name']),
          id = _Ember$getProperties.id,
          name = _Ember$getProperties.name;

      if (name) {
        return name;
      }

      if (id) {
        return id.split('-')[0];
      }

      return 'Folder';
    })
  });

  _exports.default = _default;
});