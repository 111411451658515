define("superfly/services/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    procore: Ember.inject.service(),
    egnyte: Ember.inject.service(),
    agx: Ember.inject.service(),
    hasConnections: Ember.computed.or('isProcoreConnected', 'isEgnyteConnected', 'isAgxConnected'),
    connections: Ember.computed('isProcoreConnected', 'isEgnyteConnected', function () {
      var _Ember$getProperties = Ember.getProperties(this, ['isProcoreConnected', 'isEgnyteConnected', 'isAgxConnected']),
          isProcoreConnected = _Ember$getProperties.isProcoreConnected,
          isEgnyteConnected = _Ember$getProperties.isEgnyteConnected,
          isAgxConnected = _Ember$getProperties.isAgxConnected;

      return [isProcoreConnected ? 'procore' : null, isEgnyteConnected ? 'egnyte' : null, isAgxConnected ? 'agx' : null].compact();
    }),
    isProcoreConnected: Ember.computed.bool('procore.isConnected'),
    isEgnyteConnected: Ember.computed.bool('egnyte.isConnected'),
    isAgxConnected: Ember.computed.bool('agx.isConnected')
  });

  _exports.default = _default;
});