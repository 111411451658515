define("superfly/pods/frame/dashboard/folders/components/folder-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['folder-explorer-card', 'folder'],
    classNameBindings: ['isBeingHovered:hovered', 'isBeingDragged:dragged', 'folder.id'],
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    integrations: Ember.inject.service(),
    navigationManager: Ember.inject.service(),
    folder: null,
    isBeingHovered: false,
    isBeingDragged: false,
    error: null,
    hasError: Ember.computed.bool('error'),
    isWaiting: false,
    isCamVisible: false,
    hasStartedDeleting: false,
    hasStartedMoving: false,
    hasStartedLinking: false,
    doubleClick: function doubleClick() {
      this.send('navigateToFolder');
    },
    touchEnd: function touchEnd() {
      this.send('navigateToFolder');
    },
    actions: {
      navigateToFolder: function navigateToFolder() {
        var afterTransition = Ember.get(this, 'afterTransition');

        if (afterTransition) {
          afterTransition();
        }

        this.router.transitionTo('frame.dashboard.folders.folder', Ember.get(this, 'folder.id'));
      },
      startDelete: function startDelete() {
        Ember.set(this, 'hasStartedDeleting', true);
      },
      stopDelete: function stopDelete() {
        Ember.set(this, 'hasStartedDeleting', false);
      },
      doDelete: function doDelete() {
        var _this = this;

        var folder = Ember.get(this, 'folder');
        var notify = Ember.get(this, 'notify');
        Ember.set(this, 'isWaiting', true);
        folder.destroyRecord().then(function () {
          Ember.set(_this, 'isWaiting', false);
          notify.success('Folder deleted successfully');
        }).catch(function (e) {
          Ember.set(_this, 'error', e);
          notify.error('Could not delete folder');
          Ember.set(_this, 'isWaiting', false);
        });
      },
      startMove: function startMove() {
        Ember.set(this, 'isCamVisible', false);
        Ember.set(this, 'hasStartedMoving', true);
      },
      stopMove: function stopMove() {
        if (!Ember.get(this, 'isDestroying') && !Ember.get(this, 'isDestroyed')) {
          Ember.set(this, 'hasStartedMoving', false);
        }
      },
      startRename: function startRename() {
        Ember.set(this, 'isCamVisible', false);
        Ember.set(this, 'hasStartedRenaming', true);
      },
      stopRename: function stopRename() {
        if (!Ember.get(this, 'isDestroying') && !Ember.get(this, 'isDestroyed')) {
          Ember.set(this, 'hasStartedRenaming', false);
        }
      },
      startLink: function startLink() {
        Ember.set(this, 'isCamVisible', false);
        Ember.set(this, 'hasStartedLinking', true);
      },
      stopLink: function stopLink() {
        if (!Ember.get(this, 'isDestroying') && !Ember.get(this, 'isDestroyed')) {
          Ember.set(this, 'hasStartedLinking', false);
        }
      }
    }
  });

  _exports.default = _default;
});