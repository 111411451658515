define("superfly/pods/application/components/tool-type-icon-comment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ISBV4AbC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M6.987,20a1.01,1.01,0,0,1-1-1V16.007H1.959a1.818,1.818,0,0,1-1.4-.592A2.072,2.072,0,0,1,0,13.974V1.959A1.9,1.9,0,0,1,1.959,0H18.041A1.9,1.9,0,0,1,20,1.959V13.974a2.072,2.072,0,0,1-.555,1.442,1.818,1.818,0,0,1-1.4.591h-6.1l-3.7,3.7a1.082,1.082,0,0,1-.7.3Zm1-5.989v3.105l3.105-3.105h6.95V1.959H1.959V13.974H7.985ZM3.993,4.991H16.007V7.024H3.993Zm0,4.03h9.02V10.98H3.993Z\"],[11,\"transform\",\"translate(-13029 -5568)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/tool-type-icon-comment/template.hbs"
    }
  });

  _exports.default = _default;
});