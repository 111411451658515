define("superfly/pods/frame/plans/select-plan/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Select',
    subscriptionManager: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var plansPromise = Ember.get(this, 'store').findAll('plan').then(function (plans) {
        var currentPlanName = Ember.get(_this, 'subscriptionManager.currentPlanName');
        var currentPlan = plans.filterBy('stripePlanId', currentPlanName)[0];
        return plans.filter(function (plan) {
          return currentPlan ? plan.compare(currentPlan) >= 0 : true;
        });
      });
      return Ember.RSVP.hash({
        plans: plansPromise
      });
    }
  });

  _exports.default = _default;
});