define("superfly/pods/frame/dashboard/components/auto-export-egnyte/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNameBindings: ['hasNoConnections:empty'],
    routing: Ember.inject.service('-routing'),
    egnyte: Ember.inject.service(),
    folder: null,
    folders: null,
    isLoading: true,
    folderStack: [],
    selectedFolder: null,
    loadNextSegment: Ember.on('init', Ember.observer('lastFolderPath', function loadNextSegment() {
      this.loadPath(Ember.get(this, 'lastFolderPath'));
    })),
    hasNoConnections: Ember.computed.empty('filteredConnections'),
    filteredConnections: Ember.computed.filter('egnyte.connections', function filteredConnections(item) {
      var folderId = Ember.get(this, 'folder.id');
      return item.botlink_folder === folderId;
    }),
    lastFolder: Ember.computed('folderStack.[]', function lastFolder() {
      var array = [].concat(Ember.get(this, 'folderStack'));
      return array.pop() || {};
    }),
    lastFolderPath: Ember.computed('lastFolder', function lastFolderPath() {
      var lastFolder = Ember.get(this, 'lastFolder');
      return Ember.get(lastFolder, 'path') || '';
    }),
    selectedPath: Ember.computed.alias('selectedFolder.path'),
    displayPath: Ember.computed('lastFolderPath', 'selectedPath', function displayPath() {
      var _Ember$getProperties = Ember.getProperties(this, ['lastFolderPath', 'selectedPath']),
          lastFolderPath = _Ember$getProperties.lastFolderPath,
          selectedPath = _Ember$getProperties.selectedPath;

      return selectedPath || lastFolderPath;
    }),
    hasLastFolderPath: Ember.computed.or('lastFolderPath', 'selectedPath'),
    hasBackAbility: Ember.computed.gt('folderStack.length', 0),
    modalContainerClass: Ember.computed('id', function containerClass() {
      return "".concat(this.get('componentCssClassName'), " modal modal-sm auto-export-modal");
    }),
    loadPath: function loadPath(path) {
      var _this = this;

      var _Ember$getProperties2 = Ember.getProperties(['isDestroyed', 'isDestroying']),
          isDestroyed = _Ember$getProperties2.isDestroyed,
          isDestroying = _Ember$getProperties2.isDestroying;

      if (!isDestroyed && !isDestroying) {
        Ember.setProperties(this, {
          selectedFolder: null,
          isLoading: true,
          folders: null
        });
      }

      Ember.get(this, 'egnyte').getDirectoriesAtPath(path).then(function (folders) {
        if (folders.length > 0) {
          var filtered = folders.filterBy('is_folder', true);

          if (!isDestroyed && !isDestroying) {
            Ember.set(_this, 'folders', filtered);
          }
        }
      }).finally(function () {
        if (!isDestroyed || !isDestroying) {
          Ember.set(_this, 'isLoading', false);
        }
      });
    },
    actions: {
      openAddExportModal: function openAddExportModal() {
        Ember.setProperties(this, {
          addExportModalVisible: true
        });
      },
      closeAddExportModal: function closeAddExportModal() {
        Ember.setProperties(this, {
          addExportModalVisible: false
        });
      },
      resetAddExport: function resetAddExport() {
        Ember.setProperties(this, {
          folderStack: [],
          selectedFolder: null
        });
      },
      stepBackward: function stepBackward() {
        var stack = Ember.get(this, 'folderStack');
        var length = Ember.get(stack, 'length');

        if (length > 0) {
          stack.removeAt(Ember.get(stack, 'length') - 1);
        }
      },
      selectDirectory: function selectDirectory(folder) {
        var _Ember$getProperties3 = Ember.getProperties(this, ['selectedPath', 'folderStack']),
            selectedPath = _Ember$getProperties3.selectedPath,
            folderStack = _Ember$getProperties3.folderStack;

        if (selectedPath === Ember.get(folder, 'path')) {
          folderStack.pushObject(folder);
        } else {
          Ember.set(this, 'selectedFolder', folder);
        }

        return false;
      },
      addExportCollection: function addExportCollection() {
        var _this2 = this;

        var _Ember$getProperties4 = Ember.getProperties(this, ['egnyte', 'folder', 'hasLastFolderPath']),
            egnyte = _Ember$getProperties4.egnyte,
            folder = _Ember$getProperties4.folder,
            hasLastFolderPath = _Ember$getProperties4.hasLastFolderPath;

        if (hasLastFolderPath) {
          egnyte.addConnection(Ember.get(folder, 'id'), Ember.get(this, 'displayPath')).then(function () {
            return _this2.send('closeAddExportModal');
          }).catch(function (e) {
            return Ember.warn(e);
          });
        }
      },
      deleteExportCollection: function deleteExportCollection(connection) {
        var _this3 = this;

        var egnyte = this.get('egnyte');
        egnyte.deleteConnection(Ember.get(connection, 'id')).then(function () {
          return _this3.send('closeAddExportModal');
        }).catch(function (e) {
          return Ember.warn(e);
        });
      }
    }
  });

  _exports.default = _default;
});