define("superfly/components/secure-intercom-io/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "58pJAud8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\"],[11,\"class\",\"fa fa-question-circle\"],[11,\"aria-hidden\",\"true\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/components/secure-intercom-io/template.hbs"
    }
  });

  _exports.default = _default;
});