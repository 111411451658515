define("superfly/mixins/route-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel: function beforeModel() {
      this.transitionTo(Ember.get(this, 'redirectToRoute'));
    }
  });

  _exports.default = _default;
});