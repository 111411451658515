define("superfly/pods/frame/dashboard/components/upload-dropzone/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember$RSVP = Ember.RSVP,
      Promise = _Ember$RSVP.Promise,
      all = _Ember$RSVP.all;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    folder: null,
    loadingImages: false,
    isInitialized: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      var $dropzone = this.$('#dropzone');
      this.$().on('dragover', '#dropzone', function (event) {
        event.preventDefault();
      });
      this.$().on('dragenter', '#dropzone', function (event) {
        event.preventDefault();
        $dropzone.addClass('hover');
      });
      this.$().on('dragleave', '#dropzone', function (event) {
        event.preventDefault();
        $dropzone.removeClass('hover');
      });
      this.$().on('drop', '#dropzone', function (event) {
        event.preventDefault();
        $dropzone.removeClass('hover');
        var entry = event.dataTransfer.items[0].webkitGetAsEntry();

        _this.send('dropTriggered', entry);
      });
    },
    folderDisplayName: Ember.computed('folder', function folderDisplayName() {
      var folder = Ember.get(this, 'folder');

      if (Ember.get(folder, 'isRoot')) {
        return 'Flights';
      }

      return Ember.get(folder, 'name');
    }),
    actions: {
      dropTriggered: function dropTriggered(entry) {
        var _this2 = this;

        var notify = Ember.get(this, 'notify');

        var readContents = function readContents(folder, callback) {
          var contents = [];

          function readSome(reader) {
            reader.readEntries(function (entries) {
              entries.forEach(function (entry) {
                if (entry.isFile) {
                  contents.push(entry);
                }
              });

              if (entries.length) {
                readSome(reader);
              } else {
                callback(contents);
              }
            });
          }

          readSome(folder.createReader());
        };

        if (entry.isDirectory) {
          readContents(entry, function (entries) {
            var promises = [];
            entries.forEach(function (entry) {
              promises.push(new Promise(function (resolve) {
                return entry.file(resolve);
              }));
            });
            all(promises).then(function (files) {
              return _this2.send('filesFound', files);
            });
          });
        } else {
          notify.error('Selection must be a directory');
        }
      },
      filesFound: function filesFound(files) {
        var _this3 = this;

        Ember.set(this, 'loadingImages', true);
        this.sendAction('filesFound', files, function () {
          Ember.set(_this3, 'loadingImages', false);

          _this3.sendAction('close');
        });
      },
      cancel: function cancel() {
        Ember.get(this, 'close')();
      }
    }
  });

  _exports.default = _default;
});