define("superfly/models/flight", ["exports", "ember-data", "moment", "superfly/config/environment", "superfly/mixins/model-pollable", "superfly/utils/normalize-flight-type", "superfly/common/defaults"], function (_exports, _emberData, _moment, _environment, _modelPollable, _normalizeFlightType, _defaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend(_modelPollable.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    startDate: attr('date'),
    endDate: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    name: attr('string'),
    outline: attr(),
    isComplete: attr('boolean', {
      defaultValue: false
    }),
    status: attr('string'),
    sketchfabUid: attr('string'),
    flightType: attr('flightType'),
    enabledMeasurements: attr({
      defaultValue: function defaultValue() {
        return _defaults.defaultEnabledMeasurements;
      }
    }),
    ndviRange: attr(),
    dsmRange: attr(),
    folder: belongsTo('folder'),
    measurements: hasMany('measurement', {
      polymorphic: true
    }),
    overlays: hasMany('overlay', {
      polymorphic: true
    }),
    stitchedAssets: hasMany('stitched-asset'),
    downloadOptions: hasMany('download-option'),
    share: belongsTo('share', {
      inverse: 'flight'
    }),
    isOrthomosaic: Ember.computed.equal('flightType', 'orthomosaic'),
    isNdvi: Ember.computed.equal('flightType', 'ndvi'),
    isTerrain: Ember.computed.equal('flightType', 'terrain'),
    is3d: Ember.computed.equal('flightType', '3d'),
    isThreeDimensional: Ember.computed.deprecatingAlias('is3d'),
    normalizedFlightType: Ember.computed('flightType', function normalizedFlightType() {
      var type = Ember.get(this, 'flightType');
      return (0, _normalizeFlightType.default)(type);
    }),
    normalizedStatus: Ember.computed('status', function () {
      return (Ember.get(this, 'status') || '').toLowerCase();
    }),
    isQueuedForStitching: Ember.computed.equal('normalizedStatus', 'queued for stitching'),
    isStitching: Ember.computed.equal('normalizedStatus', 'stitching'),
    isStitchFailed: Ember.computed.equal('normalizedStatus', 'stitching failed'),
    isTiling: Ember.computed.equal('normalizedStatus', 'tiling'),
    isTilingFailed: Ember.computed.equal('normalizedStatus', 'tiling failed'),
    isTilingComplete: Ember.computed.equal('normalizedStatus', 'tiling completed'),
    isProcessing: Ember.computed('isComplete', 'isProcessingFailure', 'isProcessingSuccess', function () {
      var _Ember$getProperties = Ember.getProperties(this, ['isNew', 'isComplete', 'isProcessingFailure', 'isProcessingSuccess', 'isStitchFailed']),
          isNew = _Ember$getProperties.isNew,
          isComplete = _Ember$getProperties.isComplete,
          isProcessingFailure = _Ember$getProperties.isProcessingFailure,
          isProcessingSuccess = _Ember$getProperties.isProcessingSuccess,
          isStitchFailed = _Ember$getProperties.isStitchFailed;

      return isNew || isComplete && !(isProcessingFailure || isProcessingSuccess || isStitchFailed);
    }),
    isProcessingFailure: Ember.computed.or('isStitchFailed', 'isTilingFailed'),
    isProcessingSuccess: Ember.computed.alias('isTilingComplete'),
    displayName: Ember.computed('name', function () {
      var _Ember$getProperties2 = Ember.getProperties(this, ['name', 'createdAt']),
          name = _Ember$getProperties2.name,
          createdAt = _Ember$getProperties2.createdAt;

      return name ? name : (0, _moment.default)(createdAt).format('MMMM DD, YY');
    }),
    filteredOverlays: Ember.computed('overlays', function () {
      var overlays = Ember.get(this, 'overlays');
      var blacklist = [];
      return overlays.sortBy('createdAt').reverse().filter(function (overlay) {
        var overlayType = Ember.get(overlay, 'overlayType');
        var filterType = Ember.get(overlay, 'filterType');

        if (blacklist.indexOf(overlayType) === -1 && filterType !== 'index') {
          blacklist.push(overlayType);
          return true;
        }

        return false;
      });
    }),
    sortedFilteredOverlays: Ember.computed.sort('filteredOverlays', function (a, b) {
      var sortOrder = ['ndvi', 'orthomosaic', 'dsm'];
      return sortOrder.indexOf(a.normalizedType) - sortOrder.indexOf(b.normalizedType);
    }),
    // Returns the most recently created overlay object.
    latestOverlay: Ember.computed('sortedFilteredOverlays', function () {
      var overlays = Ember.get(this, 'sortedFilteredOverlays');
      return Ember.get(overlays, 'firstObject');
    }),
    info: Ember.computed('outline', 'name', function () {
      var bounds = L.latLngBounds(Ember.get(this, 'outline').map(function (p) {
        return L.latLng(p.lat, p.lng);
      }));
      return {
        bounds: bounds,
        name: Ember.get(this, 'name')
      };
    }),
    // Sorts the flight's stitched assets (`stitchedAssets`) into useful categories.
    // Returns an object like this:
    // {
    //    orthomosaic: Object,
    //    ndviProcessed: Object,
    //    ndviUnprocessed: Object,
    //    index: Object,
    // }
    // Note: A key will only be present if the value is.
    sortedStitchedAssets: Ember.computed('stitchedAssets', function () {
      var sortedStitches = {};
      Ember.get(this, 'stitchedAssets').forEach(function (asset) {
        var filterType = Ember.get(asset, 'filterType');

        if (filterType === 'none') {
          sortedStitches['orthomosaic'] = asset;
        } else if (filterType === 'index') {
          sortedStitches['index'] = asset;
        } else if (filterType === 'ndvi') {
          if (Ember.get(asset, 'isProcessed') === true) {
            sortedStitches['ndviProcessed'] = asset;
          } else {
            sortedStitches['ndviUnprocessed'] = asset;
          }
        }
      });
      return sortedStitches;
    }),
    // Calls the flightComplete endpoint to trigger stitching.
    complete: function complete() {
      var ajax = Ember.get(this, 'ajax');
      var flightId = Ember.get(this, 'id');
      var accessToken = Ember.get(this, 'session.accessToken');
      var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': accessToken
      };
      var url = "".concat(_environment.default.BOTLINK_USERS_API_URL, "/subscriptions/queueStitch");
      return ajax.post(url, {
        headers: headers,
        data: {
          flightId: flightId
        }
      });
    }
  });

  _exports.default = _default;
});