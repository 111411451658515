define("superfly/components/material-spinner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['material-spinner'],
    classNameBindings: ['color'],
    size: 'sm',
    sizes: [{
      key: 'xs',
      size: '25'
    }, {
      key: 'sm',
      size: '50'
    }, {
      key: 'md',
      size: '75'
    }, {
      key: 'lg',
      size: '100'
    }, {
      key: 'xl',
      size: '200'
    }],
    sizeRef: Ember.computed('size', function () {
      var _Ember$getProperties = Ember.getProperties(this, ['size', 'sizes']),
          size = _Ember$getProperties.size,
          sizes = _Ember$getProperties.sizes;

      return sizes.findBy('key', size).size;
    }),
    halfSize: Ember.computed('sizeRef', function () {
      return Ember.get(this, 'sizeRef') / 2;
    }),
    didInsertElement: function didInsertElement() {
      var sizeRef = Ember.get(this, 'sizeRef');
      this.$('.loader, .circular').css({
        height: "".concat(sizeRef, "px"),
        width: "".concat(sizeRef, "px")
      });
    }
  });

  _exports.default = _default;
});