define("superfly/pods/application/components/sketchfab-viewer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VvVRl/D5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\"],[11,\"allowfullscreen\",\"\"],[11,\"mozallowfullscreen\",\"true\"],[11,\"webkitallowfullscreen\",\"true\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/sketchfab-viewer/template.hbs"
    }
  });

  _exports.default = _default;
});