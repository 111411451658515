define("superfly/transforms/flight-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      switch (serialized) {
        case 'ndvi':
          return 'vegetation';

        case 'ortho':
          return 'orthomosaic';

        case 'threeDimensional':
        case 'three_dimensional':
          return '3d';

        default:
          return serialized;
      }
    },
    serialize: function serialize(deserialized) {
      switch (deserialized) {
        case 'vegetation':
          return 'ndvi';

        case 'orthomosaic':
          return 'ortho';

        case '3d':
          return 'three_dimensional';

        default:
          return deserialized;
      }
    }
  });

  _exports.default = _default;
});