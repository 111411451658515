define("superfly/initializers/leaflet-extension", ["exports", "L"], function (_exports, _L) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _L.default.Edit.Poly = _L.default.Edit.Poly.extend({
      options: {
        icon: new _L.default.DivIcon({
          iconSize: new _L.default.Point(20, 20),
          className: 'map-edit-handle'
        })
      }
    });

    _L.default.Map.include({
      _mapStyleLayerTileLoad: function _mapStyleLayerTileLoad() {
        var _this = this;

        this._stylesLayer.getLayers().filter(function (layer) {
          return _this._currentStyleLayer !== layer;
        }, this).forEach(function (layer) {
          if (layer !== _this._currentStyleLayer) {
            _this._stylesLayer.removeLayer(layer);
          }
        }, this);

        this._currentStyleLayer.off("tileload", this._mapStyleLayerTileLoad, this);
      },
      setMapStyle: function setMapStyle(style) {
        if (!style) {
          return;
        }

        if (!this._stylesLayer) {
          this._stylesLayer = _L.default.layerGroup([]).addTo(this);
        }

        if (this._currentStyleLayer) {
          this._currentStyleLayer.off("tileload", this._mapStyleTileLoad);
        }

        var layer = _L.default.mapbox.tileLayer(style);

        if (this._currentStyleLayer) {
          layer.on("tileload", this._mapStyleLayerTileLoad, this);
        }

        layer.addTo(this._stylesLayer);
        this._currentStyleLayer = layer;
        return this;
      }
    });
    /**
     * Override Draw.Feature create event to pass a shape type
     *
     * @private
     * @param layer
     * @returns void
     */


    _L.default.Draw.Feature.prototype._fireCreatedEvent = function (layer) {
      var eventData = {
        layer: layer,
        layerType: this.type
      };

      if (this.featureTypeCode) {
        eventData.featureTypeCode = this.featureTypeCode;
      }

      this._map.fire('draw:created', eventData);
    };
    /**
     * Area Measurement
     */


    _L.default.Draw.Area = _L.default.Draw.Polygon.extend({
      initialize: function initialize(map, options) {
        this.type = 'polygon';

        _L.default.Draw.Feature.prototype.initialize.call(this, map, options);

        this.featureTypeCode = 'area';
        this.options.metric = true;
        this.options.feet = false;
      },
      _getTooltipText: function _getTooltipText() {
        var text;
        var subtext;
        var tooltipObj = _L.default.drawLocal.draw.handlers.area.tooltip;

        if (this._markers.length === 0) {
          text = tooltipObj.start;
        } else if (this._markers.length < 3) {
          text = tooltipObj.cont;
        } else {
          text = tooltipObj.end; // subtext = this._getMeasurementString();
        }

        return {
          text: text,
          subtext: subtext
        };
      }
    });
    /**
     * Distance Measurement
     */

    _L.default.Draw.Distance = _L.default.Draw.Polyline.extend({
      initialize: function initialize(map, options) {
        this.type = 'polyline';

        _L.default.Draw.Feature.prototype.initialize.call(this, map, options);

        this.featureTypeCode = 'distance';
        this.options.metric = true;
        this.options.feet = false;
      },
      _getTooltipText: function _getTooltipText() {
        var text;
        var subtext;
        var tooltipObj = _L.default.drawLocal.draw.handlers.distance.tooltip;

        if (this._markers.length === 0) {
          text = tooltipObj.start;
        } else if (this._markers.length === 1) {
          text = tooltipObj.cont;
        } else {
          text = tooltipObj.end; // subtext = this._getMeasurementString();
        }

        return {
          text: text,
          subtext: subtext
        };
      }
    });
    /**
     * Annotation Measurement
     */

    _L.default.Draw.Comment = _L.default.Draw.Marker.extend({
      initialize: function initialize(map, options) {
        this.type = 'marker';
        this.featureTypeCode = 'comment';

        _L.default.Draw.Feature.prototype.initialize.call(this, map, options);
      },
      addHooks: function addHooks() {
        _L.default.Draw.Feature.prototype.addHooks.call(this);

        if (this._map) {
          this._tooltip.updateContent({
            text: _L.default.drawLocal.draw.handlers.comment.tooltip.start
          });

          if (!this._mouseMarker) {
            this._mouseMarker = _L.default.marker(this._map.getCenter(), {
              icon: _L.default.divIcon({
                className: 'leaflet-mouse-marker',
                iconAnchor: [20, 20],
                iconSize: [40, 40]
              }),
              opacity: 0,
              zIndexOffset: this.options.zIndexOffset
            });
          }

          this._mouseMarker.on('click', this._onClick, this).addTo(this._map);

          this._map.on('mousemove', this._onMouseMove, this);

          this._map.on('click', this._onTouch, this);
        }
      }
    });
    _L.default.Draw.Volume = _L.default.Draw.Polygon.extend({
      initialize: function initialize(map, options) {
        this.type = 'polygon';

        _L.default.Draw.Feature.prototype.initialize.call(this, map, options);

        this.featureTypeCode = 'volume';
        this.options.metric = true;
        this.options.feet = false;
      },
      _getTooltipText: function _getTooltipText() {
        var text;
        var subtext;
        var tooltipObj = _L.default.drawLocal.draw.handlers.area.tooltip;

        if (this._markers.length === 0) {
          text = tooltipObj.start;
        } else if (this._markers.length < 3) {
          text = tooltipObj.cont;
        } else {
          text = tooltipObj.end; // subtext = this._getMeasurementString();
        }

        return {
          text: text,
          subtext: subtext
        };
      }
    });
    /**
     * Draw options
     */

    _L.default.drawLocal = {
      draw: {
        handlers: {
          comment: {
            tooltip: {
              start: 'Click to add comment'
            }
          },
          area: {
            error: '<strong>Error:</strong> shape edges cannot cross!',
            tooltip: {
              start: 'Click to start outlining an area',
              cont: 'Click to continue outlining an area',
              end: 'Click first point to close this site property'
            }
          },
          volume: {
            error: '<strong>Error:</strong> shape edges cannot cross!',
            tooltip: {
              start: 'Click to start outlining a volume',
              cont: 'Click to continue outlining a volume',
              end: 'Click first point to close this site property'
            }
          },
          distance: {
            tooltip: {
              start: 'Click to start drawing line',
              cont: 'Click to continue drawing line',
              end: 'Click last point to finish line'
            }
          }
        }
      },
      edit: {
        handlers: {
          edit: {
            tooltip: {
              text: 'Drag handles, or marker to edit feature.',
              subtext: 'Click cancel to undo changes.'
            }
          }
        }
      }
    };
  }

  var _default = {
    name: 'leaflet-extension',
    initialize: initialize
  };
  _exports.default = _default;
});