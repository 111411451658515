define("superfly/components/payment-method-info/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    subscriptionManager: Ember.inject.service(),
    notify: Ember.inject.service(),
    classNames: ['payment-method-info'],
    showModal: false,
    triggerPaymentMethodSubmit: false,
    paymentMethod: Ember.computed.alias('subscriptionManager.paymentSource'),
    paymentMethodFailureReason: Ember.computed.alias('subscriptionManager.paymentMethodFailureReason'),
    paymentExp: Ember.computed('paymentMethod.expMonth', 'paymentMethod.expYear', function paymentExp() {
      var paymentMethod = Ember.get(this, 'paymentMethod');

      var _Ember$getProperties = Ember.getProperties(paymentMethod, ['expMonth', 'expYear']),
          expMonth = _Ember$getProperties.expMonth,
          expYear = _Ember$getProperties.expYear;

      return (0, _moment.default)([expYear, expMonth - 1, 1]);
    }),
    actions: {
      openModal: function openModal() {
        Ember.set(this, 'showModal', true);
      },
      closeModal: function closeModal() {
        Ember.set(this, 'showModal', false);
      },
      submit: function submit() {
        this.$('button').blur();
        this.toggleProperty('triggerPaymentMethodSubmit');
      },
      onPaymentMethodSuccess: function onPaymentMethodSuccess() {
        Ember.get(this, 'notify').success('Card info updated!');
        this.send('closeModal');
      },
      onPaymentMethodFailure: function onPaymentMethodFailure() {}
    }
  });

  _exports.default = _default;
});