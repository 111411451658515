define("superfly/pods/auth/components/auth-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dWHAR3EJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"header\"],[9],[7,\"img\"],[11,\"src\",\"/assets/images/login_logo.png\"],[11,\"alt\",\"logo\"],[9],[10],[10],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/components/auth-bar/template.hbs"
    }
  });

  _exports.default = _default;
});