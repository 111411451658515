define("superfly/initializers/ember-g-recaptcha", ["exports", "superfly/config/environment", "ember-g-recaptcha/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jshint unused:false*/
  var _default = {
    name: 'ember-g-recaptcha',
    initialize: function initialize() {
      var config = _environment.default.gReCaptcha || {};

      _configuration.default.setProperties(config);
    }
  };
  _exports.default = _default;
});