define("superfly/pods/frame/dashboard/folders/components/folder-explorer/component", ["exports", "velocity", "lodash"], function (_exports, _velocity, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    navigationManager: Ember.inject.service(),
    subscriptionManager: Ember.inject.service(),
    notify: Ember.inject.service(),
    agx: Ember.inject.service(),
    mainMap: Ember.inject.service(),
    beingDraggedId: null,
    beingHoveredId: null,
    folderElement: null,
    folder: null,
    pendingMove: null,
    isSettingAgxSeason: false,
    folders: Ember.computed.alias('folder.folders'),
    flights: Ember.computed.alias('folder.flights'),
    filteredFlights: Ember.computed.filterBy('flights', 'isComplete', true),
    hasFlightsOrFolders: Ember.computed.or('hasFolders', 'hasFlights'),
    hasFolders: Ember.computed.notEmpty('folders'),
    hasFlights: Ember.computed.notEmpty('filteredFlights'),
    isRoot: Ember.computed.alias('folder.isRoot'),
    hasNonRootParentId: Ember.computed('folder.folder.isRoot', function () {
      var folder = Ember.get(this, 'target.folder');

      if (folder) {
        return !Ember.get(folder, 'isRoot');
      }

      return false;
    }),
    isSnoozed: Ember.computed.alias('subscriptionManager.isSnoozed'),
    sortedFolders: Ember.computed('folders.[]', 'sortDirection', 'sortedBy', function () {
      var folders = Ember.get(this, 'folders') || [];
      var direction = Ember.get(this, 'sortDirection');
      var sorted = folders.sortBy(Ember.get(this, 'sortedBy.key'));

      if (direction === 'desc') {
        return sorted.reverse();
      }

      return sorted;
    }),
    sortedFlights: Ember.computed('filteredFlights.[]', 'sortDirection', 'sortedBy', function () {
      var flights = Ember.get(this, 'filteredFlights') || [];
      var direction = Ember.get(this, 'sortDirection');
      var sorted = flights.sortBy(Ember.get(this, 'sortedBy.key'), 'name');

      if (direction === 'desc') {
        return sorted.reverse();
      }

      return sorted;
    }),
    sortOptions: [{
      key: 'startDate',
      value: 'flight date'
    }, {
      key: 'createdAt',
      value: 'upload date'
    }, {
      key: 'displayName',
      value: 'name'
    }],
    selectedSortedBy: null,
    isSortOptionsVisible: false,
    isNewFolderModalVisible: false,
    sortDirection: 'desc',
    sortedBy: Ember.computed('selectedSortedBy', function () {
      var selected = Ember.get(this, 'selectedSortedBy');
      return selected ? selected : Ember.get(this, 'sortOptions.firstObject');
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().on('mouseleave', '.sort-options', function () {
        return _this.send('hideOptionsMenu');
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.addObserver('filteredFlights.[]', this, '_setFlightMarkers');

      this._setFlightMarkers();
    },
    _setFlightMarkers: _lodash.default.debounce(function () {
      var _this2 = this;

      var flights = Ember.get(this, 'filteredFlights');
      var mapService = Ember.get(this, 'mainMap');
      mapService.removeAllFlightMarkers();
      flights.forEach(function (flight) {
        mapService.addFlightMarker(Ember.get(flight, 'info'), function () {
          if (!Ember.get(flight, 'isProcessingFailure') && !Ember.get(flight, 'isProcessing')) {
            var afterTransition = Ember.get(_this2, 'afterTransition');

            if (afterTransition) {
              afterTransition();
            }

            _this2.router.transitionTo('frame.dashboard.flights.flight', Ember.get(flight, 'id'));
          }
        });
      });
    }, 150),
    move: function move(focus, folder) {
      var notify = Ember.get(this, 'notify');
      var folderElement = Ember.get(this, 'folderElement');
      var $em = this.$(".".concat(Ember.get(focus, 'id')));
      var dom = $em[0];
      (0, _velocity.default)(dom, {
        opacity: [0, 'easeOut']
      }, {
        duration: 800,
        queue: false
      }).then(function () {
        Ember.set(focus, 'folder', folder);
        return focus.save();
      }).then(function () {
        notify.success("Moved ".concat(Ember.get(focus, 'name') || 'target', " into ").concat(Ember.get(folder, 'name') || 'folder'));
        Ember.$(folderElement).removeClass('drop');
        Ember.$(folderElement).addClass('successful-move');
        setTimeout(function () {
          Ember.$(folderElement).removeClass('successful-move');
        }, 2000);
      }).catch(function () {
        $em.addClass('drop-error');
        (0, _velocity.default)(dom, {
          opacity: [1, 'easeIn']
        }, {
          duration: 400,
          queue: false
        }).then(function () {
          return (0, _velocity.default)(dom, {
            left: 5
          }, {
            duration: 100
          });
        }).then(function () {
          return (0, _velocity.default)(dom, {
            left: -5
          }, {
            duration: 100
          });
        }).then(function () {
          return (0, _velocity.default)(dom, {
            left: 5
          }, {
            duration: 100
          });
        }).then(function () {
          return (0, _velocity.default)(dom, {
            left: 0
          }, {
            duration: 100
          });
        }).then(function () {
          $em.removeClass('drop-error');
        }).catch(function () {
          $em.removeClass('drop-error');
        });
        Ember.$(folderElement).removeClass('drop');
        Ember.$(folderElement).addClass('failed-move');
        notify.error("Couldn't move ".concat(Ember.get(focus, 'name') || 'target', " into ").concat(Ember.get(folder, 'name') || 'folder'));
        setTimeout(function () {
          Ember.$(folderElement).removeClass('failed-move');
        }, 2000);
      });
    },
    actions: {
      toggleOptionsMenu: function toggleOptionsMenu() {
        if (Ember.get(this, 'isSortOptionsVisible')) {
          this.send('hideOptionsMenu');
        } else {
          this.send('showOptionsMenu');
        }
      },
      hideOptionsMenu: function hideOptionsMenu() {
        Ember.set(this, 'isSortOptionsVisible', false);
      },
      showOptionsMenu: function showOptionsMenu() {
        Ember.set(this, 'isSortOptionsVisible', true);
      },
      closeNewFolderModal: function closeNewFolderModal() {
        Ember.set(this, 'isNewFolderModalVisible', false);
      },
      openNewFolderModal: function openNewFolderModal() {
        Ember.set(this, 'isNewFolderModalVisible', true);
      },
      sortBy: function sortBy(option) {
        Ember.set(this, 'sortedBy', option);
        this.send('hideOptionsMenu');
      },
      toggleSortDirection: function toggleSortDirection() {
        var currentDirection = Ember.get(this, 'sortDirection');

        if (currentDirection === 'asc') {
          Ember.set(this, 'sortDirection', 'desc');
        } else {
          Ember.set(this, 'sortDirection', 'asc');
        }
      },
      drop: function drop(focus, event) {
        var notify = Ember.get(this, 'notify');
        var folder = Ember.get(event, 'target.folder');
        var folderId = Ember.get(folder, 'id');
        var folderElement = Ember.get(this, 'folderElement');
        var type = focus.constructor.toString().indexOf('folder') ? 'folder' : 'flight';
        Ember.$(folderElement).addClass('drop');

        if (Ember.get(focus, 'id') === folderId) {
          return notify.error("A ".concat(type, " can't be placed inside itself."));
        }

        if (folderId === Ember.get(focus, 'folderId')) {
          return notify.error("That ".concat(type, " is already in that location."));
        }

        var agxFolderIds = Ember.get(this, 'agx.connections') || [];

        if (agxFolderIds.findBy('botlink_folder', folderId)) {
          Ember.set(this, 'pendingMove', {
            focus: focus,
            folder: folder
          });
          Ember.set(this, 'isSettingAgxSeason', true);
        } else {
          this.move(focus, folder);
        }
      },
      agxSaveSuccessful: function agxSaveSuccessful() {
        var _Ember$get = Ember.get(this, 'pendingMove'),
            focus = _Ember$get.focus,
            folder = _Ember$get.folder;

        this.move(focus, folder);
        Ember.set(this, 'pendingMove', null);
        this.send('closeAgxModal');
      },
      agxSaveFailure: function agxSaveFailure() {
        var notify = Ember.get(this, 'notify');
        notify.error("Could not save agX association.");
        Ember.set(this, 'pendingMove', null);
        this.send('closeAgxModal');
      },
      closeAgxModal: function closeAgxModal() {
        Ember.set(this, 'pendingMove', null);
        Ember.set(this, 'isSettingAgxSeason', false);
      },
      dragStartAction: function dragStartAction(id) {
        if (Ember.get(this, 'beingDraggedId') !== id) {
          Ember.set(this, 'beingDraggedId', id);
        }
      },
      dragEndAction: function dragEndAction() {
        Ember.set(this, 'beingDraggedId', null);
      },
      dragOverAction: function dragOverAction(folder, id) {
        if (Ember.get(this, 'beingHoveredId') !== id) {
          Ember.set(this, 'beingHoveredId', id);
        }

        if (Ember.$(event.target).attr("class") != "draggable-object-target ember-view" && !Ember.$(event.target).is("a")) {
          Ember.$(event.target).addClass('hover-drag');

          if (Ember.get(this, 'folderElement') != Ember.$(event.target)) {
            Ember.set(this, 'folderElement', Ember.$(event.target));
          }
        }
      },
      dragOutAction: function dragOutAction() {
        Ember.set(this, 'beingHoveredId', null);

        if (Ember.$(event.target).attr("class") != "draggable-object-target ember-view" && !Ember.$(event.fromElement).is("a")) {
          Ember.$(event.target).removeClass('hover-drag');
        }
      }
    }
  });

  _exports.default = _default;
});