define("superfly/reopens/link-component", ["@ember-metal"], function (_emberMetal) {
  "use strict";

  Ember.LinkComponent.reopen({
    afterNavigate: null,
    click: function click() {
      this._super.apply(this, arguments);

      var afterNavigate = (0, _emberMetal.default)(this, 'afterNavigate');

      if (afterNavigate) {
        afterNavigate();
      }
    }
  });
});