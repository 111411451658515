define("superfly/serializers/subscription", ["exports", "superfly/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      return this._super(typeClass, Ember.assign({}, hash, {
        links: {// billingCycle: `billingcycles/organization/${hash.id}/current`,
        }
      }));
    }
  });

  _exports.default = _default;
});