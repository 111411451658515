define("superfly/models/overlay", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    bounds: attr('bounds'),
    filterType: attr('string'),
    flightId: attr('string'),
    isProcessed: attr('boolean'),
    overlayType: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    deletedAt: attr('date'),
    flight: belongsTo('flight', {
      inverse: 'overlays'
    }),
    displayType: Ember.computed('normalizedType', function displayOverlayType() {
      var type = Ember.get(this, 'normalizedType') || '';
      return type.decamelize();
    }),
    displayTypeIcon: Ember.computed('normalizedType', function displayTypeIcon() {
      var type = Ember.get(this, 'normalizedType') || '';

      switch (type) {
        case 'ndvi':
          return 'vegetation';

        case 'orthomosaic':
          return 'survey';

        case 'dsm':
          return 'terrain';

        default:
          return type;
      }
    }),
    displayTypeAbbr: Ember.computed('normalizedType', function displayTypeAbbr() {
      var type = Ember.get(this, 'normalizedType') || '';

      switch (type) {
        case 'orthomosaic':
          return 'survey';

        default:
          return type;
      }
    })
  });

  _exports.default = _default;
});