define("superfly/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var subscriptionStatus = {
    active: 0,
    trialing: 1,
    past_due: 2
  };

  var _default = Model.extend({
    createdAt: attr('date'),
    updatedAt: attr('date'),
    name: attr('string'),
    description: attr('string'),
    stripeCustomerId: attr('string'),
    freeTrialEnd: attr('date'),
    rootFolder: belongsTo('rootFolder'),
    user: belongsTo('user'),
    subscriptions: hasMany('subscription'),
    pendingPlans: hasMany('pendingPlan'),
    billingCycle: belongsTo('billingCycle'),
    paymentMethods: hasMany('paymentMethod'),
    hasStitchingPlan: Ember.computed('stitchingPlan', function hasStitchingPlan() {
      var stitchingPlan = Ember.get(this, 'stitchingPlan');
      return stitchingPlan && stitchingPlan.photoLimit > 0;
    }),
    stitchingPlan: Ember.computed('subscriptions', function stitchingPlan() {
      var subscriptions = Ember.get(this, 'subscriptions').toArray();
      var hasHadFreeTrial = Ember.get(this, 'freeTrialEnd');
      var plan = {
        name: null,
        photoLimit: 0,
        billingCycle: null,
        status: 'active'
      };
      plan = subscriptions.reduce(function (subscriptionAccumulator, subscription) {
        var items = Ember.get(subscription, 'items.data');
        var resolvedPlan = items.reduce(function (itemAccumulator, item) {
          var itemPlan = item.plan;
          var photoLimit = itemPlan.metadata.uploadLimitPerBillingCycle;
          photoLimit = photoLimit ? parseInt(photoLimit) : null;
          photoLimit = photoLimit === 0 ? Infinity : photoLimit;

          if (photoLimit) {
            itemAccumulator.name = itemAccumulator.name ? 'Custom' : itemPlan.nickname;
            itemAccumulator.photoLimit += photoLimit * item.quantity;
            itemAccumulator.billingCycle = itemPlan.interval === 'month' ? 'monthly' : 'annual';
          }

          return itemAccumulator;
        }, subscriptionAccumulator);
        resolvedPlan.status = subscriptionStatus[subscription.status] > subscriptionStatus[resolvedPlan.status] ? subscription.status : resolvedPlan.status;
        return resolvedPlan;
      }, plan);

      if (plan.photoLimit === 0 && !hasHadFreeTrial) {
        return {
          name: 'Free Trial',
          photoLimit: 1000,
          billingCycle: 'none',
          status: 'active'
        };
      }

      return plan;
    }),
    hasInventoryManagement: Ember.computed('inventoryManagementPlan', function hasInventoryManagement() {
      var inventoryManagementPlan = Ember.get(this, 'inventoryManagementPlan');
      return inventoryManagementPlan && inventoryManagementPlan.siteLimit > 0;
    }),
    inventoryManagementPlan: Ember.computed('subscriptions', function inventoryManagementPlan() {
      var subscriptions = Ember.get(this, 'subscriptions').toArray();
      var hasHadFreeTrial = Ember.get(this, 'freeTrialEnd');

      if (subscriptions.length === 0 && hasHadFreeTrial) {
        return;
      }

      var plan = {
        siteLimit: 0,
        billingCycle: null
      };
      plan = subscriptions.reduce(function (subscriptionAccumulator, subscription) {
        var items = Ember.get(subscription, 'items.data');
        return items.reduce(function (itemAccumulator, item) {
          var itemPlan = item.plan;
          var siteLimit = itemPlan.metadata.siteLimit;
          siteLimit = siteLimit ? parseInt(siteLimit) : null;
          siteLimit = siteLimit === 0 ? Infinity : siteLimit;

          if (siteLimit) {
            itemAccumulator.name = itemAccumulator.name ? 'Custom' : itemPlan.nickname;
            itemAccumulator.siteLimit += siteLimit * item.quantity;
            itemAccumulator.billingCycle = itemPlan.interval === 'month' ? 'monthly' : 'annual';
          }

          return itemAccumulator;
        }, subscriptionAccumulator);
      }, plan);

      if (plan.siteLimit === 0 && !hasHadFreeTrial) {
        return {
          name: 'Free Trial',
          siteLimit: 1,
          billingCycle: 'none'
        };
      }

      return plan;
    })
  });

  _exports.default = _default;
});