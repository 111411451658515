define("superfly/pods/auth/set-new-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NlFBl0H+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"validToken\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"title col-12 mt-4\"],[9],[0,\"\\n        \"],[7,\"h4\"],[9],[0,\"Reset password\"],[10],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n          Password must contain at least one uppercase letter, one lowercase letter,\\n          one number, and be at least 8 characters long.\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n        \"],[1,[27,\"application/components/reset-password-form\",null,[[\"onPasswordUpdated\",\"changeset\",\"token\"],[[27,\"action\",[[22,0,[]],\"onPasswordUpdated\"],null],[23,[\"changeset\"]],[23,[\"token\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"row col-12 justify-content-end mt-4\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"Change your mind?\"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"title col-12 mt-4\"],[9],[0,\"\\n        \"],[7,\"h4\"],[9],[0,\"Reset password\"],[10],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n          This password reset request has expired. Please request a new password reset email.\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row col-12 justify-content-end\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"auth.login\"],[[\"tagName\",\"class\"],[\"button\",\"btn btn-primary\"]],{\"statements\":[[0,\"Log in\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/set-new-password/template.hbs"
    }
  });

  _exports.default = _default;
});