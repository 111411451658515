define("superfly/mixins/upload-browseable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _input: null,
    folder: null,
    onDidInsertUploadBrowseable: Ember.on('didInsertElement', function () {
      this.setupUploadBrowseable();
    }),
    onWillDestroyUploadBrowseable: Ember.on('willDestroyElement', function () {
      this.teardownUploadBrowseable();
    }),
    setupUploadBrowseable: function setupUploadBrowseable() {
      var _this = this;

      var $domNode = this.$();
      var $input = this.$("<input ".concat(this.supportDirectory() ? 'webkitdirectory' : '', ">"));
      $input.attr({
        type: 'file'
      }).css({
        visibility: 'hidden',
        position: 'absolute',
        width: '1px',
        height: '1px'
      });

      if (!Ember.get(this, 'singleFile')) {
        $input.attr('multiple', 'multiple');
      }

      $domNode.append($input);
      $domNode.on('click', function (event) {
        return _this.browseableUploadInitialized(event);
      });
      $domNode.on('click', 'input', function (event) {
        return event.stopPropagation();
      });
      $domNode.on('change', 'input', function (event) {
        return _this.browseableUploadCollectionSelected(event);
      });
      Ember.set(this, '_input', $input);
    },
    teardownUploadBrowseable: function teardownUploadBrowseable() {
      var $input = Ember.get(this, '_input');
      this.$().off('click.browseable');
      $input.remove();
    },
    browseableUploadInitialized: function browseableUploadInitialized() {
      Ember.get(this, '_input').get(0).click();
    },
    browseableUploadCollectionSelected: function browseableUploadCollectionSelected(e) {
      if (e.target.value) {
        this.sendAction('filesFound', Object.values(e.target.files));
      }

      e.target.value = '';
    },
    supportDirectory: function supportDirectory() {
      var agent = window.navigator.userAgent;
      return /Chrome/.test(agent) || /Firefox/.test(agent) || /Edge/.test(agent);
    }
  });

  _exports.default = _default;
});