define("superfly/pods/application/components/upload-type-icon-vegetation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O5++V/08",
    "block": "{\"symbols\":[],\"statements\":[[7,\"g\"],[11,\"transform\",\"translate(-21089 -12562)\"],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M29.2,21.27C20.768,23.68,14.34,30.51,9.117,41.759c0,0-.8-1.205-2.009-2.812A11.491,11.491,0,0,1,5.1,32.117c0-2.812,1.205-6.428,4.017-10.044a24.283,24.283,0,0,1,13.659-8.437c6.83-1.205,13.659-2.009,20.489-3.214S54.113,7.61,56.122,5.2q.6,3.013-7.231,21.694C43.668,39.348,34.428,46.58,20.768,48.589a14.909,14.909,0,0,1-3.616-.8l-2.41,6.026L9.921,52.2A85.413,85.413,0,0,1,19.965,32.117c4.419-6.428,12.454-11.249,23.3-14.061C42.462,17.654,37.641,18.859,29.2,21.27Z\"],[11,\"transform\",\"translate(797.389 494.691)\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M88.384,3.616A13.553,13.553,0,0,1,92,11.651v68.7a10.114,10.114,0,0,1-3.616,8.035A14.751,14.751,0,0,1,80.349,92h-68.7a10.114,10.114,0,0,1-8.035-3.616C1.607,85.974,0,83.563,0,80.349v-68.7A10.114,10.114,0,0,1,3.616,3.616C6.026,1.607,8.437,0,11.651,0h68.7A10.114,10.114,0,0,1,88.384,3.616Zm-8.035,8.035h-68.7v68.7h68.7Z\"],[11,\"transform\",\"translate(782 479)\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/upload-type-icon-vegetation/template.hbs"
    }
  });

  _exports.default = _default;
});