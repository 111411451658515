define("superfly/pods/frame/dashboard/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      transition.abort();
      this.transitionTo('frame.dashboard.folders');
    }
  });

  _exports.default = _default;
});