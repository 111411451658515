define("superfly/pods/application/components/main-map/component", ["exports", "L", "superfly/config/environment"], function (_exports, _L, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mainMap: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: 'section',
    elementId: 'super-map',
    tileLayer: null,
    map: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _L.default.mapbox.accessToken = _environment.default.MAPBOX_API_KEY;

      var map = _L.default.mapbox.map('super-map'); // map.options.zoomSnap = 0.1
      // map.options.zoomDelta = 0.1


      this.set('map', map);
    },
    onMapSet: Ember.observer('map', function () {
      this.assignMapToMainMapService();
    }),
    onMapStyleChange: Ember.on('init', Ember.observer('mainMap.mapStyle', function () {
      this.setMapStyle();
    })),
    onMapStartup: Ember.on('init', Ember.observer('map', 'mainMap.currentOverlayId', function () {
      var _Ember$getProperties = Ember.getProperties(this, ['store', 'map']),
          store = _Ember$getProperties.store,
          map = _Ember$getProperties.map;

      var currentOverlayId = Ember.get(this, 'mainMap.currentOverlayId');
      var overlay = store.peekRecord('overlay', currentOverlayId);
      this.setMapStyle();

      if (map && overlay) {
        // Center the map on the current position if available
        var hasBrowserSupport = !!navigator.geolocation.getCurrentPosition;

        if (hasBrowserSupport) {
          new Ember.RSVP.Promise(function (resolve, reject) {
            return navigator.geolocation.getCurrentPosition(resolve, reject, {
              enableHighAccuracy: true,
              maximumAge: 30000,
              timeout: 27000
            });
          }).then(function (position) {
            var point = _L.default.latLng(position.coords.latitude, position.coords.longitude);

            map.setView(point, 9);
          });
        }

        var overlayBounds = Ember.get(overlay, 'bounds');

        if (overlayBounds) {
          var bounds = _L.default.latLngBounds(overlayBounds.southWest, overlayBounds.northEast);

          map.fitBounds(bounds);
        }
      }
    })),
    setMapStyle: function setMapStyle() {
      var map = Ember.get(this, 'map');

      if (map) {
        var mapStyle = Ember.get(this, "mainMap.mapStyle.style");
        var oldLayer = Ember.get(this, "tileLayer");

        var tileLayer = _L.default.mapbox.styleLayer("mapbox://styles/".concat(mapStyle), {
          minZoom: 0,
          maxZoom: 22
        });

        this.set('tileLayer', tileLayer);
        map.addLayer(tileLayer, true);

        if (oldLayer) {
          // We delay to create a nice transition, it its too fast
          // its ugly and hard on the eyes.
          setTimeout(function () {
            map.removeLayer(oldLayer);
          }, 500);
        }
      }
    },
    assignMapToMainMapService: function assignMapToMainMapService() {
      Ember.set(this, 'mainMap.map', this.get('map'));
    },
    setAdditionalMapOptions: Ember.observer('map.options', 'bounds', function () {
      var map = this.get('map');
      map.setView([46.8782320, -96.7858390], 9);
      map.options.minZoom = 5;
      map.options.maxZoom = 22;
      map.options.animate = true;
      map.options.zoomAnimationThreshold = 10;

      var attribution = _L.default.control.attribution({
        position: 'bottomright',
        prefix: false
      });

      attribution.addAttribution("<a href='https://www.mapbox.com/about/maps/' target='_system'>&copy; Mapbox &copy; OpenStreetMap</a> <a class='mapbox-improve-map' href='https://www.mapbox.com/map-feedback/' target='_system'>Improve this map</a>");
      map.addControl(attribution);
    })
  });

  _exports.default = _default;
});