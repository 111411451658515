define("superfly/pods/auth/login/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6B17Yd51",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"title mt-4 mb-2 mt-lg-5 mb-lg-3\"],[9],[0,\"\\n  \"],[7,\"h4\"],[11,\"class\",\"mb-2\"],[9],[0,\"Log in\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"d-none d-sm-block\"],[9],[0,\"Enter your information below.\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"auth/login/components/login-form\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"signup-action\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"Don't have an account?\"],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[12,\"href\",[28,[[27,\"href-to\",[\"auth.signup\"],null]]]],[9],[0,\"Sign up\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/login/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});