define("superfly/pods/application/components/site-quickbar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    subscriptionManager: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      Ember.$('[data-toggle="tooltip"]').tooltip({
        animation: false,
        placement: 'right',
        delay: {
          show: 300,
          hide: 100
        },
        template: '<div class="tooltip quickbar-tooltip" role="tooltip"><div class="tooltip-container animated fade-in-right" style="position:relative;"><div class="arrow" style=" left: -5px;"></div><div class="tooltip-inner"></div></div></div>'
      });
    },
    actions: {
      startSearch: function startSearch() {
        Ember.get(this, 'eventBus').publish('site-left-flyout', 'search', 'open');
      }
    }
  });

  _exports.default = _default;
});