define("superfly/pods/frame/dashboard/flights/flight/components/overlay-properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LPQ/z9b6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"dynamicComponentName\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[23,[\"dynamicComponentName\"]]],[[\"overlay\",\"units\"],[[23,[\"overlay\"]],[23,[\"units\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/dashboard/flights/flight/components/overlay-properties/template.hbs"
    }
  });

  _exports.default = _default;
});