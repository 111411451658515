define("superfly/components/secure-intercom-io/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    elementId: 'intercom-launcher',
    session: Ember.inject.service(),
    intercom: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.get(this, 'intercom').start({
        user_hash: Ember.get(this, 'session.currentUser.intercomHash'),
        custom_launcher_selector: "#".concat(Ember.get(this, 'elementId')),
        hide_default_launcher: true
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.get(this, 'intercom').stop();
    }
  });

  _exports.default = _default;
});