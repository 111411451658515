define("superfly/pods/auth/confirm/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Confirm account',
    facebookPixel: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      Ember.set(this, 'token', transition.queryParams.token);
    },
    setupController: function setupController(controller) {
      Ember.set(controller, 'token', Ember.get(this, 'token'));
    }
  });

  _exports.default = _default;
});