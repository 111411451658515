define("superfly/helpers/area-with-units", ["exports", "numeral", "superfly/utils/convert", "superfly/utils/unit-abbr"], function (_exports, _numeral, _convert, _unitAbbr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.areaWithUnits = areaWithUnits;
  _exports.default = void 0;

  function areaWithUnits(params) {
    var value = params[0];
    var units = params[1];
    var squared = !(units === 'acres' || units === 'hectares');

    if (units === 'paces') {
      units = 'feet';
    }

    if (value < 10) {
      return "".concat((0, _numeral.default)((0, _convert.default)(value, 'area').to(units)).format('0,0.00'), " ").concat((0, _unitAbbr.default)(units), " ").concat(squared ? '²' : '');
    } else if (value >= 10 && value < 100) {
      return "".concat((0, _numeral.default)((0, _convert.default)(value, 'area').to(units)).format('0,0.0'), " ").concat((0, _unitAbbr.default)(units), " ").concat(squared ? '²' : '');
    } else {
      return "".concat((0, _numeral.default)((0, _convert.default)(value, 'area').to(units)).format('0,0'), " ").concat((0, _unitAbbr.default)(units), " ").concat(squared ? '²' : '');
    }
  }

  var _default = Ember.Helper.helper(areaWithUnits);

  _exports.default = _default;
});