define("superfly/components/dropdown-select/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown-selector', 'd-flex', 'flex-row'],
    label: null,
    icon: null,
    value: null,
    selected: null,
    visible: false,
    options: [],
    computedOptions: Ember.computed('options', function computedOptions() {
      var options = Ember.get(this, 'options');
      var label = Ember.get(this, 'label');
      var icon = Ember.get(this, 'icon');
      var defaultOptions = [{
        label: label ? label : 'None',
        icon: icon,
        value: null
      }];
      return defaultOptions.concat(options);
    }),
    willInsertElement: function willInsertElement() {
      // Load selected value if one was defined
      var value = Ember.get(this, 'value');
      var options = Ember.get(this, 'computedOptions');
      var selected = (0, _lodash.find)(options, function (option) {
        return option.value === value;
      });
      Ember.set(this, 'selected', selected);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      // Event listener for closing popover
      this.$().on('mouseleave', '.dropdown-menu', function () {
        Ember.set(_this, 'visible', false);
      });
    },
    actions: {
      show: function show() {
        Ember.set(this, 'visible', true);
      },
      select: function select(option) {
        Ember.set(this, 'value', option.value);
        Ember.set(this, 'selected', option);
        this.sendAction('select', option);
        Ember.set(this, 'visible', false);
      }
    }
  });

  _exports.default = _default;
});