define("superfly/serializers/shared-overlay", ["exports", "superfly/serializers/overlay"], function (_exports, _overlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _overlay.default.extend({});

  _exports.default = _default;
});