define("superfly/components/form-text-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iLL+Gtav",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"form-group \",[21,\"componentCssClassName\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[27,\"input\",[[27,\"-input-type\",[[23,[\"type\"]]],null]],[[\"type\",\"class\",\"disabled\",\"placeholder\",\"value\",\"enter\"],[[23,[\"type\"]],\"form-control mb-0\",[23,[\"disabled\"]],[23,[\"placeholder\"]],[23,[\"value\"]],[23,[\"enter\"]]]]],false],[0,\"\\n  \"],[7,\"small\"],[12,\"class\",[28,[\"form-text error \",[27,\"if\",[[23,[\"error\"]],\"\",\"invisible \"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"error\"]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"error\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      | \"],[2,\" Maintain space when there is no error to prevent jumping around \"],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"style\"],[9],[0,\"\\n  input[type=color] {\\n    padding: 0px !important\\n  }\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/components/form-text-input/template.hbs"
    }
  });

  _exports.default = _default;
});