define("superfly/pods/application/components/object-type-icon-terrain/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'svg',
    attributeBindings: ['xmlns', 'viewBox'],
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '-8487 -13025 36 36'
  });

  _exports.default = _default;
});