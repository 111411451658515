define("superfly/components/spinner-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XFbBckFa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"data-test-selector\",[21,\"testSelector\"]],[12,\"disabled\",[21,\"isDisabled\"]],[12,\"type\",[21,\"type\"]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"material-spinner\",null,[[\"size\"],[\"xs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/components/spinner-button/template.hbs"
    }
  });

  _exports.default = _default;
});