define("superfly/utils/read-file-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = readFileJson;
  var Promise = Ember.RSVP.Promise;

  function readFileJson(jsonFile) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (event) {
        return resolve(JSON.parse(event.target.result));
      };

      reader.onerror = function () {
        return reject('An error occurred reading your flight.json file');
      };

      reader.readAsText(jsonFile);
    });
  }
});