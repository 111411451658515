define("superfly/pods/application/components/action-type-icon-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OreYYBPr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M3.1,14H0V10.933l8.26-8.26,3.1,3.1Zm9.05-9.05L9.08,1.852,10.69.243a.807.807,0,0,1,1.154,0l1.944,1.944a.807.807,0,0,1,0,1.154Z\"],[11,\"transform\",\"translate(-13106 -5489)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/action-type-icon-edit/template.hbs"
    }
  });

  _exports.default = _default;
});