define("superfly/pods/auth/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ipEJEURj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"d-flex flex-row col-12 justify-content-center align-items-center\"],[9],[0,\"\\n\"],[4,\"auth/components/auth-bar\",null,[[\"class\"],[\"col-xs-12 col-md-8 col-lg-6\"]],{\"statements\":[[0,\"    \"],[1,[21,\"liquid-outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});