define("superfly/pods/application/components/upload-type-icon-3d/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NqmMBmmV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M93.569,75.519a5.415,5.415,0,0,1-2.587,4.747L49.587,103.137c-.862.863-1.725.863-3.018.863a6.229,6.229,0,0,1-3.018-.863L2.156,80.266A6.184,6.184,0,0,1,0,75.519V28.481a5.415,5.415,0,0,1,2.587-4.747L43.982.863a5.709,5.709,0,0,1,6.037,0L91.413,23.734A4.958,4.958,0,0,1,94,28.481V75.519Zm-83.22-3.452L41.395,89.759V54.8L10.349,37.112ZM47,11.22,15.954,28.481,47,45.743,78.046,28.481ZM83.22,72.066V37.112L51.743,54.8V89.759Z\"],[11,\"transform\",\"translate(-19759 -12088)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/upload-type-icon-3d/template.hbs"
    }
  });

  _exports.default = _default;
});