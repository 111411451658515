define("superfly/pods/application/components/delete-confirm-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fNA03xYa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal-dialog\",null,[[\"containerClass\",\"onClose\"],[[23,[\"containerClass\"]],[23,[\"cancel\"]]]],{\"statements\":[[0,\"  \"],[7,\"form\"],[9],[0,\"\\n    \"],[7,\"header\"],[9],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"Are you sure you want to delete?\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"section\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"warning-banner\"],[9],[0,\"Negative consequences can occur if you don't read this!\"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"This action \"],[7,\"strong\"],[9],[0,\"CANNOT\"],[10],[0,\" be undone. This will \"],[7,\"strong\"],[9],[0,\"PERMANENTLY\"],[10],[0,\" delete the \"],[1,[21,\"objectType\"],false],[0,\" \"],[7,\"strong\"],[9],[1,[21,\"objectName\"],false],[10],[0,\".\"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"Please type \"],[7,\"strong\"],[9],[0,\"DELETE\"],[10],[0,\" to confirm.\"],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"class\",\"value\"],[\"form-control\",[23,[\"confirmation\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"footer\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"waiting\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isWaiting\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"material-spinner\",null,[[\"size\"],[\"xs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[9],[0,\"cancel\"],[3,\"action\",[[22,0,[]],\"no\"]],[10],[0,\"\\n        \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-danger \",[27,\"if\",[[23,[\"canConfirm\"]],\"\",\"disabled\"],null]]]],[9],[0,\"delete\"],[3,\"action\",[[22,0,[]],\"yes\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"yes\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/delete-confirm-modal/template.hbs"
    }
  });

  _exports.default = _default;
});