define("superfly/mixins/map-drawable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    tagName: 'li',
    flight: null,
    measurements: Ember.inject.service(),
    isDisabled: Ember.computed('flight.enabledMeasurements.[]', function isDisabled() {
      var enabled = Ember.get(this, 'flight.enabledMeasurements') || [];
      return !enabled.includes(Ember.get(this, 'type'));
    }),
    start: function start() {
      Ember.get(this, 'measurements').startCreate(Ember.get(this, 'type'));
    },
    stop: function stop() {
      Ember.get(this, 'measurements').stopCreate();
    },
    actions: {
      toggleDraw: function toggleDraw() {
        if (!Ember.get(this, 'isDisabled')) {
          if (Ember.get(this, 'isActive')) {
            return this.stop();
          }

          return this.start();
        }
      }
    }
  });

  _exports.default = _default;
});