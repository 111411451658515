define("superfly/pods/application/components/flight-agx-season/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['details', 'b-b'],
    agx: Ember.inject.service(),
    season: null,
    seasons: Ember.computed.alias('agx.seasons'),
    year: '',
    years: Ember.computed('seasons', function years() {
      var seasons = Ember.get(this, 'seasons');
      return seasons.mapBy('year').uniq();
    }),
    hasYear: Ember.computed.bool('year'),
    filteredSeasons: Ember.computed('seasons', 'year', function names() {
      var _Ember$getProperties = Ember.getProperties(this, ['seasons', 'year']),
          seasons = _Ember$getProperties.seasons,
          year = _Ember$getProperties.year;

      return seasons.filterBy('year', year);
    }),
    correctedSeasons: Ember.computed.map('filteredSeasons', function correctedSeasons(item) {
      if (isNaN(item.name)) {
        return item;
      }

      var newItem = Object.assign({}, item);
      newItem.name = "".concat(item.name, " 1st Crop");
      return newItem;
    }),
    actions: {
      selectAgxYear: function selectAgxYear(year) {
        Ember.set(this, 'year', year);
        Ember.set(this, 'season', null);
      },
      selectAgxName: function selectAgxName(season) {
        Ember.set(this, 'season', season);
        Ember.get(this, 'seasonSelected')(season);
      }
    }
  });

  _exports.default = _default;
});