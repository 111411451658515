define("superfly/routes/logout", ["exports", "superfly/mixins/route-redirect"], function (_exports, _routeRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeRedirect.default, {
    redirectToRoute: 'auth.logout'
  });

  _exports.default = _default;
});