define("superfly/helpers/truncate-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncateString = truncateString;
  _exports.default = void 0;

  function truncateString(params
  /*, hash*/
  ) {
    var ret = params[0];
    var maxLength = params[1];

    if (maxLength && ret.length > maxLength) {
      return ret.substr(0, maxLength - 3) + "...";
    }

    return params[0];
  }

  var _default = Ember.Helper.helper(truncateString);

  _exports.default = _default;
});