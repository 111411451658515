define("superfly/initializers/setup-component-css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This initializer overrides a computed property set in ember-component-css, and
  // allows that addon to apply custom styles to components nested inside a
  // `/components` subdirectory inside of a pod.
  //
  // Original implementation:
  //
  //    _componentIdentifier: computed({
  //      get() {
  //        return (this._debugContainerKey || '').replace('component:', '');
  //      }
  //    })
  //
  var _default = {
    name: 'setup-component-css',
    initialize: function initialize() {
      Ember.Component.reopen({
        _componentIdentifier: Ember.computed({
          get: function get() {
            return (this._debugContainerKey || '').replace(/(\/?)component(:|s)/g, '');
          }
        })
      });
    }
  };
  _exports.default = _default;
});