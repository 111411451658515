define("superfly/adapters/overlay", ["exports", "ember-data", "superfly/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-ajax/mixins/ajax-support"], function (_exports, _emberData, _environment, _dataAdapterMixin, _ajaxSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend(_dataAdapterMixin.default, _ajaxSupport.default, {
    authorizer: 'authorizer:custom',
    host: _environment.default.BOTLINK_TYLER_API_URL,
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    pathForType: function pathForType() {
      return 'overlays';
    },
    headers: Ember.computed('session.accessToken', function headers() {
      var header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
      var authorization = Ember.get(this, 'session.shareToken') || Ember.get(this, 'session.accessToken');

      if (authorization) {
        header.Authorization = authorization;
      }

      return header;
    }),

    /**
     * Custom implementation.
     * https://github.com/simplabs/ember-simple-auth/blob/1.2.0-beta.1/addon/mixins/data-adapter-mixin.js#L122
     *
     * Instead of invalidating the session when it receives a `401`, this will
     * instead simply notify the user the action he attempted is unauthorized.
     */
    handleResponse: function handleResponse(status) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        Ember.get(this, 'notify').alert('Unauthorized');
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});