define("superfly/instance-initializers/service-boot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    instance.lookup('service:integrations');
    instance.lookup('service:event-bus');
    instance.lookup('service:procore');
    instance.lookup('service:egnyte');
    instance.lookup('service:agx');
  }

  var _default = {
    initialize: initialize,
    name: 'service-boot'
  };
  _exports.default = _default;
});