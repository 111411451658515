define("superfly/pods/application/components/new-folder-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    name: null,
    error: null,
    isWaiting: false,
    hasError: Ember.computed.bool('error'),
    currentFolder: null,
    didInsertElement: function didInsertElement() {
      this.$('input').focus();
    },
    containerClass: Ember.computed('id', function () {
      return 'modal-xs';
    }),
    canSubmit: Ember.computed('name', function canSubmit() {
      var name = Ember.get(this, 'name') || '';
      return name.length > 0;
    }),
    actions: {
      save: function save() {
        var _this = this;

        var _Ember$getProperties = Ember.getProperties(this, ['store', 'notify', 'session', 'name', 'currentFolder', 'canSubmit']),
            store = _Ember$getProperties.store,
            notify = _Ember$getProperties.notify,
            session = _Ember$getProperties.session,
            name = _Ember$getProperties.name,
            currentFolder = _Ember$getProperties.currentFolder,
            canSubmit = _Ember$getProperties.canSubmit;

        if (canSubmit) {
          var props = {
            name: name,
            folder: currentFolder,
            company: Ember.get(session, 'currentCompany')
          };
          var newFolder = store.createRecord('folder', props);
          Ember.set(this, 'isWaiting', true);
          newFolder.save().then(function () {
            notify.success('New folder created');

            _this.sendAction('close');
          }).catch(function (e) {
            Ember.set(_this, 'isWaiting', false);
            notify.error('Could not create folder');
            Ember.set(_this, 'error', e);
          });
        }
      },
      cancel: function cancel() {
        this.sendAction('close');
      }
    }
  });

  _exports.default = _default;
});