define("superfly/pods/application/components/upgrade-subscription/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "apdx0I88",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"canUpgrade\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"plans.select-plan\"],[[\"class\"],[\"upgrade\"]],{\"statements\":[[0,\"upgrade\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/upgrade-subscription/template.hbs"
    }
  });

  _exports.default = _default;
});