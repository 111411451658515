define("superfly/utils/convert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convert;
  var formulas = {
    meters: {
      meters: 1,
      feet: 3.28084,
      paces: 0.762,
      yards: 1.0936133333333,
      miles: 0.00062137121212119323429,
      kilometers: 0.001
    },
    squareMeters: {
      meters: 1,
      feet: 10.7639,
      yards: 1.19598888894151,
      acres: 0.000247105,
      hectares: 0.0001,
      miles: 3.861017849113862317e-7,
      hectare: 1e-4,
      kilometers: 1e-6
    },
    cubicMeters: {
      meters: 1,
      feet: 35.3147,
      yards: 1.30795,
      miles: 2.39913e-10,
      kilometers: 1e-9
    }
  };

  function convert(value, type) {
    var formula;
    var meters;
    meters = value;
    formula = formulas.meters;

    if (type === 'area') {
      formula = formulas.squareMeters;
    }

    if (type === 'volume') {
      formula = formulas.cubicMeters;
    }

    function to(unit) {
      return meters * formula[unit];
    }

    return {
      to: to
    };
  }
});