define("superfly/pods/frame/settings/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1XYXpxOV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content-row d-flex flex-row\"],[9],[0,\"\\n  \"],[2,\" sidebar \"],[0,\"\\n\"],[4,\"application/components/site-sidebar\",null,null,{\"statements\":[[0,\"    \"],[1,[21,\"frame/settings/components/settings-nav\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[2,\" content \"],[0,\"\\n\"],[4,\"application/components/site-content\",null,null,{\"statements\":[[0,\"    \"],[1,[21,\"liquid-outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});