define("superfly/pods/application/components/svg-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['svg-icon'],
    classNameBindings: ['error'],
    family: null,
    icon: null,
    error: false,
    icons: [{
      family: 'action',
      icon: 'color'
    }, {
      family: 'action',
      icon: 'checked'
    }, {
      family: 'action',
      icon: 'unchecked'
    }, {
      family: 'action',
      icon: 'addnew'
    }, {
      family: 'action',
      icon: 'edit'
    }, {
      family: 'action',
      icon: 'delete'
    }, {
      family: 'action',
      icon: 'clouddownload'
    }, {
      family: 'object',
      icon: 'folder'
    }, {
      family: 'object',
      icon: 'survey'
    }, {
      family: 'object',
      icon: 'vegetation'
    }, {
      family: 'object',
      icon: 'terrain'
    }, {
      family: 'object',
      icon: '3d'
    }, {
      family: 'tool',
      icon: 'select'
    }, {
      family: 'tool',
      icon: 'area'
    }, {
      family: 'tool',
      icon: 'distance'
    }, {
      family: 'tool',
      icon: 'comment'
    }, {
      family: 'tool',
      icon: 'product'
    }, {
      family: 'upload',
      icon: 'survey'
    }, {
      family: 'upload',
      icon: 'vegetation'
    }, {
      family: 'upload',
      icon: 'terrain'
    }, {
      family: 'upload',
      icon: '3d'
    }],
    iconComponentName: Ember.computed('family', function iconComponentName() {
      var icons = Ember.get(this, 'icons');
      var family = Ember.get(this, 'family') || '';
      var icon = Ember.get(this, 'icon') || '';
      var iconRef = icons.find(function (item) {
        return item.family === family && item.icon === icon.toLowerCase();
      });

      if (iconRef) {
        return "application/components/".concat(iconRef.family, "-type-icon-").concat(iconRef.icon);
      }
    })
  });

  _exports.default = _default;
});