define("superfly/utils/static-flight-map", ["exports", "L", "lodash/assign", "superfly/config/environment"], function (_exports, _L, _assign, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = staticFlightMap;

  function staticFlightMap(flight) {
    var width = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 280;
    var height = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 175;
    var outline = Ember.get(flight, 'outline');

    if (outline && Ember.get(outline, 'length') > 0) {
      var converted = outline.map(function (point) {
        return [point.lat, point.lng];
      });

      var polygon = _L.default.polygon(converted);

      var geoJSON = polygon.toGeoJSON();

      try {
        (0, _assign.default)(geoJSON.properties, {
          stroke: '#e68f23',
          'stroke-width': 2,
          'stroke-opacity': 0.8,
          fill: '#82c449',
          'fill-opacity': 0.6
        });
        var params = ['https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/static', "geojson(".concat(encodeURIComponent(JSON.stringify(geoJSON)), ")"), "auto", "".concat(width, "x").concat(height)];
        return "".concat(params.join('/'), "?&access_token=").concat(_environment.default.MAPBOX_API_KEY, "&attribution=false&logo=false");
      } catch (e) {// noop
      }
    }

    return null;
  }
});