define("superfly/resolver", ["exports", "ember-resolver"], function (_exports, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberResolver.default.extend({
    moduleNameLookupPatterns: Ember.computed({
      get: function get() {
        var defaultLookup = this._super();

        return [this.componentResolver].concat(defaultLookup);
      }
    }),
    // app/components/<name>/component
    // app/components/<name>/template
    componentResolver: function componentResolver(parsedName) {
      var fullNameWithoutType = parsedName.fullNameWithoutType,
          type = parsedName.type;

      if (type === 'component') {
        return "".concat(this.prefix(parsedName), "/components/").concat(fullNameWithoutType, "/").concat(type);
      } else if (type === 'template') {
        return "".concat(this.prefix(parsedName), "/").concat(fullNameWithoutType, "/").concat(type);
      }
    }
  });

  _exports.default = _default;
});