define("superfly/pods/frame/dashboard/flights/flight/components/theme-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['themes'],
    current: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'themes', ['ltgreen', 'green', 'dkgreen', 'magenta', 'ltpurple', 'purple', 'dkpurple', 'darken', 'aqua', 'teal', 'ltblue', 'blue', 'dkblue', 'yellow', 'orange', 'dkorange', 'red', 'dkred']);
    },
    actions: {
      select: function select(value) {
        Ember.get(this, 'select')(value);
      }
    }
  });

  _exports.default = _default;
});