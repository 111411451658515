define("superfly/services/event-bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    _subscriptionMap: Ember.computed(function () {
      return {};
    }),
    publish: function publish(name) {
      var _this = this;

      for (var _len = arguments.length, messages = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        messages[_key - 1] = arguments[_key];
      }

      var markedForCleanup = [];
      var map = Ember.get(this, "_subscriptionMap.".concat(name)) || [];
      map.forEach(function (subscription) {
        if (Ember.isNone(subscription.context) || Ember.get(subscription.context, 'isDestroyed')) {
          return markedForCleanup.push(subscription.context);
        }

        subscription.callback.apply(subscription.context, messages);
      });
      markedForCleanup.forEach(function (context) {
        return _this.unsubscribe(name, context);
      });
    },
    subscribe: function subscribe(name, context, callback) {
      var subscriptions = Ember.get(this, "_subscriptionMap.".concat(name)) || Ember.set(this, "_subscriptionMap.".concat(name), []);
      subscriptions.push({
        callback: callback,
        context: context
      });
    },
    unsubscribe: function unsubscribe(name, context, callback) {
      var subscriptionMap = Ember.get(this, "_subscriptionMap");
      var subscriptions = Ember.get(subscriptionMap, name);

      if (Ember.isBlank(subscriptions)) {
        return;
      }

      var blankCallback = Ember.isBlank(callback);
      var indices = subscriptions.reduce(function (indices, subscription, index) {
        if ((blankCallback || subscription.callback === callback) && subscription.context === context) {
          indices.push(index);
        }

        return indices;
      }, []);
      indices.reverse().forEach(function (index) {
        return subscriptions.splice(index, 1);
      });

      if (subscriptions.length === 0) {
        delete subscriptionMap[name];
      }
    },
    unsubscribeAll: function unsubscribeAll(context) {
      var _this2 = this;

      var subscriptionMap = Ember.get(this, '_subscriptionMap');
      Object.keys(subscriptionMap).forEach(function (name) {
        return _this2.unsubscribe(name, context);
      });
    }
  });

  _exports.default = _default;
});