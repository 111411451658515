define("superfly/pods/application/components/object-type-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['object-type-icon'],
    classNameBindings: ['error'],
    type: null,
    error: false,
    iconTypes: ['folder', 'survey', 'vegetation', 'terrain', '3d'],
    iconComponentName: Ember.computed('type', function iconComponentName() {
      var iconTypes = Ember.get(this, 'iconTypes');
      var type = Ember.get(this, 'type');

      if (iconTypes.indexOf(type) !== -1) {
        return "application/components/object-type-icon-".concat(type);
      }

      return 'application/components/object-type-icon-survey';
    })
  });

  _exports.default = _default;
});