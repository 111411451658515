define("superfly/components/material-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dWMKYiwn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"class\",\"circular\"],[11,\"viewBox\",\"25 25 50 50\"],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"class\",\"path\"],[11,\"cx\",\"50\"],[11,\"cy\",\"50\"],[11,\"r\",\"20\"],[11,\"fill\",\"none\"],[11,\"stroke-width\",\"2\"],[11,\"stroke-miterlimit\",\"10\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/components/material-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});