define("superfly/pods/frame/settings/profile/components/user-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "20QK7FvI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h4\"],[9],[0,\"Current password\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[7,\"span\"],[11,\"class\",\"bullets\"],[9],[0,\"•••••••••••••\"],[10],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"text-button\"],[9],[0,\"Change\"],[3,\"action\",[[22,0,[]],\"startEdit\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"application/components/reset-password-form\",null,[[\"onCancel\",\"onPasswordUpdated\",\"changeset\",\"token\"],[[27,\"action\",[[22,0,[]],\"stopEdit\"],null],[27,\"action\",[[22,0,[]],\"onPasswordUpdated\"],null],[23,[\"changeset\"]],[23,[\"accessToken\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/profile/components/user-password/template.hbs"
    }
  });

  _exports.default = _default;
});