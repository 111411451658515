define("superfly/pods/frame/plans/checkout/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Checkout',
    queryParams: {
      planId: {
        replace: true
      }
    },
    planId: null,
    model: function model() {
      return Ember.RSVP.hash({
        paymentMethod: this.modelFor('frame.botlink-product.plans')
      });
    }
  });

  _exports.default = _default;
});