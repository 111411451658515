define("superfly/models/payment-method", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    company: belongsTo('company'),
    addressCity: attr('string'),
    addressCountry: attr('string'),
    addressLine1: attr('string'),
    addressLine1Check: attr('string'),
    addressLine2: attr('string'),
    addressState: attr('string'),
    addressZip: attr('string'),
    addressZipCheck: attr('string'),
    brand: attr('string'),
    country: attr('string'),
    customer: attr('string'),
    cvcCheck: attr('boolean'),
    dynamicLast4: attr('string'),
    expMonth: attr('number'),
    expYear: attr('number'),
    fingerprint: attr('string'),
    funding: attr('string'),
    last4: attr('number'),
    metadata: attr(),
    name: attr('string'),
    object: attr('string'),
    tokenizationMethod: attr('string')
  });

  _exports.default = _default;
});