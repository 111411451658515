define("superfly/pods/frame/settings/account/components/mapstyle-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "maGp7rOZ",
    "block": "{\"symbols\":[\"xs\",\"option\"],\"statements\":[[4,\"x-select\",null,[[\"value\",\"action\",\"class\"],[[23,[\"selected\"]],\"selectMapStyle\",\"form-control\"]],{\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('superfly/pods/frame/settings/account/components/mapstyle-selector/template.hbs' @ L3:C7) \"],null]],[[\"value\"],[[22,2,[\"style\"]]]],{\"statements\":[[1,[22,2,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/account/components/mapstyle-selector/template.hbs"
    }
  });

  _exports.default = _default;
});