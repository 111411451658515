define("superfly/components/spinner-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays a button that renders a spinner when action is loading.
   *
   * This component _must_ take a _thenable_ function (returns a `Promise`) for
   * The spinner to start and stop correctly. The first aruement is the text to
   * display inside the button.
   *
   * @module
   * @augments ember/Component
   */
  var SpinnerButton = Ember.Component.extend({
    tagName: 'span',

    /**
     * Button type.
     *
     * @type {String}
     * @default 'submit'
     */
    type: 'submit',

    /**
     * Value for `data-test-selector`. Defaults to `spinner-button`.
     *
     * @type {String}
     * @default 'spinner-button'
     */
    testSelector: 'spinner-button',

    /**
     * Indicates whether the component's action is currently in progress.
     *
     * @type {Boolean}
     * @default false
     */
    isLoading: false,

    /**
     * Indicates whether the button is currently disabled.
     *
     * @type {Boolean}
     * @default false
     */
    isDisabled: false,

    /**
     * Action handler for click event.
     *
     * Calls the `onClick` action passed in at the component invocation (which
     * must be _thenable_). The `click` event starts the spinner, and the resolving
     * (or rejecting) of `onClick` stops it.
     *
     * @function
     * @returns {undefined}
     */
    click: function click() {
      var _this = this;

      this._activateSpinner();

      Ember.get(this, 'onClick')().finally(function () {
        return _this._deactivateSpinner();
      });
    },

    /**
     * Sets component properties to indicate a loading state.
     *
     * @function
     * @private
     * @returns {undefined}
     */
    _activateSpinner: function _activateSpinner() {
      Ember.setProperties(this, {
        isLoading: true,
        isDisabled: true
      });
    },

    /**
     * Sets component properties to indicate a non-loading state.
     *
     * @function
     * @private
     * @returns {undefined}
     */
    _deactivateSpinner: function _deactivateSpinner() {
      Ember.setProperties(this, {
        isLoading: false,
        isDisabled: false
      });
    }
  });
  SpinnerButton.reopenClass({
    positionalParams: ['text']
  });
  var _default = SpinnerButton;
  _exports.default = _default;
});