define("superfly/utils/convert-units", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultDistanceSetting = {
    name: 'DistanceUnit',
    value: {
      densityUnitName: 'invalid',
      densityMultiplier: 0
    }
  };
  var defaultMassSetting = {
    name: 'MassUnit',
    value: {
      densityUnitName: 'invalid',
      densityMultiplier: 0
    }
  };
  var defaultVolumeSetting = {
    name: 'VolumeUnit',
    value: {
      densityUnitName: 'invalid³',
      densityMultiplier: 0
    }
  };
  var defaultDensitySetting = {
    name: 'DensityUnit',
    value: {
      densityUnitName: 'invalid/invalid³',
      densityMultiplier: 0
    }
  };
  var defaultSettings = [defaultDistanceSetting, defaultMassSetting, defaultVolumeSetting, defaultDensitySetting];

  var _default = Ember.Object.extend({
    session: Ember.inject.service(),
    getSetting: function getSetting(settingName) {
      var settings = Ember.get(this, 'session.currentUser.settings') || defaultSettings;
      return (0, _lodash.find)(settings, function (setting) {
        return setting.name === settingName;
      });
    },
    convertDistanceToCustomUnit: function convertDistanceToCustomUnit(value) {
      var currentSetting = this.getSetting('DistanceUnit');
      return value / currentSetting.value.distanceMultiplier;
    },
    convertDistanceFromCustomUnit: function convertDistanceFromCustomUnit(value) {
      var currentSetting = this.getSetting('DistanceUnit');
      return value * currentSetting.value.distanceMultiplier;
    },
    convertAreaToCustomUnit: function convertAreaToCustomUnit(value) {
      var currentSetting = this.getSetting('DistanceUnit');
      return value / Math.pow(currentSetting.value.distanceMultiplier, 2);
    },
    convertAreaFromCustomUnit: function convertAreaFromCustomUnit(value) {
      var currentSetting = this.getSetting('DistanceUnit');
      return value * Math.pow(currentSetting.value.distanceMultiplier, 2);
    },
    convertMassToCustomUnit: function convertMassToCustomUnit(value) {
      var currentSetting = this.getSetting('MassUnit');
      return value / currentSetting.value.massMultiplier;
    },
    convertMassFromCustomUnit: function convertMassFromCustomUnit(value) {
      var currentSetting = this.getSetting('MassUnit');
      return value * currentSetting.value.massMultiplier;
    },
    convertVolumeToCustomUnit: function convertVolumeToCustomUnit(value) {
      var currentSetting = this.getSetting('VolumeUnit');
      return value / currentSetting.value.volumeMultiplier;
    },
    convertVolumeFromCustomUnit: function convertVolumeFromCustomUnit(value) {
      var currentSetting = this.getSetting('VolumeUnit');
      return value * currentSetting.value.volumeMultiplier;
    },
    convertDensityToCustomUnit: function convertDensityToCustomUnit(value) {
      var currentSetting = this.getSetting('DensityUnit');
      return value / currentSetting.value.densityMultiplier;
    },
    convertDensityFromCustomUnit: function convertDensityFromCustomUnit(value) {
      var currentSetting = this.getSetting('DensityUnit');
      return value * currentSetting.value.densityMultiplier;
    }
  });

  _exports.default = _default;
});