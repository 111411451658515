define("superfly/models/share", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    active: attr('boolean'),
    token: attr('string'),
    password: attr('string'),
    flightId: attr('string'),
    flight: belongsTo('flight', {
      inverse: 'share'
    }),
    sharedFlight: belongsTo('shared-flight', {
      inverse: 'share'
    })
  });

  _exports.default = _default;
});