define("superfly/pods/frame/sites/site/maps/route", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Site',
    model: function model(params) {
      var sites = [
        /*
        {
        id: '1',
        name: 'South Pit',
        siteId: '1',
        city: 'Fargo',
        state: 'ND',
        contactName: 'Bruce Wayne',
        officeNumber: '(701) 555-1234',
        lastFlown: "2018-09-14T17:11:10.727Z",
        totalFlights: 12,
        totalProducts: 4,
        totalValue: 1733517.21,
        totalMeasurements: 4
        },{
        id: '2',
        name: 'Fergus Falls Pit',
        siteId: '2',
        city: 'Fergus Falls',
        state: 'MN',
        contactName: 'Bruce Wayne',
        officeNumber: '(701) 555-1234',
        lastFlown: "2018-09-14T17:11:10.727Z",
        totalFlights: 12,
        totalProducts: 4,
        totalValue: 1733517.21,
        totalMeasurements: 4
        },{
        id: '3',
        name: 'Bismarck Pit',
        siteId: '3',
        city: 'Bismarck',
        state: 'ND',
        contactName: 'Bruce Wayne',
        officeNumber: '(701) 555-1234',
        lastFlown: "2018-09-14T17:11:10.727Z",
        totalFlights: 12,
        totalProducts: 4,
        totalValue: 1733517.21,
        totalMeasurements: 4
        },{
        id: '4',
        name: 'Pit 41',
        siteId: '41',
        city: 'Sabin',
        state: 'MN',
        contactName: 'Bruce Wayne',
        officeNumber: '(701) 555-1234',
        lastFlown: "2018-09-14T17:11:10.727Z",
        totalFlights: 12,
        totalProducts: 4,
        totalValue: 1733517.21,
        totalMeasurements: 4
        }
        */
      ];
      var site = (0, _lodash.find)(sites, {
        id: params.siteId
      });
      return {
        site: site
      };
    },
    setupController: function setupController(controller, model) {
      var site = model.site;
      controller.set('site', site);
    }
  });

  _exports.default = _default;
});