define("superfly/pods/frame/products/components/product-card/component", ["exports", "lodash", "superfly/utils/convert-units"], function (_exports, _lodash, _convertUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'article',
    product: null,
    sites: [],
    lastFlights: [],
    massCustomUnitLabel: Ember.computed('product.id', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('MassUnit').value.massUnitName;
      return unitName;
    }),
    stats: Ember.computed('product', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var productId = Ember.get(this, 'product.id');
      var sites = Ember.get(this, 'sites');
      var lastFlights = Ember.get(this, 'lastFlights');
      var productSites = (0, _lodash.filter)(sites.toArray(), function (site) {
        var siteProducts = Ember.get(site, 'products').toArray();
        var product = (0, _lodash.find)(siteProducts, function (siteProduct) {
          var siteProductId = Ember.get(siteProduct, 'product.id');
          return siteProductId === productId;
        });
        return product;
      });
      var stats = {}; // TODO: This repeating is gross refactor/cleanup please

      stats.totalWeight = (0, _lodash.sumBy)(lastFlights, function (lastFlight) {
        var site = (0, _lodash.find)(productSites, function (productSite) {
          return Ember.get(productSite, 'id') === lastFlight.siteId;
        });

        if (!site) {
          return 0;
        }

        var flightAttributes = lastFlight.flight.readings ? lastFlight.flight.readings[0].attributes : [];
        var piles = flightAttributes.length > 0 ? (0, _lodash.find)(flightAttributes, function (flightAttribute) {
          return flightAttribute.code === 'piles';
        }).value : [];
        var productPiles = (0, _lodash.filter)(piles, function (pile) {
          return pile.productId === productId;
        });

        if (productPiles.length === 0) {
          return 0;
        }

        var totalWeight = (0, _lodash.sumBy)(productPiles, function (pile) {
          var mass = pile.density * pile.volume;
          return mass;
        });
        return convertUtil.convertMassToCustomUnit(totalWeight);
      });
      stats.locations = productSites.length;
      stats.totalValue = (0, _lodash.sumBy)(lastFlights, function (lastFlight) {
        var site = (0, _lodash.find)(productSites, function (productSite) {
          return Ember.get(productSite, 'id') === lastFlight.siteId;
        });

        if (!site) {
          return 0;
        }

        var siteProducts = Ember.get(site, 'products').toArray();
        var siteProduct = (0, _lodash.find)(siteProducts, function (siteProduct) {
          return Ember.get(siteProduct, 'product.id') === productId;
        });
        var flightAttributes = lastFlight.flight.readings ? lastFlight.flight.readings[0].attributes : [];
        var piles = flightAttributes.length > 0 ? (0, _lodash.find)(flightAttributes, function (flightAttribute) {
          return flightAttribute.code === 'piles';
        }).value : [];
        var productPiles = (0, _lodash.filter)(piles, function (pile) {
          return pile.productId === productId;
        });

        if (productPiles.length === 0) {
          return 0;
        }

        return (0, _lodash.sumBy)(productPiles, function (pile) {
          var mass = pile.density * pile.volume;
          var totalCost = mass * pile.cost;
          return totalCost;
        });
      }); // Cost should be inverted

      stats.globalCost = (0, _lodash.round)(convertUtil.convertMassFromCustomUnit(Ember.get(this, 'product.globalCost')), 2);
      return stats;
    })
  });

  _exports.default = _default;
});