define("superfly/pods/application/components/map-controls/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vMiOG6pD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"button current-position \",[27,\"if\",[[23,[\"currentPositionIsLoading\"]],\"loading\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"currentPositionIsLoading\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"material-spinner\",null,[[\"size\"],[\"xs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"my_location\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[22,0,[]],\"markCurrentPosition\"]],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"button\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"add\"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"zoomIn\"]],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"button\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"remove\"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"zoomOut\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/map-controls/template.hbs"
    }
  });

  _exports.default = _default;
});