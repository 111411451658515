define("superfly/transforms/array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return Ember.isArray(serialized) ? serialized : [];
    },
    serialize: function serialize(deserialized) {
      var type = Ember.typeOf(deserialized);

      if (type === 'array') {
        return deserialized;
      }

      if (type === 'string') {
        return deserialized.split(',').map(function (item) {
          return item.trim();
        });
      }

      return [];
    }
  });

  _exports.default = _default;
});