define("superfly/pods/application/components/style-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mainMap: Ember.inject.service(),
    options: Ember.computed.alias('mainMap.mapStyleOptions'),
    selected: Ember.computed.alias('mainMap.mapStyle.style'),
    actions: {
      selectMapStyle: function selectMapStyle(value) {
        Ember.set(this, 'mainMap.mapStyleSelected', value);
      }
    }
  });

  _exports.default = _default;
});