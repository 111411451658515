define("superfly/adapters/share", ["exports", "superfly/adapters/application", "superfly/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.BOTLINK_USERS_API_URL,
    headers: Ember.computed('session.shareToken', function headers() {
      var header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
      var authorization = Ember.get(this, 'session.shareToken') || Ember.get(this, 'session.accessToken');

      if (authorization) {
        header.Authorization = authorization;
      }

      return header;
    }),
    pathForType: function pathForType() {
      return 'flightshares';
    }
  });

  _exports.default = _default;
});