define("superfly/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // --------------- Start Top Level Module ---------------
    this.transition(this.fromRoute('auth'), this.toRoute('settings'), this.use('fade'), this.reverse('fade'));
    this.transition(this.fromRoute('auth'), this.toRoute('dashboard'), this.use('fade'), this.reverse('fade'));
    this.transition(this.fromRoute('dashboard'), this.toRoute('settings'), this.use('fade'), this.reverse('fade'));
    this.transition(this.fromRoute('dashboard'), this.toRoute('sites'), this.use('fade'), this.reverse('fade'));
    this.transition(this.fromRoute('dashboard'), this.toRoute('products'), this.use('fade'), this.reverse('fade')); // --------------- End Top Level Module ---------------
    // --------------- Start Auth Module ---------------

    this.transition(this.fromRoute('auth.login'), this.toRoute('auth.forgot-password'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('auth.login'), this.toRoute('auth.signup'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('auth.login'), this.toRoute('auth.forgot-password'), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('auth.login'), this.toRoute('auth.confirm'), this.use('fade'), this.reverse('fade')); // --------------- End Auth Module ---------------
    // --------------- Start Settings Module ---------------

    this.transition(this.fromRoute('frame.settings.profile'), this.toRoute('frame.settings.account'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('frame.settings.profile'), this.toRoute('frame.settings.billing'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('frame.settings.profile'), this.toRoute('frame.settings.integrations'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('frame.settings.profile'), this.toRoute('frame.settings.units'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('frame.settings.account'), this.toRoute('frame.settings.billing'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('frame.settings.account'), this.toRoute('frame.settings.integrations'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('frame.settings.account'), this.toRoute('frame.settings.units'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('frame.settings.billing'), this.toRoute('frame.settings.integrations'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('frame.settings.billing'), this.toRoute('frame.settings.units'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('frame.settings.integrations'), this.toRoute('frame.settings.units'), this.use('toUp'), this.reverse('toDown')); // --------------- End Settings Module ---------------
    // --------------- Start Dashboard Module ---------------

    this.transition(this.fromRoute('frame.dashboard.folders'), this.toRoute('frame.dashboard.flights'), this.use('fade'), this.reverse('fade')); // --------------- Start Folders Module ---------------

    this.transition(this.fromRoute('frame.dashboard.folders.index'), this.toRoute('frame.dashboard.folders.folder'), this.use('fade'), this.reverse('fade')); // --------------- End Folders Module ---------------
    // --------------- End Dashboard Module ---------------
  }
});