define("superfly/pods/frame/products/product/route", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var model = this.modelFor('frame.products');
      var products = model.products,
          sites = model.sites,
          lastFlights = model.lastFlights;
      var product = (0, _lodash.find)(products.toArray(), function (product) {
        return Ember.get(product, 'id').toString() === params.productId;
      });
      return {
        product: product,
        sites: sites,
        lastFlights: lastFlights
      };
    },
    setupController: function setupController(controller, model) {
      var product = model.product,
          sites = model.sites,
          lastFlights = model.lastFlights;
      controller.set('product', product);
      controller.set('sites', sites);
      controller.set('lastFlights', lastFlights);
    }
  });

  _exports.default = _default;
});