define("superfly/mixins/integration-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    userId: Ember.computed.alias('session.currentUserID'),
    accessToken: Ember.computed.alias('session.accessToken'),
    isConnected: false,
    hasUserAndToken: Ember.computed.and('userId', 'accessToken'),
    shouldConnect: Ember.computed('hasUserAndToken', 'isConnected', function shouldConnect() {
      var _this$getProperties = this.getProperties(['hasUserAndToken', 'isConnected']),
          hasUserAndToken = _this$getProperties.hasUserAndToken,
          isConnected = _this$getProperties.isConnected;

      return hasUserAndToken && !isConnected;
    }),
    unwrapResponse: function unwrapResponse(response) {
      return response[Ember.get(this, '_connectorName')] || response;
    }
  });

  _exports.default = _default;
});