define("superfly/components/collapsed-action-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7HIyR2gY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-ellipsis-v\"],[11,\"aria-hidden\",\"true\"],[9],[10],[3,\"action\",[[22,0,[]],\"show\"],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isCamVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"c-a-m-dropdown\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/components/collapsed-action-menu/template.hbs"
    }
  });

  _exports.default = _default;
});