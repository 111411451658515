define("superfly/pods/frame/dashboard/folders/components/flight-card/component", ["exports", "moment", "superfly/utils/static-flight-map"], function (_exports, _moment, _staticFlightMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "article",
    classNames: ["folder-explorer-card", "has-thumbnail"],
    classNameBindings: ["isProcessing:processing", "isBeingDragged:dragged", "flight.isProcessingFailure:failed", "flight.id"],
    notify: Ember.inject.service(),
    navigationManager: Ember.inject.service(),
    flight: null,
    isBeingDragged: false,
    isCamVisible: false,
    hasStartedDeleting: false,
    hasStartedMoving: false,
    isProcessing: Ember.computed.alias("flight.isProcessing"),
    onDrag: Ember.observer("isBeingDragged", function () {
      if (Ember.get(this, "isBeingDragged")) {
        var height = this.$().css("height");
        this.$().css("height", height);
      } else {
        this.$().css("height", "auto");
      }
    }),
    staticMap: Ember.computed("flight.outline", function staticMap() {
      return (0, _staticFlightMap.default)(Ember.get(this, "flight"));
    }),
    uploadedAndStatusText: Ember.computed("flight.status", function status() {
      var flight = Ember.get(this, "flight");

      var _Ember$getProperties = Ember.getProperties(flight, ["createdAt", "isProcessing", "isProcessingFailure"]),
          createdAt = _Ember$getProperties.createdAt,
          isProcessingFailure = _Ember$getProperties.isProcessingFailure,
          isProcessing = _Ember$getProperties.isProcessing;

      if (isProcessing || isProcessingFailure) {
        return "";
      }

      if (createdAt) {
        return "Uploaded: " + (0, _moment.default)(createdAt).format("MMMM DD, YYYY");
      }
    }),
    statusText: Ember.computed("flight.status", function status() {
      var flight = Ember.get(this, "flight");

      var _Ember$getProperties2 = Ember.getProperties(flight, ["isProcessing", "isProcessingFailure", "startDate"]),
          isProcessing = _Ember$getProperties2.isProcessing,
          isProcessingFailure = _Ember$getProperties2.isProcessingFailure,
          startDate = _Ember$getProperties2.startDate;

      if (isProcessing || isProcessingFailure) {
        return Ember.get(flight, "status");
      }

      if (startDate) {
        return "Flown: " + (0, _moment.default)(startDate).format("MMMM DD, YYYY");
      }

      return "No flight date specified.";
    }),
    actions: {
      viewFlight: function viewFlight() {
        if (!Ember.get(this, "flight.isProcessingFailure")) {
          var afterTransition = Ember.get(this, "afterTransition");

          if (afterTransition) {
            afterTransition();
          }

          this.router.transitionTo("frame.dashboard.flights.flight", Ember.get(this, "flight.id"));
        }
      },
      startMove: function startMove() {
        Ember.set(this, "isCamVisible", false);
        Ember.set(this, "hasStartedMoving", true);
      },
      stopMove: function stopMove() {
        if (!Ember.get(this, "isDestroying") && !Ember.get(this, "isDestroyed")) {
          Ember.set(this, "hasStartedMoving", false);
        }
      },
      startRename: function startRename() {
        Ember.set(this, "isCamVisible", false);
        Ember.set(this, "hasStartedRenaming", true);
      },
      stopRename: function stopRename() {
        if (!Ember.get(this, "isDestroying") && !Ember.get(this, "isDestroyed")) {
          Ember.set(this, "hasStartedRenaming", false);
        }
      },
      startDelete: function startDelete() {
        Ember.set(this, "hasStartedDeleting", true);
      },
      stopDelete: function stopDelete() {
        if (!Ember.get(this, "isDestroying") && !Ember.get(this, "isDestroyed")) {
          Ember.set(this, "hasStartedDeleting", false);
        }
      },
      doDelete: function doDelete() {
        var _this = this;

        var flight = Ember.get(this, "flight");
        var notify = Ember.get(this, "notify");
        Ember.set(this, "isWaiting", true);
        flight.destroyRecord().then(function () {
          return notify.success("Flight deleted successfully");
        }).catch(function (e) {
          Ember.set(_this, "error", e);
          notify.error("Could not delete flight");
          Ember.set(_this, "isWaiting", false);
        });
      }
    }
  });

  _exports.default = _default;
});