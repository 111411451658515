define("superfly/pods/shared/locked/components/share-lock/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DtNgdnXy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"alert alert-danger\"],[9],[1,[21,\"error\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"form\"],[11,\"class\",\"card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"input-group-addon\"],[11,\"id\",\"basic-addon1\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-lock\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"password\",[23,[\"password\"]],\"form-control\",\"password\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-footer\"],[9],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-primary \",[27,\"if\",[[23,[\"isSubmitDisabled\"]],\"disabled\"],null]]]],[11,\"type\",\"submit\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Unlock\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[22,0,[]],\"submit\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"submit\"],null]],[[\"on\"],[\"submit\"]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/shared/locked/components/share-lock/template.hbs"
    }
  });

  _exports.default = _default;
});