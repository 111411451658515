define("superfly/pods/frame/sites/site/flights/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3YVKdTLD",
    "block": "{\"symbols\":[\"currentSite\",\"flight\"],\"statements\":[[4,\"liquid-bind\",[[23,[\"site\"]]],[[\"use\"],[\"fade\"]],{\"statements\":[[4,\"each\",[[23,[\"flightsSortedByDate\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"frame/sites/site/flights/components/measurement-list-item\",null,[[\"site\",\"flight\",\"reloadFlights\"],[[22,1,[]],[22,2,[]],[27,\"action\",[[22,0,[]],\"reloadFlights\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/site/flights/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});