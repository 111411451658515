define("superfly/serializers/overlay", ["exports", "superfly/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(modelClass, resourceHash) {
      var type = Ember.get(resourceHash, 'overlayType');
      var modelSubClass = modelClass;
      var overlayType;

      switch (type) {
        case 'ndviUnprocessed':
        case 'ortho':
        case 'orthomosaic':
          overlayType = 'ortho';
          break;

        case 'ndvi':
        case 'ndviProcessed':
          overlayType = 'ndvi';
          break;

        default:
          overlayType = type;
          break;
      }

      if (type) {
        modelSubClass = this.store.modelFor("overlay-".concat(overlayType));
      }

      return this._super(modelSubClass, resourceHash);
    }
  });

  _exports.default = _default;
});