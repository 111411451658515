define("superfly/pods/frame/sites/site/products/components/product-card/component", ["exports", "lodash", "numeral", "superfly/utils/convert-units"], function (_exports, _lodash, _numeral, _convertUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'product',
    site: null,
    product: null,
    flight: null,
    isCamVisible: false,
    isEditSiteProductModalVisible: false,
    isManageSiteProductModalVisible: false,
    isDeleteSiteProductModalVisible: false,
    // TODO: Need to fix when to recompute these props
    massCustomUnitLabel: Ember.computed('units', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('MassUnit').value.massUnitName;
      return unitName;
    }),
    volumeCustomUnitLabel: Ember.computed('units', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('VolumeUnit').value.volumeUnitName;
      return unitName;
    }),
    densityCustomUnitLabel: Ember.computed('units', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('DensityUnit').value.densityUnitName;
      return unitName;
    }),
    stats: Ember.computed('product', 'product.inventory', 'product.siteDensity', 'product.siteCost', 'product.product.globalCost', 'product.product.globalDensity', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var siteProduct = Ember.get(this, 'product');
      var productId = Ember.get(siteProduct, 'product.id');
      var flight = Ember.get(this, 'flight');
      var flightAttributes = flight.readings ? flight.readings[0].attributes : [];
      var piles = ((0, _lodash.find)(flightAttributes, function (flightAttribute) {
        return flightAttribute.code === 'piles';
      }) || {
        value: []
      }).value;
      var productPiles = (0, _lodash.filter)(piles, function (pile) {
        return pile.productId === productId;
      });
      var volume = convertUtil.convertVolumeToCustomUnit((0, _lodash.sumBy)(productPiles, function (pile) {
        return pile.volume;
      }));
      var pileCount = productPiles.length === 0 ? 1 : productPiles.length;
      var summedPileDensity = (0, _lodash.sumBy)(productPiles, function (pile) {
        return pile.density;
      });
      var averageDensity = convertUtil.convertDensityToCustomUnit(summedPileDensity / pileCount);
      var siteDensity = Ember.get(siteProduct, 'siteDensity');
      var globalDensity = Ember.get(siteProduct, 'product.globalDensity');
      var density = convertUtil.convertDensityToCustomUnit(siteDensity || globalDensity);
      var totalMass = convertUtil.convertMassToCustomUnit((0, _lodash.sumBy)(productPiles, function (pile) {
        return pile.volume * pile.density;
      }));
      var cost = (0, _lodash.round)(convertUtil.convertMassFromCustomUnit(Ember.get(siteProduct, 'siteCost') || Ember.get(siteProduct, 'product.globalCost')), 2);
      var totalValue = (0, _lodash.sumBy)(productPiles, function (pile) {
        return pile.volume * pile.density * pile.cost;
      });
      var linkedMeasurements = (0, _lodash.filter)(productPiles, function (pile) {
        return pile.productId === productId;
      });
      var inventory = convertUtil.convertMassToCustomUnit(Ember.get(siteProduct, 'inventory'));
      var stats = {
        volume: (0, _numeral.default)(volume).format('0,0.00'),
        averageDensity: (0, _numeral.default)(averageDensity).format('0,0.00'),
        siteDensity: siteDensity,
        density: (0, _numeral.default)(density).format('0,0.00'),
        totalMass: (0, _numeral.default)(totalMass).format('0,0.00'),
        cost: cost,
        totalValue: (0, _numeral.default)(totalValue).format('$0,0.00'),
        inventory: inventory,
        linkedMeasurements: linkedMeasurements.length
      };
      return stats;
    }),
    hasMeasurements: Ember.computed('stats', function () {
      var stats = Ember.get(this, 'stats');
      var linkedMeasurements = Ember.get(stats, 'linkedMeasurements');
      return linkedMeasurements > 0;
    }),
    actions: {
      closeEditSiteProductModal: function closeEditSiteProductModal() {
        Ember.set(this, 'isEditSiteProductModalVisible', false);
      },
      openEditSiteProductModal: function openEditSiteProductModal() {
        Ember.set(this, 'isEditSiteProductModalVisible', true);
      },
      closeManageSiteProductModal: function closeManageSiteProductModal() {
        Ember.set(this, 'isManageSiteProductModalVisible', false);
      },
      openManageSiteProductModal: function openManageSiteProductModal() {
        Ember.set(this, 'isManageSiteProductModalVisible', true);
      },
      closeDeleteSiteProductModal: function closeDeleteSiteProductModal() {
        Ember.set(this, 'isDeleteSiteProductModalVisible', false);
      },
      openDeleteSiteProductModal: function openDeleteSiteProductModal() {
        Ember.set(this, 'isDeleteSiteProductModalVisible', true);
      }
    }
  });

  _exports.default = _default;
});