define("superfly/models/site-product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    site: belongsTo('site'),
    product: belongsTo('product'),
    siteDensity: attr('number', {
      defaultValue: 0
    }),
    siteCost: attr('number', {
      defaultValue: 0
    }),
    color: attr('string'),
    inventory: attr('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});