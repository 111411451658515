define("superfly/pods/frame/dashboard/components/route-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'article',
    classNameBindings: ['isActionActive:active'],
    navigationManager: Ember.inject.service(),
    measurements: Ember.inject.service(),
    mainMap: Ember.inject.service(),
    isActionActive: Ember.computed.readOnly('measurements.isActive')
  });

  _exports.default = _default;
});