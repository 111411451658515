define("superfly/pods/application/components/site-left-flyout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ftBDotqs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"menu animated fade-in-left bg-light\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"back\"],[9],[0,\"\\n    \"],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"arrow_back\"],[10],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n    \"],[1,[27,\"component\",[[23,[\"activeComponent\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/site-left-flyout/template.hbs"
    }
  });

  _exports.default = _default;
});