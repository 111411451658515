define("superfly/models/plan", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    displayName: attr('string'),
    planName: attr('string'),
    stripePlanId: attr('string'),
    interval: attr('string'),
    amount: attr('number'),
    features: attr('array'),
    isYearlyPlan: Ember.computed.match('interval', /yearly/),
    isMonthlyPlan: Ember.computed.match('interval', /monthly/),
    totalYearlyAmount: Ember.computed('amount', 'isMonthlyPlan', function () {
      var _Ember$getProperties = Ember.getProperties(this, ['amount', 'isMonthlyPlan']),
          amount = _Ember$getProperties.amount,
          isMonthlyPlan = _Ember$getProperties.isMonthlyPlan;

      return isMonthlyPlan ? amount * 12 : amount;
    }),
    totalYearlyAmountFormatted: Ember.computed('totalYearlyAmount', function () {
      return this.formatUSD(Ember.get(this, 'totalYearlyAmount'));
    }),
    totalMonthlyAmount: Ember.computed('amount', 'isMonthlyPlan', function () {
      var _Ember$getProperties2 = Ember.getProperties(this, ['amount', 'isMonthlyPlan']),
          amount = _Ember$getProperties2.amount,
          isMonthlyPlan = _Ember$getProperties2.isMonthlyPlan;

      return isMonthlyPlan ? amount : amount / 12;
    }),
    totalMonthlyAmountFormatted: Ember.computed('totalMonthlyAmount', function () {
      return this.formatUSD(Ember.get(this, 'totalMonthlyAmount'));
    }),
    totalAmountNow: Ember.computed('amount', 'isMonthlyPlan', function () {
      var _Ember$getProperties3 = Ember.getProperties(this, ['isMonthlyPlan', 'totalYearlyAmount', 'totalMonthlyAmount']),
          isMonthlyPlan = _Ember$getProperties3.isMonthlyPlan,
          totalYearlyAmount = _Ember$getProperties3.totalYearlyAmount,
          totalMonthlyAmount = _Ember$getProperties3.totalMonthlyAmount;

      return isMonthlyPlan ? totalMonthlyAmount : totalYearlyAmount;
    }),
    totalAmountNowFormatted: Ember.computed('totalAmountNow', function () {
      return this.formatUSD(Ember.get(this, 'totalAmountNow'));
    }),
    isBestValue: Ember.computed('planName', function () {
      return Ember.get(this, 'displayName').toLowerCase() === 'standard';
    }),
    escapedFeatures: Ember.computed('features', function () {
      return Ember.get(this, 'features').map(function (feature) {
        return Ember.String.htmlSafe(feature);
      });
    }),
    formatUSD: function formatUSD(cents) {
      return "".concat(cents / 100);
    },
    relativeValue: Ember.computed(function () {
      switch (Ember.get(this, 'displayName')) {
        case 'Starter':
          return 0;

        case 'Standard':
          return 1;

        case 'Professional':
          return 2;

        default:
          return 0;
      }
    }),
    compare: function compare(otherPlan) {
      var thisValue = Ember.get(this, 'relativeValue');
      var thatValue = Ember.get(otherPlan, 'relativeValue');

      if (thisValue > thatValue) {
        return 1;
      } else if (thisValue < thatValue) {
        return -1;
      } else {
        return 0;
      }
    }
  });

  _exports.default = _default;
});