define("superfly/pods/auth/forgot-password/components/forgot-password-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SBKti846",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"response\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"alert alert-\",[23,[\"response\",\"type\"]],\" \",[23,[\"response\",\"type\"]],\" mb-3\"]]],[9],[1,[23,[\"response\",\"message\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"input\",null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[23,[\"email\"]],\"Email\"]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"responseIsSuccess\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"button-container\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"        Reset\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[22,0,[]],\"resetPassword\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/forgot-password/components/forgot-password-form/template.hbs"
    }
  });

  _exports.default = _default;
});