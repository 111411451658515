define("superfly/models/site-measurement", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany; // import computed from 'ember-computed';
  // import get from 'ember-metal/get';

  var _default = Model.extend({
    readingId: attr('string'),
    site: belongsTo('site'),
    flight: belongsTo('flight'),
    piles: hasMany('pile-inventory')
  });

  _exports.default = _default;
});