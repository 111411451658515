define("superfly/pods/frame/settings/integrations/components/integration-procore-connector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['settings-integration-connection'],
    procore: Ember.inject.service(),
    callbackUrl: window.location.href,
    actions: {
      connectProcore: function connectProcore() {
        Ember.set(this, 'isLoading', true);
        Ember.get(this, 'procore').connect(Ember.get(this, 'callbackUrl'));
      },
      disconnectProcore: function disconnectProcore() {
        Ember.get(this, 'procore').disconnect(Ember.get(this, 'callbackUrl'));
      }
    }
  });

  _exports.default = _default;
});