define("superfly/serializers/flight", ["exports", "superfly/config/environment", "superfly/serializers/application", "superfly/common/defaults"], function (_exports, _environment, _application, _defaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      var data = Ember.assign({}, hash, {
        links: {
          folder: hash.folderId === 'root' ? "/folders/company/".concat(hash.companyId, "/root") : "/folders/".concat(hash.folderId),
          measurements: "/flights/".concat(hash.id, "/measurements"),
          overlays: "".concat(_environment.default.BOTLINK_TYLER_API_URL, "/overlays/flights/").concat(hash.id),
          stitchedAssets: "/imagery/".concat(hash.id, "/stitched"),
          downloadOptions: "/flights/".concat(hash.id, "/download-options"),
          share: "/flights/".concat(hash.id, "/flightshare")
        }
      }); // Work around to allow using model defaults when result from API is `null`

      if (data.enabledMeasurements === null) {
        data.enabledMeasurements = _defaults.defaultEnabledMeasurements;
      }

      return this._super(typeClass, data);
    }
  });

  _exports.default = _default;
});