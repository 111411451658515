define("superfly/pods/frame/sites/site/components/site-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "75l+HdXi",
    "block": "{\"symbols\":[],\"statements\":[[2,\" Site header \"],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row col-12 h1 font-weight-bold py-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row col-12 pr-0\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[1,[23,[\"site\",\"name\"]],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row col-lg-auto col-2 justify-content-end pr-0\"],[9],[0,\"\\n        \"],[2,\" TODO: Implement\\n          <a class=\\\"icon-button text\\\" {{action 'onShareClick'}}><i class=\\\"material-icons header-icon\\\">share</i></a>\\n        \"],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"ul\"],[11,\"class\",\"row col-12\"],[9],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"col-sm-auto col-6\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"frame.sites.site.index\",[23,[\"site\",\"id\"]]],null,{\"statements\":[[0,\"          Overview\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\\n      \"],[7,\"li\"],[11,\"class\",\"col-sm-auto col-6\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"frame.sites.site.products\",[23,[\"site\",\"id\"]]],null,{\"statements\":[[0,\"          Products\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\\n      \"],[7,\"li\"],[11,\"class\",\"col-sm-auto col-6\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"frame.sites.site.flights\",[23,[\"site\",\"id\"]]],null,{\"statements\":[[0,\"          Flights\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\\n      \"],[2,\"\\n      <li class=\\\"col-sm-auto col-6\\\">\\n{{#link-to 'frame.sites.site.maps' site.id}}\\n          Maps\\n        {{/link-to}}      </li>\\n      \"],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[2,\" End site header \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/site/components/site-header/template.hbs"
    }
  });

  _exports.default = _default;
});