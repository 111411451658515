define("superfly/pods/application/components/object-type-icon-folder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iVHayqjs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"g\"],[11,\"transform\",\"translate(-10629 -17601)\"],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"class\",\"a\"],[11,\"cx\",\"18\"],[11,\"cy\",\"18\"],[11,\"r\",\"18\"],[11,\"transform\",\"translate(22 173)\"],[9],[10],[0,\"\\n  \"],[7,\"g\"],[11,\"transform\",\"translate(30 183)\"],[9],[0,\"\\n    \"],[7,\"path\"],[11,\"class\",\"b\"],[11,\"d\",\"M10,2h8a1.816,1.816,0,0,1,1.4.6A1.816,1.816,0,0,1,20,4V14a2.051,2.051,0,0,1-2,2H2a1.816,1.816,0,0,1-1.4-.6A1.816,1.816,0,0,1,0,14V2A1.816,1.816,0,0,1,.6.6,1.816,1.816,0,0,1,2,0H8Z\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/object-type-icon-folder/template.hbs"
    }
  });

  _exports.default = _default;
});