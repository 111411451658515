define("superfly/pods/frame/plans/select-plan/components/plan-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['col-xs-12'],
    classNameBindings: ['getClassNames'],
    subscriptionManager: Ember.inject.service(),
    plan: null,
    determineCurrentPlan: true,
    getClassNames: Ember.computed('plan.displayName', function () {
      var displayName = Ember.get(this, 'plan.displayName');

      switch (displayName) {
        case 'Starter':
          return 'starter-plan';

        case 'Standard':
          return 'standard-plan';

        case 'Professional':
          return 'professional-plan';
      }
    }),
    currentPlanBaseName: Ember.computed.alias('subscriptionManager.currentPlanBaseName'),
    planDisplayName: Ember.computed.alias('plan.displayName'),
    isCurrentPlan: Ember.computed('currentPlanBaseName', function () {
      var name = Ember.get(this, 'currentPlanBaseName');
      return name ? name.toLowerCase() === Ember.get(this, 'planDisplayName').toLowerCase() : false;
    }),
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'determineCurrentPlan')) {
        var isCurrentPlan = Ember.get(this, 'isCurrentPlan');

        if (isCurrentPlan) {
          Ember.$(Ember.get(this, 'element')).addClass('prominent current-plan');
        }
      }
    }
  });

  _exports.default = _default;
});