define("superfly/utils/layer-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = layerLength;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function layerLength(layer) {
    var _ref;

    var isArea = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (!layer) {
      return null;
    }

    var points = (_ref = []).concat.apply(_ref, _toConsumableArray(layer.getLatLngs()));
    /**
     * If measurement is an area, we need to add the starting point
     * to the end of the points array, not doing this leaves the final
     * let of the perimeter left out of the calculation.
     */


    if (isArea) {
      points.push(points[0]);
    }

    var tempLatLng = null;
    var totalDistance = 0.00000;
    /**
     * Loop through all points in the shape layer (geoJSON) and
     * find the distance between each pair separately and sum the results
     */

    points.forEach(function (latlng) {
      if (tempLatLng === null) {
        tempLatLng = latlng;
        return;
      }

      totalDistance += tempLatLng.distanceTo(latlng);
      tempLatLng = latlng;
    });
    return totalDistance.toFixed(2); // meters
  }
});