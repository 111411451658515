define("superfly/pods/frame/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "16uuENvR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"subscriptionManager\",\"session\",\"refreshWebClient\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-12 newClientBanner\"],[9],[0,\"\\n      \"],[7,\"a\"],[9],[0,\"\\n        Please refresh to update and receive new features!\\n      \"],[3,\"action\",[[22,0,[]],\"reload\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"application/components/site-header-banner\",null,[[\"days\"],[[23,[\"remainingDaysOnTrial\"]]]]],false],[0,\"\\n\\n\"],[1,[21,\"application/components/site-header-nav\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"frame-content\"],[9],[0,\"\\n  \"],[1,[21,\"application/components/site-quickbar\"],false],[0,\"\\n  \"],[1,[27,\"liquid-outlet\",null,[[\"class\"],[\"liquid-frame\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"upload-dropzone\"],[9],[10],[0,\"\\n\"],[1,[21,\"application/components/flight-planner\"],false],[0,\"\\n\\n\"],[7,\"style\"],[9],[0,\"\\n  .newClientBanner {\\n    margin-top: 25px;\\n    text-align: center;\\n    justify-content: center;\\n    font-size: 12px;\\n    letter-spacing: 1.1px;\\n    padding-top: 5px;\\n    padding-bottom: 5px;\\n  }\\n\\n  .newClientBanner {\\n    background-color: #fff;\\n    color: #232323;\\n  }\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});