define("superfly/pods/frame/plans/checkout/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: {
      planId: {
        replace: true
      }
    },
    planId: null
  });

  _exports.default = _default;
});