define("superfly/utils/convert-dms-to-latlon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertDmsToLatlon;

  function convertDmsToLatlon(ref, degrees, minutes, seconds) {
    var total = +degrees + (+minutes || 0) / 60 + (+seconds || 0) / 3600;
    return ['s', 'w'].indexOf(ref.toLowerCase()) === -1 ? total : -total;
  }
});