define("superfly/pods/frame/components/upload-collection-builder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rtXmopH4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"class\",\"option\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-upload\"],[11,\"aria-hidden\",\"true\"],[9],[10],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"toggleViewer\"],null]]],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isChoosingType\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"application/components/upload-type-selector\",null,[[\"folder\",\"typeSelected\",\"close\"],[[23,[\"folder\"]],[27,\"action\",[[22,0,[]],\"typeSelected\"],null],[27,\"action\",[[22,0,[]],\"hideTypeChooser\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"isShowingDropzone\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"upload-dropzone\"]],{\"statements\":[[0,\"    \"],[1,[27,\"frame/dashboard/components/upload-dropzone\",null,[[\"folder\",\"close\",\"filesFound\"],[[23,[\"folder\"]],[27,\"action\",[[22,0,[]],\"hideUploadDropzone\"],null],[27,\"action\",[[22,0,[]],\"filesFound\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/components/upload-collection-builder/template.hbs"
    }
  });

  _exports.default = _default;
});