define("superfly/pods/frame/plans/checkout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vrbQwxbB",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"frame/plans/checkout/components/route-component\",null,[[\"planId\",\"paymentMethod\"],[[23,[\"planId\"]],[23,[\"model\",\"paymentMethod\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/plans/checkout/template.hbs"
    }
  });

  _exports.default = _default;
});