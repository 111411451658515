define("superfly/pods/application/components/action-type-icon-addnew/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o2KGfStg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M15.971,0A1.951,1.951,0,0,1,17.41.589,1.943,1.943,0,0,1,18,2.025V15.975A2.013,2.013,0,0,1,15.971,18H2.029A1.951,1.951,0,0,1,.59,17.411,2.025,2.025,0,0,1,0,15.939V2.025A1.834,1.834,0,0,1,.59.589,1.841,1.841,0,0,1,2.029,0Zm0,15.939V2.025H2.029V15.975H15.971ZM8,3.975H9.959V7.988h4.057V9.939H10v4.049H8.041V9.975H3.984V8.025H8Z\"],[11,\"transform\",\"translate(-13367 -5292)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/action-type-icon-addnew/template.hbs"
    }
  });

  _exports.default = _default;
});