define("superfly/pods/application/components/action-type-icon-addnew/component", ["exports", "superfly/components/base-svg/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    viewBox: '-13367 -5292 18 18'
  });

  _exports.default = _default;
});