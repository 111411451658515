define("superfly/pods/application/components/action-type-icon-delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TnFC/TLN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M10.873.775V2.352H0V.775H2.7L3.5,0H7.373l.8.775ZM.775,3.1H10.1v9.324A1.567,1.567,0,0,1,8.52,14h-6.2A1.516,1.516,0,0,1,1.2,13.541a1.516,1.516,0,0,1-.459-1.119V3.1Zm3.557,5.48L2.7,10.213l1.09,1.09L5.422,9.668,7.057,11.3l1.09-1.09L6.512,8.578,8.148,6.914l-1.09-1.09L5.422,7.459,3.787,5.8,2.668,6.885Z\"],[11,\"transform\",\"translate(-13074.729 -5489.2)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/action-type-icon-delete/template.hbs"
    }
  });

  _exports.default = _default;
});