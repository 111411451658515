define("superfly/pods/frame/dashboard/folders/components/link-to/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    integrations: Ember.inject.service(),
    containerClass: Ember.computed('id', function () {
      return "".concat(Ember.get(this, 'componentCssClassName'), " custom-modal modal-xs link-to-modal");
    }),
    actions: {
      closeMenu: function closeMenu() {
        this.sendAction('close');
      }
    }
  });

  _exports.default = _default;
});