define("superfly/services/procore", ["exports", "superfly/mixins/integration-service", "superfly/config/environment"], function (_exports, _integrationService, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_integrationService.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    userId: Ember.computed.alias('session.currentUserID'),
    accessToken: Ember.computed.alias('session.accessToken'),
    companies: null,
    projects: null,
    albums: null,
    connections: null,
    selectedCompany: null,
    selectedProject: null,
    selectedAlbum: null,
    // Connection Statuses
    isCheckingConnection: false,
    isLoadingConnections: false,
    isLoadingProjects: false,
    isLoadingAlbums: false,
    isDisconnecting: false,
    isLoadingAny: Ember.computed('isLoadingConnections', 'isLoadingProjects', 'isLoadingAlbums', function isLoadingAny() {
      var _this$getProperties = this.getProperties(['isLoadingConnections', 'isLoadingProjects', 'isLoadingAlbums']),
          isLoadingConnections = _this$getProperties.isLoadingConnections,
          isLoadingProjects = _this$getProperties.isLoadingProjects,
          isLoadingAlbums = _this$getProperties.isLoadingAlbums;

      return isLoadingConnections || isLoadingProjects || isLoadingAlbums;
    }),
    hasUserAndToken: Ember.computed.and('userId', 'accessToken'),
    shouldConnect: Ember.computed('hasUserAndToken', 'isConnected', function shouldConnect() {
      var _this$getProperties2 = this.getProperties(['hasUserAndToken', 'isConnected']),
          hasUserAndToken = _this$getProperties2.hasUserAndToken,
          isConnected = _this$getProperties2.isConnected;

      return hasUserAndToken && !isConnected;
    }),
    checkConnection: Ember.on('init', Ember.observer('userId', 'accessToken', function checkConnection() {
      var _this = this;

      var _this$getProperties3 = this.getProperties(['userId', 'accessToken', 'hasUserAndToken', 'ajax']),
          userId = _this$getProperties3.userId,
          accessToken = _this$getProperties3.accessToken,
          hasUserAndToken = _this$getProperties3.hasUserAndToken,
          ajax = _this$getProperties3.ajax;

      if (hasUserAndToken) {
        this.set('isCheckingConnection', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/login/").concat(userId, "?token=").concat(accessToken)).then(function (response) {
          _this.set('isConnected', response.length > 0);
        }).catch(function () {
          _this.set('isConnected', false);
        }).finally(function () {
          _this.set('isCheckingConnection', false);
        });
      }
    })),
    setConnections: Ember.observer('isConnected', function () {
      this.getConnections();
    }),
    setCompanies: Ember.observer('isConnected', function setCompanies() {
      var _this2 = this;

      var _this$getProperties4 = this.getProperties(['userId', 'shouldConnect', 'ajax']),
          userId = _this$getProperties4.userId,
          shouldConnect = _this$getProperties4.shouldConnect,
          ajax = _this$getProperties4.ajax;

      if (!shouldConnect) {
        this.set('isLoadingCompanies', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/companies/").concat(userId)).then(function (companies) {
          _this2.set('companies', companies);
        }).finally(function () {
          _this2.set('isLoadingCompanies', false);
        });
      }
    }),
    setProjects: Ember.observer('selectedCompany', function setProjects() {
      var _this3 = this;

      var _this$getProperties5 = this.getProperties(['userId', 'shouldConnect', 'ajax', 'selectedCompany']),
          userId = _this$getProperties5.userId,
          shouldConnect = _this$getProperties5.shouldConnect,
          ajax = _this$getProperties5.ajax,
          selectedCompany = _this$getProperties5.selectedCompany;

      if (!shouldConnect && selectedCompany) {
        var selectedCompanyId = Ember.get(selectedCompany, 'id');
        this.set('isLoadingProjects', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/projects/").concat(userId, "/").concat(selectedCompanyId)).then(function (projects) {
          _this3.set('projects', projects);

          _this3.set('selectedProject', null);

          _this3.set('selectedAlbum', null);
        }).finally(function () {
          _this3.set('isLoadingProjects', false);
        });
      }
    }),
    setAlbums: Ember.observer('selectedProject', function setAlbums() {
      var _this4 = this;

      var _this$getProperties6 = this.getProperties(['userId', 'shouldConnect', 'selectedProject', 'ajax']),
          userId = _this$getProperties6.userId,
          shouldConnect = _this$getProperties6.shouldConnect,
          selectedProject = _this$getProperties6.selectedProject,
          ajax = _this$getProperties6.ajax;

      if (!shouldConnect && selectedProject) {
        var selectedProjectId = Ember.get(selectedProject, 'id');
        this.set('isLoadingAlbums', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/albums/").concat(userId, "/").concat(selectedProjectId)).then(function (albums) {
          var selectedAlbum = null;

          _this4.setProperties({
            albums: albums,
            selectedAlbum: selectedAlbum
          });
        }).finally(function () {
          _this4.set('isLoadingAlbums', false);
        });
      }
    }),
    resetSelections: function resetSelections() {
      this.setProperties({
        selectedCompany: null,
        selectedProject: null,
        selectedAlbum: null
      });
    },
    connect: function connect(callbackURL) {
      var _this$getProperties7 = this.getProperties(['userId', 'accessToken', 'shouldConnect']),
          userId = _this$getProperties7.userId,
          accessToken = _this$getProperties7.accessToken,
          shouldConnect = _this$getProperties7.shouldConnect;

      if (shouldConnect) {
        var url = "".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/oauth/user/").concat(userId, "?token=").concat(accessToken, "&callback=").concat(callbackURL);
        window.location.replace(url);
      }
    },
    disconnect: function disconnect() {
      var _this5 = this;

      var _this$getProperties8 = this.getProperties(['userId', 'hasUserAndToken', 'ajax']),
          userId = _this$getProperties8.userId,
          hasUserAndToken = _this$getProperties8.hasUserAndToken,
          ajax = _this$getProperties8.ajax;

      if (hasUserAndToken) {
        this.set('isDisconnecting', true);
        ajax.delete("".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/login/").concat(userId), {
          dataType: 'text'
        }).then(function () {
          _this5.set('isConnected', false);
        }).finally(function () {
          _this5.set('isDisconnecting', false);
        });
      }
    },
    getConnections: function getConnections() {
      var _this6 = this;

      var _this$getProperties9 = this.getProperties(['userId', 'shouldConnect', 'ajax']),
          userId = _this$getProperties9.userId,
          shouldConnect = _this$getProperties9.shouldConnect,
          ajax = _this$getProperties9.ajax;

      if (!shouldConnect) {
        this.set('isLoadingConnections', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/connections/").concat(userId)).then(function (connections) {
          _this6.set('connections', connections);
        }).finally(function () {
          _this6.set('isLoadingConnections', false);
        });
      }
    },
    getConnectionData: function getConnectionData(connectionId) {
      var _this$getProperties10 = this.getProperties(['userId', 'hasUserAndToken', 'ajax']),
          userId = _this$getProperties10.userId,
          hasUserAndToken = _this$getProperties10.hasUserAndToken,
          ajax = _this$getProperties10.ajax;

      if (hasUserAndToken && connectionId) {
        return ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/connections/").concat(userId, "/").concat(connectionId));
      }

      return Ember.RSVP.reject('getConnectionData failed.');
    },
    addConnection: function addConnection(companyId, projectId, albumId, folderId) {
      var _this7 = this;

      var _this$getProperties11 = this.getProperties(['userId', 'hasUserAndToken', 'ajax']),
          userId = _this$getProperties11.userId,
          hasUserAndToken = _this$getProperties11.hasUserAndToken,
          ajax = _this$getProperties11.ajax;

      if (hasUserAndToken && projectId && albumId && folderId) {
        var albums = this.get('albums');
        var album = albums.findBy('id', albumId);
        var connection = {
          user_id: parseInt(userId, 10),
          company_id: companyId,
          botlink_folder: folderId,
          project_id: projectId,
          album_id: albumId,
          name: Ember.get(album, 'name')
        };
        var data = JSON.stringify(connection);
        return ajax.post("".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/connections"), {
          data: data,
          dataType: 'text'
        }).then(function () {
          return _this7.getConnections();
        });
      }

      return Ember.RSVP.reject('addConnection failed.');
    },
    deleteConnection: function deleteConnection(connectionId) {
      var _this$getProperties12 = this.getProperties(['userId', 'hasUserAndToken', 'ajax']),
          userId = _this$getProperties12.userId,
          hasUserAndToken = _this$getProperties12.hasUserAndToken,
          ajax = _this$getProperties12.ajax;

      if (hasUserAndToken && connectionId) {
        var connections = this.get('connections');
        var connection = connections.findBy('id', connectionId);
        return ajax.delete("".concat(_environment.default.BOTLINK_API_URL, "/integrations/procore/connections/").concat(userId, "/").concat(connectionId), {
          dataType: 'text'
        }).then(function () {
          return connections.removeObject(connection);
        });
      }

      return Ember.RSVP.reject('deleteConnection failed.');
    }
  });

  _exports.default = _default;
});