define("superfly/pods/frame/settings/billing/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FQFoHCB+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"frame/settings/components/settings-section\",null,[[\"title\"],[\"Botlink Insites Plans\"]],{\"statements\":[[0,\"  \"],[1,[21,\"frame/settings/billing/components/products-section\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"frame/settings/components/settings-section\",null,[[\"title\"],[\"Payment method\"]],{\"statements\":[[0,\"  \"],[1,[21,\"payment-method-info\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[2,\" TODO: Not yet implemented\\n{{#frame/settings/components/settings-section title=\\\"Invoice history\\\"}}\\n  {{frame/settings/billing/components/invoice-history-section}}\\n{{/frame/settings/components/settings-section}}\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/billing/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});