define("superfly/pods/frame/products/product/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gi89jnK6",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"frame/products/product/components/route-component\",null,[[\"product\",\"sites\",\"lastFlights\"],[[23,[\"product\"]],[23,[\"sites\"]],[23,[\"lastFlights\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/products/product/template.hbs"
    }
  });

  _exports.default = _default;
});