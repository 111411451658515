define("superfly/pods/shared/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iMAcKcOJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"application/components/site-header-nav\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"frame-content\"],[9],[0,\"\\n  \"],[1,[21,\"application/components/site-quickbar\"],false],[0,\"\\n  \"],[1,[21,\"liquid-outlet\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/shared/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});