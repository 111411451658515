define("superfly/pods/application/components/set-agx-data-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PSqzTlzQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal-dialog\",null,[[\"containerClass\",\"onComplete\"],[[23,[\"containerClass\"]],[27,\"action\",[[22,0,[]],\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"header\"],[9],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"Select season\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"application/components/flight-agx-season\",null,[[\"seasonSelected\"],[[27,\"action\",[[22,0,[]],\"seasonSelected\"],null]]]],false],[0,\"\\n    \"],[7,\"footer\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"waiting\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isWaiting\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"material-spinner\",null,[[\"size\",\"color\"],[\"xs\",\"white\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"closeModal\"]],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-rounded\"],[9],[0,\"save\"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/set-agx-data-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});