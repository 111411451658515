define("superfly/pods/application/components/flight-upload-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    locationState: Ember.inject.service(),
    ajax: Ember.inject.service(),
    collection: null,
    flight: Ember.computed.alias('collection.flight'),
    flightId: Ember.computed.alias('collection.flight.id'),
    flightDisplayId: Ember.computed('flightId', function flightDisplayId() {
      var id = Ember.get(this, 'flightId');

      if (id) {
        return id.substr(0, id.indexOf('-'));
      }
    }),
    status: Ember.computed('collection.{didSucceed,didNotSucceed,isComplete,isUploading}', function status() {
      var collection = Ember.get(this, 'collection');
      var result;

      if (Ember.get(collection, 'didSucceed')) {
        result = 'Upload successful';
      } else if (Ember.get(collection, 'didCancel')) {
        result = 'Upload paused';
      } else if (Ember.get(collection, 'isUploading')) {
        result = "Uploading to ".concat(Ember.get(collection, 'folder.name') || 'Flights');
      } else if (Ember.get(collection, 'didNotSucceed')) {
        result = Ember.get(collection, 'uploadError');
      } else {
        result = 'Queued for upload';
      }

      return result;
    }),
    progress: Ember.computed('collection.uploadProgress', function progress() {
      var p = Ember.get(this, 'collection.uploadProgress') || 0;
      return Ember.String.htmlSafe("width: ".concat(p * 100, "%"));
    }),
    flightName: Ember.computed('flight', function () {
      var name = Ember.get(this, 'flight.name');
      var collectionName = Ember.get(this, 'collection.name');
      return name || collectionName || 'Pending flight';
    }),
    isDismissible: Ember.computed('collection.{didSucceed,didNotSucceed}', function isDismissible() {
      var didSucceed = Ember.get(this, 'collection.didSucceed');
      var didNotSucceed = Ember.get(this, 'collection.didNotSucceed');
      return didSucceed || didNotSucceed;
    }),
    actions: {
      retry: function retry() {
        Ember.get(this, 'collection').retry();
      },
      cancel: function cancel() {
        Ember.get(this, 'collection').cancel();
      },
      dismiss: function dismiss() {
        var collection = Ember.get(this, 'collection');
        this.sendAction('dismissUploadSet', collection);
      }
    }
  });

  _exports.default = _default;
});