define("superfly/pods/application/components/tool-type-icon-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VbEOEg1l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M0,0H6.015V1.956h7.97V0H20V6.015H18.044v7.97H20V20H13.985V18.044H6.015V20H0V13.985H1.956V6.015H0ZM1.956,1.956v2.03h2.03V1.956Zm0,14.022v2.03h2.03v-2.03Zm12.03-9.963V3.985H6.015v2.03H3.985v7.97h2.03v2.03h7.97v-2.03h2.03V6.015Zm1.993-4.059v2.03h2.03V1.956Zm0,14.022v2.03h2.03v-2.03Z\"],[11,\"transform\",\"translate(-13028.5 -5687.5)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/tool-type-icon-area/template.hbs"
    }
  });

  _exports.default = _default;
});