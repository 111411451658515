define("superfly/integrations/google-adwords", ["exports", "ember-cli-analytics/integrations/google-adwords"], function (_exports, _googleAdwords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _googleAdwords.default;
    }
  });
});