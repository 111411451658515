define("superfly/pods/frame/dashboard/flights/flight/components/overlay-properties-dsm/component", ["exports", "numeral", "superfly/utils/convert", "superfly/utils/convert-units"], function (_exports, _numeral, _convert, _convertUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    overlay: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'colortable', [[215, 25, 28], [220, 44, 37], [225, 63, 45], [230, 83, 54], [235, 102, 63], [240, 121, 72], [245, 140, 81], [250, 160, 90], [254, 177, 100], [254, 187, 112], [254, 198, 124], [254, 208, 136], [255, 219, 149], [255, 229, 161], [255, 240, 173], [255, 250, 185], [250, 253, 189], [239, 249, 186], [228, 244, 183], [217, 240, 179], [207, 236, 176], [196, 231, 172], [185, 227, 169], [174, 222, 165], [159, 213, 166], [142, 201, 169], [126, 189, 172], [109, 178, 175], [92, 166, 178], [76, 154, 181], [59, 143, 183], [43, 131, 186]]);
    },
    hasDsmRangeData: Ember.computed('overlay.flight.dsmRange', function hasDsmRangeData() {
      return Ember.get(this, 'overlay.flight.dsmRange.length') > 0;
    }),
    colors: Ember.computed('colortable', function colors() {
      var colortable = Ember.get(this, 'colortable');
      var colorArray = [];
      var increment = 100 / colortable.length;
      colortable.forEach(function (color, index) {
        colorArray.pushObject({
          startOffset: index * increment,
          stopOffset: (index + 1) * increment,
          r: color[0],
          g: color[1],
          b: color[2]
        });
      });
      return colorArray;
    }),
    minValue: Ember.computed('overlay.overlay.flight.dsmRange', function minValue() {
      var values = Ember.get(this, 'overlay.flight.dsmRange');
      return Math.min.apply(Math, _toConsumableArray(values));
    }),
    maxValue: Ember.computed('overlay.overlay.flight.dsmRange', function minValue() {
      var values = Ember.get(this, 'overlay.flight.dsmRange');
      return Math.max.apply(Math, _toConsumableArray(values));
    }),
    normalizedValues: Ember.computed('overlay.overlay.flight.dsmRange', function normalizedValues() {
      var numberOfTicks = 9;
      var minValue = Ember.get(this, 'minValue');
      var maxValue = Ember.get(this, 'maxValue');
      var range = maxValue - minValue;
      var increment = range / (numberOfTicks - 1);
      var table = [];

      for (var i = 0; i < numberOfTicks; i++) {
        table.push(minValue + i * increment);
      }

      return table;
    }),
    displayValues: Ember.computed('normalizedUnits', 'normalizedValues', function () {
      var _this = this;

      var units = Ember.get(this, 'normalizedUnits');
      var values = Ember.get(this, 'normalizedValues');
      var displayValues = [];
      values.forEach(function (value) {
        var convertedValue;

        if (units === 'preferred units') {
          var convertUtil = _convertUnits.default.create({
            container: Ember.getOwner(_this)
          });

          convertedValue = convertUtil.convertDistanceToCustomUnit(value);
        } else {
          convertedValue = (0, _convert.default)(value).to(units);
        }

        displayValues.pushObject((0, _numeral.default)(convertedValue));
      });
      return displayValues;
    }),
    normalizedUnits: Ember.computed('units', function () {
      var units = Ember.get(this, 'units');

      if (units === 'paces' || units === 'acres') {
        units = 'feet';
      }

      if (units === 'hectares') {
        units = 'meters';
      }

      return units;
    })
  });

  _exports.default = _default;
});