define("superfly/serializers/shared-flight", ["exports", "superfly/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    normalize: function normalize(typeClass, hash) {
      var result = Ember.assign({}, hash, {
        links: {
          measurements: "/flightshares/".concat(hash.flightShareId, "/measurements"),
          overlays: "/flightshares/".concat(hash.flightShareId, "/overlays"),
          stitchedAssets: "/flightshares/".concat(hash.flightShareId, "/stitched-assets")
        }
      });
      return this._super(typeClass, result);
    }
  });

  _exports.default = _default;
});