define("superfly/components/tethered-modal-menu/component", ["exports", "ember-modal-dialog/components/modal-dialog"], function (_exports, _modalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modalDialog.default.extend({
    containerClassNames: 'tethered-modal-menu',
    hasOverlay: false,
    translucentOverlay: false,
    targetAttachment: 'bottom middle',
    attachment: 'top center'
  });

  _exports.default = _default;
});