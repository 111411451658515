define("superfly/models/site", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany; // import computed from 'ember-computed';
  // import get from 'ember-metal/get';

  var _default = Model.extend({
    readingId: attr('string'),
    name: attr('string'),
    siteId: attr('string'),
    city: attr('string'),
    state: attr('string'),
    contactName: attr('string'),
    officeNumber: attr('string'),
    lastFlown: attr('date'),
    totalFlights: attr('number', {
      defaultValue: 0
    }),
    totalProducts: attr('number', {
      defaultValue: 0
    }),
    totalValue: attr('number', {
      defaultValue: 0
    }),
    totalMeasurements: attr('number', {
      defaultValue: 0
    }),
    products: hasMany('site-product')
  });

  _exports.default = _default;
});