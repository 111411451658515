define("superfly/pods/application/components/tool-type-icon-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qDAkQeNl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M11.6,20H10.574L7.623,12.377,0,9.426V8.4L20,0Z\"],[11,\"transform\",\"translate(-13028 -5744)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/tool-type-icon-select/template.hbs"
    }
  });

  _exports.default = _default;
});