define("superfly/models/measurement", ["exports", "ember-data", "L"], function (_exports, _emberData, _L) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    displayLabel: '',
    label: attr('string'),
    type: attr('string'),
    color: attr('string', {
      defaultValue: 'blue'
    }),
    geoJsonString: attr('string'),
    content: attr('string'),
    visibleOnFlight: attr('boolean', {
      defaultValue: true
    }),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    flight: belongsTo('flight', {
      inverse: 'measurements'
    }),

    /**
     * Generates a display-able string of the measurement type
     */
    typeDisplay: Ember.computed('type', function () {
      return (Ember.get(this, 'type') || '').capitalize();
    }),

    /**
     * Parses the geoJsonString property into a geoJson POJO.
     *
     * @returns {object}
     */
    geoJson: Ember.computed('geoJsonString', function geoJson() {
      return JSON.parse(Ember.get(this, 'geoJsonString'));
    }),

    /**
     * Builds leaflet geoJSON layer group from the geoJSON parsed object.
     * The individual layer containing the shape is then returned from that object.
     *
     * @returns {object} Leaflet layer
     */
    layer: Ember.computed('geoJson', 'color', function layer() {
      var color = Ember.get(this, 'color');

      var shapeLayer = _L.default.geoJson(Ember.get(this, 'geoJson'), {
        style: function style(feature) {
          return {
            color: color
          };
        }
      }).getLayers()[0];

      shapeLayer.model = this;
      return shapeLayer;
    })
  });

  _exports.default = _default;
});