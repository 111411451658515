define("superfly/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONSerializer = _emberData.default.JSONSerializer;

  var _default = JSONSerializer.extend({
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.camelize(key);
    },
    keyForRelationship: function keyForRelationship(key) {
      return "".concat(Ember.String.camelize(key), "Id");
    } // serializeAttribute(snapshot, json, key, attributes) {
    //   if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
    //     this._super(snapshot, json, key, attributes);
    //   }
    // }

  });

  _exports.default = _default;
});