define("superfly/pods/frame/dashboard/flights/flight/components/overlay-properties/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    overlay: null,
    units: null,
    typesWithProperties: ['ndvi', 'dsm'],
    dynamicComponentName: Ember.computed('overlay', function dynamicComponentName() {
      var types = Ember.get(this, 'typesWithProperties');
      var overlayType = Ember.get(this, 'overlay.normalizedType');

      if (types.includes(overlayType)) {
        return "frame/dashboard/flights/flight/components/overlay-properties-".concat(overlayType);
      }
    })
  });

  _exports.default = _default;
});