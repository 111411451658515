define("superfly/models/shared-measurement", ["exports", "ember-data", "superfly/models/measurement"], function (_exports, _emberData, _measurement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _measurement.default.extend({
    flight: belongsTo('shared-flight', {
      inverse: 'measurements'
    })
  });

  _exports.default = _default;
});