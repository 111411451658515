define("superfly/pods/application/components/flight-planner-map/component", ["exports", "L", "superfly/config/environment", "lodash"], function (_exports, _L, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mainMap: Ember.inject.service(),
    divId: 'upload-map',
    mapId: 'mapbox.satellite',
    map: null,
    collection: null,
    areaGeoJSON: null,
    markerGroup: null,
    areaLayer: null,
    marker: Ember.computed('componentCssClassName', function () {
      return _L.default.divIcon({
        iconSize: new _L.default.Point(28, 28),
        className: "".concat(this.get('componentCssClassName'), " image-location-marker"),
        html: '<i class="material-icons">image</i>'
      });
    }),
    editMarker: Ember.computed('componentCssClassName', function () {
      return _L.default.divIcon({
        iconSize: new _L.default.Point(20, 20),
        className: "".concat(this.get('componentCssClassName'), " area-edit-marker")
      });
    }),
    bounds: Ember.computed('collection.imageCoordinates', function () {
      var coords = this.get('collection.imageCoordinates');

      var bounds = _L.default.latLngBounds(coords).pad(0.1);

      return bounds;
    }),
    onImageMarkersChanged: Ember.observer('collection.imageCoordinates', function () {
      this.updateLayers();
      this.updateBounds();
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      _L.default.mapbox.accessToken = _environment.default.MAPBOX_API_KEY;

      var map = _L.default.mapbox.map('upload-map');

      this.set('map', map);
      var mainMap = Ember.get(this, "mainMap");

      var tileLayer = _L.default.mapbox.styleLayer("mapbox://styles/".concat(mainMap.mapStyle.style), {
        minZoom: 0,
        maxZoom: 22
      });

      map.addLayer(tileLayer, true);
      this.set('parentReset', function () {
        _this.resetBounds();
      });
    },
    updateLayers: function updateLayers() {
      var _this2 = this;

      var markerGroup = this.get('markerGroup');
      var map = this.get('map');
      var coords = this.get('collection.imageCoordinates');
      var files = this.get('collection.files');

      if (markerGroup) {
        map.removeLayer(markerGroup);
      }

      if (coords) {
        markerGroup = _L.default.featureGroup();
        this.set('markerGroup', markerGroup);
        coords.forEach(function (coord, index) {
          var file = files[index];
          coord.fileName = file.name;

          var marker = _L.default.marker(coord, {
            icon: _this2.get('marker')
          });

          file.marker = marker;
          marker.on('click', function (e) {
            var imageTag = _this2.get('markerImage');

            if (!imageTag) {
              imageTag = new Image();

              _this2.set('markerImage', imageTag);
            }

            var popup = marker.bindPopup(imageTag);
            imageTag.src = URL.createObjectURL(file);
            imageTag.setAttribute('style', 'max-width: 16rem;');

            imageTag.onload = function (e) {
              popup.openPopup();
              URL.revokeObjectURL(e.target.src);
            };

            files.forEach(function (file) {
              if (file.name === e.latlng.fileName) {
                Ember.set(file, 'selected', 'active');

                if (file.marker) {
                  $(file.marker._icon).addClass('active');
                }

                Ember.run.next(_this2, function () {
                  var element = $(".file-item.active")[0];
                  element.scrollIntoView();
                });
              } else {
                Ember.set(file, 'selected', '');

                if (file.marker) {
                  $(file.marker._icon).removeClass('active');
                }
              }
            });
          });
          marker.addTo(markerGroup);
          file.marker = marker;
        });
      }

      markerGroup.addTo(map);
    },
    resetBounds: function resetBounds() {
      var map = this.get('map');
      var areaLayer = this.get('areaLayer');

      if (areaLayer) {
        map.removeLayer(areaLayer);
        this.set('areaLayer', null);
      }

      this.updateBounds();
    },
    updateBounds: function updateBounds() {
      var map = this.get('map');
      var bounds = (0, _lodash.cloneDeep)(this.get('bounds'));
      var areaLayer = this.get('areaLayer');

      if (bounds) {
        if (!areaLayer) {
          map.on('draw:editvertex', this.updateArea.bind(this));

          var area = _L.default.polygon([bounds.getNorthWest(), bounds.getSouthWest(), bounds.getSouthEast(), bounds.getNorthEast()], {
            color: '#F59A2D'
          });

          area.addTo(map);
          area.editing.enable();
          this.set('areaLayer', area);
        }

        this.updateArea();
      }
    },
    updateArea: function updateArea() {
      this.sendAction('areaChanged', this.get('areaLayer'));
    },
    setAdditionalMapOptions: Ember.observer('map.options', 'bounds', function () {
      var map = this.get('map');
      var bounds = this.get('bounds');
      map.options.animate = true;
      map.options.zoomAnimationThreshold = 10;
      map.fitBounds(bounds);
      map.setView(bounds.getCenter());
      this.updateLayers();
      this.updateBounds();
    })
  });

  _exports.default = _default;
});