define("superfly/pods/frame/plans/select-plan/components/plan-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wibGSczX",
    "block": "{\"symbols\":[\"feature\"],\"statements\":[[7,\"h3\"],[11,\"class\",\"title\"],[9],[1,[23,[\"plan\",\"displayName\"]],false],[10],[0,\"\\n\"],[7,\"h2\"],[11,\"class\",\"price\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dollar-sign\"],[9],[0,\"$\"],[10],[0,\"\\n  \"],[1,[23,[\"plan\",\"totalMonthlyAmountFormatted\"]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"interval\"],[9],[0,\"mo\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"plan-details enable-select\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"plan\",\"escapedFeatures\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isCurrentPlan\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"disabled\",\"true\"],[11,\"class\",\"select-plan\"],[9],[0,\"Current Plan\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[\"frame.botlink-product.plans.checkout\",[27,\"query-params\",null,[[\"planId\"],[[23,[\"plan\",\"stripePlanId\"]]]]]],[[\"class\",\"tagName\"],[\"select-plan\",\"button\"]],{\"statements\":[[0,\"    Get started $\"],[1,[23,[\"plan\",\"totalMonthlyAmountFormatted\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/plans/select-plan/components/plan-card/template.hbs"
    }
  });

  _exports.default = _default;
});