define("superfly/pods/application/components/flyout-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fhE9c6xc",
    "block": "{\"symbols\":[\"flight\",\"folder\"],\"statements\":[[7,\"form\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"class\"],[[23,[\"searchExpression\"]],\"Search...\",\"form-control search-input\"]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"isAwaitingResults\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin loading\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[3,\"action\",[[22,0,[]],\"doSearch\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"results pt-3 pb-3\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"hasResults\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"folderResults\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"folderResults\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"frame/dashboard/folders/components/folder-card\",null,[[\"folder\",\"afterTransition\",\"hideActions\"],[[22,2,[]],[27,\"action\",[[22,0,[]],\"afterTransition\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[4,\"if\",[[23,[\"flightResults\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"flightResults\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"frame/dashboard/folders/components/flight-card\",null,[[\"flight\",\"afterTransition\",\"hideActions\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"afterTransition\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"showNoResultsWarning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"alert alert-warning\"],[9],[0,\"No results found.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/flyout-search/template.hbs"
    }
  });

  _exports.default = _default;
});