define("superfly/pods/application/components/flyout-search/component", ["exports", "lodash/forOwn"], function (_exports, _forOwn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routing: Ember.inject.service('router'),
    subscriptionManager: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    searchExpression: null,
    hasSearchExpression: Ember.computed('searchExpression', function hasSearchExpression() {
      var searchExpression = (Ember.get(this, 'searchExpression') || '').trim();
      return searchExpression && searchExpression.length > 0;
    }),
    results: null,
    isAwaitingResults: false,
    hasSearchResolved: false,
    folderResults: Ember.computed.alias('results.folders'),
    flightResults: Ember.computed.alias('results.flights'),
    hasResults: Ember.computed('folderResults', 'flightResults', function hasResults() {
      var _Ember$getProperties = Ember.getProperties(this, ['folderResults', 'flightResults']),
          folderResults = _Ember$getProperties.folderResults,
          flightResults = _Ember$getProperties.flightResults;

      return folderResults && folderResults.length > 0 || flightResults && flightResults.length > 0;
    }),
    hasNoResults: Ember.computed.not('hasResults'),
    showNoResultsWarning: Ember.computed('hasSearchExpression', 'hasNoResults', 'isAwaitingResults', function showNoResultsWarning() {
      var _Ember$getProperties2 = Ember.getProperties(this, ['hasSearchResolved', 'hasSearchExpression', 'hasNoResults', 'isAwaitingResults']),
          hasSearchResolved = _Ember$getProperties2.hasSearchResolved,
          hasSearchExpression = _Ember$getProperties2.hasSearchExpression,
          hasNoResults = _Ember$getProperties2.hasNoResults,
          isAwaitingResults = _Ember$getProperties2.isAwaitingResults;

      return hasSearchResolved && hasSearchExpression && hasNoResults && !isAwaitingResults;
    }),
    onSearchExpressionChange: Ember.observer('searchExpression', function onSearchExpressionChange() {
      this.send('doSearch');
    }),
    didInsertElement: function didInsertElement() {
      this.$('input').focus();
    },
    willDestroyElement: function willDestroyElement() {
      this.clearResults();
      this.clearSearchExpression();
    },
    clearResults: function clearResults() {
      Ember.set(this, 'renderError', false);
      Ember.set(this, 'results', null);
      Ember.set(this, 'hasSearchResolved', false);
    },
    clearSearchExpression: function clearSearchExpression() {
      Ember.set(this, 'searchExpression', null);
    },
    search: function search() {
      var _this = this;

      var ajax = Ember.get(this, 'ajax');
      var searchExpression = Ember.get(this, 'searchExpression');
      new Ember.RSVP.Promise(function (resolve, reject) {
        _this.clearResults();

        if (Ember.get(_this, 'hasSearchExpression')) {
          return resolve();
        }

        return reject();
      }).then(function () {
        Ember.set(_this, 'isAwaitingResults', true);
        return Ember.RSVP.hashSettled({
          flights: ajax.request("/flights/search?fuzzy=".concat(searchExpression)),
          folders: ajax.request("/folders/search?fuzzy=".concat(searchExpression))
        }).then(function (hash) {
          var results = [];
          (0, _forOwn.default)(hash, function (value, key) {
            results[key] = [];

            if (value && value.state === 'fulfilled') {
              var store = Ember.get(_this, 'store');
              var payload = Ember.get(value, 'value') || [];

              if (payload && payload.length > 0) {
                var items = [];
                payload.forEach(function (item) {
                  var obj = store.push(store.normalize(key.substring(0, key.length - 1), item));
                  items.pushObject(obj);
                });
                results[key] = items;
              }
            }
          });
          Ember.set(_this, 'results', results);
        });
      }).catch(function () {
        Ember.set(_this, 'renderError', true);
      }).finally(function () {
        Ember.set(_this, 'isAwaitingResults', false);
        Ember.set(_this, 'hasSearchResolved', true);
      });
    },
    actions: {
      doSearch: function doSearch() {
        if (Ember.get(this, 'hasSearchExpression')) {
          Ember.run.debounce(this, 'search', 500);
        }
      },
      afterTransition: function afterTransition() {
        Ember.get(this, 'eventBus').publish('site-left-flyout', null, 'close');
      }
    }
  });

  _exports.default = _default;
});