define("superfly/pods/auth/confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8uefmagx",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"auth/confirm/components/confirm-account\",null,[[\"token\"],[[23,[\"token\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/confirm/template.hbs"
    }
  });

  _exports.default = _default;
});