define("superfly/services/location-state", ["exports", "superfly/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    routing: Ember.inject.service('-routing'),
    displayFlightsPanel: false,
    displayFlightPropertiesPanel: false,
    isTransitioning: false,
    initialPositionSet: false,
    locationRouteMap: {
      'dashboard.flights': 'flights',
      'dashboard.flights.index': 'flights',
      'dashboard.flights.show': 'flightProperties'
    },
    onRouteUpdate: Ember.on('init', Ember.observer('routing.router.currentPath', function () {
      var route = Ember.get(this, 'routing.router.currentPath');
      var map = Ember.get(this, 'locationRouteMap');

      if (map[route]) {
        this._setDisplayProperties(map[route]);
      }
    })),

    /**
     * A computed property that tells whether it is appropriate to show the
     * header links for the flights panel.  Waits for the panel animation
     * to finish.
     *
     * Returns Boolean
     */
    showFlightsHeaders: Ember.computed('displayFlightsPanel', 'isTransitioning', function () {
      return Ember.get(this, 'displayFlightsPanel') && !Ember.get(this, 'isTransitioning');
    }),

    /**
     * A computed property that tells whether it is appropriate to show the
     * header links for the flightsProperties panel.  Waits for the panel
     * animation to finish.
     *
     * Returns Boolean
     */
    showFlightPropertiesHeaders: Ember.computed('displayFlightPropertiesPanel', 'isTransitioning', function () {
      return Ember.get(this, 'displayFlightPropertiesPanel') && !Ember.get(this, 'isTransitioning');
    }),

    /**
     * Watches for changes to the panel display properties and starts the
     * appropriate panel animation.
     */
    onPanelChange: Ember.on('init', Ember.observer('displayFlightsPanel', 'displayFlightPropertiesPanel', function () {
      var displayFlightsPanel = Ember.get(this, 'displayFlightsPanel');
      var displayFlightPropertiesPanel = Ember.get(this, 'displayFlightPropertiesPanel');
      var isTransitioning = Ember.get(this, 'isTransitioning');

      if (displayFlightsPanel && !isTransitioning) {
        this._setPanelPositionToFlights();
      } else if (displayFlightPropertiesPanel && !isTransitioning) {
        this._setPanelPositionToFlightProperties();
      }
    })),

    /**
     * Sets the appropriate panel display properties based on the passed-in
     * string.  This argument may be 'sites', 'flights', or 'flightProperties'.
     *
     * This function keeps the panel display properties locked together so that
     * if one is `true`, the others are necessarily `false`.
     */
    _setDisplayProperties: function _setDisplayProperties() {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'sites';

      switch (state) {
        case 'flights':
          Ember.setProperties(this, {
            'displaySitesPanel': false,
            'displayFlightsPanel': true,
            'displayFlightPropertiesPanel': false
          });
          break;

        case 'flightProperties':
          Ember.setProperties(this, {
            'displaySitesPanel': false,
            'displayFlightsPanel': false,
            'displayFlightPropertiesPanel': true
          });
          break;
      }
    },

    /**
     * Calculates the panel positions for the flights panel to be displayed.
     * It then hands those positions off to `_setPanelPosition` to actually
     * carry out the panel animation.
     */
    _setPanelPositionToFlights: function _setPanelPositionToFlights() {
      var leftPanelWidth = _environment.default.Constants.leftPanelContainerWidth + 15;
      var positions = {
        left: "0",
        right: "".concat(leftPanelWidth, "px")
      };

      this._setPanelPosition(positions);
    },

    /**
     * Calculates the panel positions for the flightsProperties panel to be
     * displayed. It then hands those positions off to `_setPanelPosition` to
     * actually carry out the panel animation.
     */
    _setPanelPositionToFlightProperties: function _setPanelPositionToFlightProperties() {
      this._setPanelPositionToFlights();

      var leftPanelWidth = _environment.default.Constants.leftPanelContainerWidth + 15;
      var positions = {
        left: "-".concat(leftPanelWidth, "px"),
        right: '0'
      };

      this._setPanelPosition(positions);
    },

    /**
     * Handles panel animation based on the passed-in `positions` object.
     *
     * This object MUST contain keys for `left`, `center`, and `right` whose
     * values are positions (strings) for the panels in question.
     */
    _setPanelPosition: function _setPanelPosition(positions) {
      Ember.set(this, 'panelPositions', positions);
    }
  });

  _exports.default = _default;
});