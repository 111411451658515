define("superfly/models/pile-inventory", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    pileNumber: attr('string'),
    siteMeasurement: belongsTo('site-measurement'),
    product: belongsTo('product') // vol
    // density

  });

  _exports.default = _default;
});