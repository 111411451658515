define("superfly/pods/frame/dashboard/components/auto-export-procore/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNameBindings: ['hasNoConnections:empty'],
    routing: Ember.inject.service('-routing'),
    procore: Ember.inject.service(),
    folder: null,
    step: 1,
    addExportModalVisible: false,
    selectedCompany: Ember.computed.alias('procore.selectedCompany'),
    selectedProject: Ember.computed.alias('procore.selectedProject'),
    selectedAlbum: Ember.computed.alias('procore.selectedAlbum'),
    hasNoConnections: Ember.computed.empty('filteredConnections'),
    filteredConnections: Ember.computed.filter('procore.connections', function filteredConnections(item) {
      var folderId = Ember.get(this, 'folder.id');
      return item.botlink_folder === folderId;
    }),
    modalContainerClass: Ember.computed('id', function containerClass() {
      return "".concat(this.get('componentCssClassName'), " custom-modal auto-export-modal");
    }),
    finalizeAddExport: Ember.computed('step', 'selectedAlbum', function finalizeAddExport() {
      return this.get('step') === 3 && this.get('selectedAlbum');
    }),
    hasOptionChosen: Ember.computed('step', 'selectedCompany', 'selectedProject', 'selectedAlbum', function hasOptionChosen() {
      var _this$getProperties = this.getProperties(['step', 'selectedCompany', 'selectedProject', 'selectedAlbum']),
          step = _this$getProperties.step,
          selectedCompany = _this$getProperties.selectedCompany,
          selectedProject = _this$getProperties.selectedProject,
          selectedAlbum = _this$getProperties.selectedAlbum;

      switch (+step) {
        case 1:
          return selectedCompany !== null;

        case 2:
          return selectedProject !== null;

        case 3:
          return selectedAlbum !== null;

        default:
          return false;
      }
    }),
    actions: {
      resetAddExport: function resetAddExport() {
        this.setProperties({
          step: 1,
          selectedCompany: null,
          selectedProject: null,
          selectedAlbum: null
        });
      },
      exportSettings: function exportSettings() {
        this.get('routing').transitionTo('dashboard.folders.settings');
      },
      openAddExportModal: function openAddExportModal() {
        this.setProperties({
          step: 1,
          addExportModalVisible: true
        });
      },
      stepForward: function stepForward() {
        if (this.get('hasOptionChosen')) {
          var step = this.get('step');

          if (step < 3) {
            this.incrementProperty('step');
          }
        }
      },
      stepBackward: function stepBackward() {
        var step = this.get('step');

        if (step > 1) {
          this.decrementProperty('step');
        }
      },
      closeAddExportModal: function closeAddExportModal() {
        Ember.set(this, 'addExportModalVisible', false);
      },
      selectCompany: function selectCompany(company) {
        this.set('selectedCompany', company);
      },
      selectProject: function selectProject(project) {
        this.set('selectedProject', project);
      },
      selectAlbum: function selectAlbum(album) {
        this.set('selectedAlbum', album);
      },
      completeAddExport: function completeAddExport() {
        var _this = this;

        var _Ember$getProperties = Ember.getProperties(this, ['selectedCompany', 'selectedProject', 'selectedAlbum', 'folder', 'procore']),
            selectedCompany = _Ember$getProperties.selectedCompany,
            selectedProject = _Ember$getProperties.selectedProject,
            selectedAlbum = _Ember$getProperties.selectedAlbum,
            folder = _Ember$getProperties.folder,
            procore = _Ember$getProperties.procore;

        procore.addConnection(Ember.get(selectedCompany, 'id'), Ember.get(selectedProject, 'id'), Ember.get(selectedAlbum, 'id'), Ember.get(folder, 'id')).then(function () {
          return _this.send('closeAddExportModal');
        }).catch(function (e) {
          return Ember.warn(e);
        });
      },
      deleteExportCollection: function deleteExportCollection(connection) {
        var _this2 = this;

        var procore = this.get('procore');
        procore.deleteConnection(Ember.get(connection, 'id')).then(function () {
          return _this2.send('closeAddExportModal');
        }).catch(function (e) {
          return Ember.warn(e);
        });
      }
    }
  });

  _exports.default = _default;
});