define("superfly/pods/auth/confirm/components/confirm-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OmX7u3ne",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"title mt-4 mb-2 mt-lg-5 mb-lg-3\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Confirming your account\"],[10],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"showErrorMessage\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"application/components/loading-svg\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showErrorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"alert alert-danger\"],[9],[0,\"There was an error confirming your account\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"button-container\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Retry\"],[3,\"action\",[[22,0,[]],\"tryAgain\"]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/confirm/components/confirm-account/template.hbs"
    }
  });

  _exports.default = _default;
});