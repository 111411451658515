define("superfly/pods/frame/dashboard/components/measurement-toolbar-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['select', 'tool'],
    measurements: Ember.inject.service(),
    isActive: Ember.computed.alias('measurements.isSelectingActive'),
    startSelect: function startSelect() {
      Ember.get(this, 'measurements').startSelect();
    },
    stopSelect: function stopSelect() {
      Ember.get(this, 'measurements').stopSelect();
    },
    actions: {
      toggleSelect: function toggleSelect() {
        if (Ember.get(this, 'isActive')) {
          return this.stopSelect();
        }

        return this.startSelect();
      }
    }
  });

  _exports.default = _default;
});