define("superfly/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "superfly/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    uploadManager: Ember.inject.service(),
    routeAfterAuthentication: 'frame.dashboard',
    init: function init() {
      this._super.apply(this, arguments);

      Ember.get(this, 'uploadManager');
    },
    title: function title(tokens) {
      var tokenArray = Ember.makeArray(tokens);
      var common = _environment.default.appName;

      if (tokenArray.length > 0) {
        return "".concat(tokenArray.reverse().join(' - '), " | ").concat(common);
      }

      return common;
    }
  });

  _exports.default = _default;
});