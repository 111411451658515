define("superfly/models/shared-flight", ["exports", "ember-data", "superfly/models/flight"], function (_exports, _emberData, _flight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = _flight.default.extend({
    enabledMeasurements: ['area', 'distance', 'comment'],
    flightShareId: attr('string'),
    overlays: hasMany('shared-overlay'),
    stitchedAssets: hasMany('shared-stitched-assets'),
    measurements: hasMany('shared-measurement'),
    folder: null,
    downloadOptions: null,
    flightShare: belongsTo('share', {
      async: false,
      inverse: 'sharedFlight'
    })
  });

  _exports.default = _default;
});