define("superfly/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    readingId: attr('string'),
    name: attr('string'),
    sku: attr('string'),
    globalDensity: attr('number', {
      defaultValue: 0
    }),
    globalCost: attr('number', {
      defaultValue: 0
    }),
    // Per ton
    color: attr('string')
  });

  _exports.default = _default;
});