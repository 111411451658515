define("superfly/services/intercom", ["exports", "ember-intercom-io/services/intercom", "superfly/config/environment"], function (_exports, _intercom, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      getWithDefault = _Ember.getWithDefault;

  var _default = _intercom.default.extend({
    config: getWithDefault(_environment.default, 'intercom', {})
  });

  _exports.default = _default;
});