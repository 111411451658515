define("superfly/pods/application/components/site-header-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['site-header-nav', 'navbar', 'navbar-expand-lg', 'navbar-light', 'd-flex', 'flex-row'],
    session: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    onEventBusSidebarChange: Ember.on('sidebar.isVisible', function onEventBusSidebarChange() {
      this.send('toggleSidebar');
    }),
    showSidebar: function showSidebar() {
      Ember.get(this, 'sidebar').trigger('show');
    },
    hideSidebar: function hideSidebar() {
      Ember.get(this, 'sidebar').trigger('hide');
    },
    actions: {
      toggleSidebar: function toggleSidebar() {
        var isShowingSidebar = Ember.get(this, 'sidebar.isVisible');

        if (isShowingSidebar) {
          this.hideSidebar();
        } else {
          this.showSidebar();
        }
      }
    }
  });

  _exports.default = _default;
});