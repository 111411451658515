define("superfly/services/main-map", ["exports", "L", "lodash", "superfly/config/environment"], function (_exports, _L, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    map: null,
    currentPosition: undefined,
    currentOverlay: null,
    currentOverlayId: Ember.computed.alias('currentOverlay.id'),
    flightSketchfabViewable: false,
    mapStyleDefault: 'mapbox/satellite-v9',
    mapStyleOptions: [{
      name: 'Satellite',
      style: 'mapbox/satellite-v9'
    }, {
      name: 'Streets',
      style: 'mapbox/streets-v11'
    }, {
      name: 'Hybrid',
      style: 'mapbox/satellite-streets-v11'
    }, {
      name: 'Outdoors',
      style: 'mapbox/outdoors-v11'
    }, {
      name: 'Light',
      style: 'mapbox/light-v10'
    }, {
      name: 'Dark',
      style: 'mapbox/dark-v10'
    }],
    mapStyleSelected: null,
    mapStyleStored: Ember.computed('id', function () {
      var de = Ember.get(this, 'mapStyleDefault');
      return window.localStorage.getItem('map-style') || de;
    }),
    mapStyle: Ember.computed('id', 'mapStyleSelected', function () {
      var current = Ember.get(this, 'mapStyleSelected');
      var options = Ember.get(this, 'mapStyleOptions');
      return options.findBy('style', current);
    }),
    sketchfabActive: Ember.computed.bool('flightSketchfabViewable'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'mapStyleSelected', Ember.get(this, 'mapStyleStored'));
    },
    addCurrentPositionMarker: function addCurrentPositionMarker(point) {
      this._addCurrentPositionMarker(point);
    },
    restoreCurrentPositionMarker: function restoreCurrentPositionMarker() {
      this._restoreCurrentPositionMarker();
    },
    // Fit map to bounds.
    fitBounds: function fitBounds(bounds, opts) {
      this._fitBounds(bounds, opts);
    },
    // Add marker to map for a site with click listener.
    addSiteMarker: function addSiteMarker(site, clickFunction) {
      this._addSiteMarker(site, clickFunction);
    },
    addFlightMarker: function addFlightMarker(flight, clickFunction) {
      this._addFlightMarker(flight, clickFunction);
    },
    // Add a tile overlay layer to map.
    addTileOverlay: function addTileOverlay(overlay) {
      this._addTileOverlay(overlay);

      this.set('currentOverlay', overlay);
    },
    // Replace previous tiles with new overlay
    changeTileOverlay: function changeTileOverlay(overlay) {
      this._removeAllTileOverlays();

      this._addNewTileOverlay(overlay);

      this.set('currentOverlay', overlay);
    },
    // Pan map `diff` pixels left or right.
    panX: function panX(diff) {
      this._panX(diff);
    },
    // Pan map `diff` pixel up or down.
    panY: function panY(diff) {
      this._panY(diff);
    },
    // Center map over point at current zoom level.
    setView: function setView(point) {
      this._setView(point);
    },
    removeAllSiteMarkers: function removeAllSiteMarkers() {
      this._removeAllSiteMarkers();
    },
    removeAllFlightMarkers: function removeAllFlightMarkers() {
      this._removeAllFlightMarkers();
    },
    removeAllTileOverlays: function removeAllTileOverlays() {
      this._removeAllTileOverlays();
    },
    zoomIn: function zoomIn() {
      this._zoomIn();
    },
    zoomOut: function zoomOut() {
      this._zoomOut();
    },
    getZoom: function getZoom() {
      var map = Ember.get(this, 'map');
      return map.getZoom();
    },
    updateMapOptions: function updateMapOptions(opts) {
      this._updateOrStoreMapOptions(opts);
    },
    ////////////////////////////// REMOVE OVERLAYS ///////////////////////////////
    _removeAllTileOverlays: function _removeAllTileOverlays() {
      var map = Ember.get(this, 'map');
      var activeTileOverlays = Ember.get(this, '_activeTileOverlays');

      if (map) {
        map.whenReady(function () {
          activeTileOverlays.forEach(function (tileOverlay) {
            map.removeLayer(tileOverlay);
          });
        });
      }
    },
    ////////////////////////////// INSERT OVERLAY ////////////////////////////////
    _storedTileOverlays: [],
    _activeTileOverlays: [],
    _purgeStoredTileOverlays: Ember.observer('_storedTileOverlays', function () {
      Ember.run.later(this, function () {
        if (Ember.get(this, 'map') === null) {
          Ember.set(this, '_storedTileOverlays', []);
        }
      }, 500);
    }),
    _addStoredTileOverlays: Ember.observer('map', function () {
      var _this = this;

      var map = Ember.get(this, 'map');
      var storedTileOverlays = Ember.get(this, '_storedTileOverlays');

      if (map && storedTileOverlays.length > 0) {
        storedTileOverlays.forEach(function (tileOverlay) {
          _this._addTileOverlayToMap(tileOverlay, map);

          storedTileOverlays.reject(function (storedTileOverlay) {
            return storedTileOverlay !== tileOverlay;
          });
        });
      }
    }),
    _addTileOverlayToMap: function _addTileOverlayToMap(tileOverlay, map) {
      var activeTileOverlays = Ember.get(this, '_activeTileOverlays');
      tileOverlay.addTo(map);
      activeTileOverlays.pushObject(tileOverlay);
    },
    _storeTileOverlay: function _storeTileOverlay(tileOverlay) {
      Ember.get(this, '_storedTileOverlays').pushObject(tileOverlay);
    },
    _addOrStoreTileOverlay: function _addOrStoreTileOverlay(map, tileOverlay) {
      var _this2 = this;

      if (map) {
        map.whenReady(function () {
          _this2._addTileOverlayToMap(tileOverlay, map);
        });
      } else {
        this._storeTileOverlay(tileOverlay);
      }
    },
    _addTileOverlay: function _addTileOverlay(overlay) {
      var map = Ember.get(this, 'map');

      var _Ember$getProperties = Ember.getProperties(overlay, ['id', 'bounds']),
          id = _Ember$getProperties.id,
          bounds = _Ember$getProperties.bounds;

      var routeURL = Ember.get(this, 'router.currentURL') || '';
      var token;
      var urlTarget;

      if (routeURL.indexOf('shared') !== -1) {
        token = Ember.get(this, 'session.shareToken');
        urlTarget = "".concat(_environment.default.BOTLINK_API_URL, "/flightshares/overlays/").concat(id, "/tiles/{z}/{x}/{y}.png");
      } else {
        token = Ember.get(this, 'session.accessToken');
        urlTarget = "".concat(_environment.default.BOTLINK_API_URL, "/overlays/").concat(id, "/tiles/{z}/{x}/{y}.png");
      }

      var overlayUrlTemplate = "".concat(urlTarget, "?token=").concat(token);

      var tileOverlay = _L.default.tileLayer(overlayUrlTemplate, {
        bounds: bounds,
        tms: false,
        zIndex: 999,
        minZoom: 15,
        maxZoom: 22
      });

      this._addOrStoreTileOverlay(map, tileOverlay);

      this._fitBounds(bounds);
    },
    _addNewTileOverlay: function _addNewTileOverlay(overlay) {
      var map = Ember.get(this, 'map');

      var _Ember$getProperties2 = Ember.getProperties(overlay, ['id', 'bounds']),
          id = _Ember$getProperties2.id,
          bounds = _Ember$getProperties2.bounds;

      var routeURL = Ember.get(this, 'router.currentURL') || '';
      var token;
      var urlTarget;

      if (routeURL.indexOf('shared') !== -1) {
        token = Ember.get(this, 'session.shareToken');
        urlTarget = "".concat(_environment.default.BOTLINK_API_URL, "/flightshares/overlays/").concat(id, "/tiles/{z}/{x}/{y}.png");
      } else {
        token = Ember.get(this, 'session.accessToken');
        urlTarget = "".concat(_environment.default.BOTLINK_API_URL, "/overlays/").concat(id, "/tiles/{z}/{x}/{y}.png");
      }

      var overlayUrlTemplate = "".concat(urlTarget, "?token=").concat(token);

      var tileOverlay = _L.default.tileLayer(overlayUrlTemplate, {
        bounds: bounds,
        tms: false,
        zIndex: 999,
        minZoom: 15,
        maxZoom: 22
      });

      this._addOrStoreTileOverlay(map, tileOverlay);
    },
    ////////////////////////////// REMOVE MARKERS ////////////////////////////////
    // If set to `true` and the map has not yet loaded, this will prevent the service
    // from adding site markers when it does. Used when the user loads the app on a
    // particular site.
    _preventSiteMarkers: false,
    _emptyMarkerPane: function _emptyMarkerPane() {
      var map = Ember.get(this, 'map');
      var markerPane = map.getPanes().markerPane;
      var shadowPane = map.getPanes().shadowPane;
      Ember.$(markerPane).empty();
      Ember.$(shadowPane).empty();
    },
    _removeAllSiteMarkers: function _removeAllSiteMarkers() {
      var _this3 = this;

      var map = Ember.get(this, 'map');

      if (map) {
        map.whenReady(function () {
          _this3._emptyMarkerPane();
        });
      } else {
        Ember.set(this, '_preventSiteMarkers', true);
      }
    },
    _removeAllFlightMarkers: function _removeAllFlightMarkers() {
      var _this4 = this;

      var map = Ember.get(this, 'map');

      if (map) {
        map.whenReady(function () {
          _this4._emptyMarkerPane();
        });
      } else {
        Ember.set(this, '_preventSiteMarkers', true);
      }
    },
    ///////////////////////////////// ADD MARKERS ////////////////////////////////////
    // Stored markers are used when some route or component tries to add a marker
    // to the map, but the map is not yet loaded.  If this is the case, the marker
    // will be stored in the array below and added once the map loads.
    _storedMarkers: [],
    // Removes stored markers not added to the map after 500 milliseconds.
    _purgeStoredMarkers: Ember.observer('_storedMarkers', function () {
      Ember.run.later(this, function () {
        if (Ember.get(this, 'map') === null) {
          Ember.set(this, '_storedMarkers', []);
        }
      }, 500);
    }),
    _addStoredMarkers: Ember.observer('map', function () {
      var map = Ember.get(this, 'map');
      var storedMarkers = Ember.get(this, '_storedMarkers');
      var addSiteMarkers = !Ember.get(this, '_preventSiteMarkers');

      if (map && storedMarkers.length > 0 && addSiteMarkers) {
        storedMarkers.forEach(function (marker) {
          marker.addTo(map);
          storedMarkers.reject(function (storedMarker) {
            return storedMarker !== marker;
          });
        });
      }
    }),
    _storeMarker: function _storeMarker(marker) {
      Ember.get(this, '_storedMarkers').pushObject(marker);
    },
    _addOrStoreMarker: function _addOrStoreMarker(map, marker) {
      if (map) {
        map.whenReady(function () {
          marker.addTo(map);
        });
      } else {
        this._storeMarker(marker);
      }
    },
    _addSiteMarker: function _addSiteMarker(site, clickFunction) {
      var point = Ember.get(site, 'bounds').getCenter();
      var siteName = Ember.get(site, 'name');

      var marker = _L.default.marker(point, {
        icon: _L.default.icon({
          iconUrl: '/assets/images/marker-icon.png'
        }),
        title: siteName,
        alt: siteName,
        riseOnHover: true,
        riseOffset: 9999
      }).on('click', function () {
        return clickFunction();
      });

      this._addOrStoreMarker(Ember.get(this, 'map'), marker);
    },
    _addFlightMarker: function _addFlightMarker(flight, clickFunction) {
      var point = Ember.get(flight, 'bounds').getCenter();
      var flightName = Ember.get(flight, 'name');

      var marker = _L.default.marker(point, {
        title: flightName,
        alt: flightName,
        riseOnHover: true,
        riseOffset: 9999
      }).on('click', function () {
        return clickFunction();
      });

      marker.bindPopup(flightName, {
        closeButton: false
      });
      marker.on('mouseover', function () {
        return marker.openPopup();
      });
      marker.on('mouseout', function () {
        return marker.closePopup();
      });

      this._addOrStoreMarker(Ember.get(this, 'map'), marker);
    },
    _restoreCurrentPositionMarker: function _restoreCurrentPositionMarker() {
      var currentPosition = Ember.get(this, 'currentPosition');

      if (currentPosition) {
        this._addCurrentPositionMarker(currentPosition, true);
      }
    },
    _addCurrentPositionMarker: function _addCurrentPositionMarker(point, force) {
      var currentPosition = Ember.get(this, 'currentPosition');
      var map = Ember.get(this, 'map');

      if (!currentPosition || force) {
        Ember.set(this, 'currentPosition', point);

        this._addOrStoreMarker(map, _L.default.marker(point, {
          icon: _L.default.icon({
            iconUrl: '/assets/images/current-location-marker-icon.png'
          }),
          title: "Your current location",
          alt: "Your current location",
          riseOnHover: true,
          zIndexOffset: 999
        }));
      }
    },
    /////////////////////////////// MAP COMMANDS /////////////////////////////////
    // Stored commands are used when some route or component tries to alter the map,
    // but the map is not yet loaded.  If this is the case, the command will be
    // stored in the array below and called once the map loads.
    _storedMapCommands: [],
    _storeMapCommand: function _storeMapCommand(funcName, arg, opts) {
      var command = {
        funcName: funcName,
        arg: arg,
        opts: opts
      };
      Ember.get(this, '_storedMapCommands').pushObject(command);
    },
    // Removes uncalled storedCommands after 500 milliseconds.
    _purgeStoredMapCommands: Ember.observer('_storedMapCommands', function () {
      Ember.run.later(this, function () {
        if (Ember.get(this, 'map') === null) {
          Ember.set(this, '_storedMapCommands', []);
        }
      }, 500);
    }),
    _runStoredMapCommands: Ember.observer('map', function () {
      var map = Ember.get(this, 'map');
      var storedMapCommands = Ember.get(this, '_storedMapCommands');

      if (map && storedMapCommands.length > 0) {
        storedMapCommands.forEach(function (cmd) {
          var funcName = cmd.funcName;
          var arg = cmd.arg;
          var opts = cmd.opts || {};
          map[funcName](arg, opts);
          storedMapCommands.reject(function (storedCmd) {
            return storedCmd !== cmd;
          });
        });
      }
    }),
    _tryOrStoreMapCommand: function _tryOrStoreMapCommand(map, funcName) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      if (map) {
        map.whenReady(function () {
          map[funcName].apply(map, args);
        });
      } else {
        this._storeMapCommand.apply(this, [funcName].concat(args));
      }
    },
    _fitBounds: function _fitBounds(bounds, opts) {
      var map = Ember.get(this, 'map');

      this._tryOrStoreMapCommand(map, 'fitBounds', bounds, opts);
    },
    _panX: function _panX(diff) {
      var map = Ember.get(this, 'map');

      this._tryOrStoreMapCommand(map, 'panBy', [diff, 0]);
    },
    _panY: function _panY(diff) {
      var map = Ember.get(this, 'map');

      this._tryOrStoreMapCommand(map, 'panBy', [0, diff]);
    },
    _setView: function _setView(point) {
      var map = Ember.get(this, 'map');

      this._tryOrStoreMapCommand(map, 'setView', point, 9);
    },
    _zoomIn: function _zoomIn() {
      var map = Ember.get(this, 'map');

      this._tryOrStoreMapCommand(map, 'zoomIn');
    },
    _zoomOut: function _zoomOut() {
      var map = Ember.get(this, 'map');

      this._tryOrStoreMapCommand(map, 'zoomOut');
    },
    //////////////////////////////// MAP OPTIONS /////////////////////////////////
    // Stored options are used when some route or component tries to alter the map,
    // but the map is not yet loaded.  If this is the case, the option will be
    // stored in the array below and set once the map loads.
    _storedMapOptions: [],
    _storeMapOption: function _storeMapOption(optName, arg) {
      var option = {
        optName: optName,
        arg: arg
      };
      Ember.get(this, '_storedMapOptions').pushObject(option);
    },
    // Removes uncalled storedOptions after 500 milliseconds.
    _purgeStoredMapOptions: Ember.observer('_storedMapOptions', function () {
      Ember.run.later(this, function () {
        if (Ember.get(this, 'map') === null) {
          Ember.set(this, '_storedMapOptions', []);
        }
      }, 500);
    }),
    _setStoredMapOptions: Ember.observer('map', function () {
      var map = Ember.get(this, 'map');
      var storedMapOptions = Ember.get(this, '_storedMapOptions');

      if (map && storedMapOptions.length > 0) {
        _lodash.default.forEach(storedMapOptions, function (value, key) {
          map.options[key] = value;
          storedMapOptions.reject(function (storedOpt) {
            return storedOpt !== {
              key: value
            };
          });
        });
      }
    }),
    _updateOrStoreMapOptions: function _updateOrStoreMapOptions(opts) {
      var _this5 = this;

      var map = Ember.get(this, 'map');

      if (map) {
        map.whenReady(function () {
          _lodash.default.forEach(opts, function (value, key) {
            map.options[key] = value;
          });
        });
      } else {
        _lodash.default.forEach(opts, function (value, key) {
          _this5._storeMapOption(key, value);
        });
      }
    }
  });

  _exports.default = _default;
});