define("superfly/pods/frame/sites/components/link-products-to-site-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h3D/HBRP",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[4,\"custom-modal-dialog\",null,[[\"containerClass\"],[[23,[\"containerClass\"]]]],{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"close\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-close\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\\n  \"],[7,\"h3\"],[11,\"class\",\"title\"],[9],[0,\"Add Products\"],[10],[0,\"\\n\\n  \"],[1,[27,\"input\",null,[[\"value\",\"placeholder\",\"class\"],[[23,[\"searchText\"]],\"Search...\",\"form-control search-input\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"products-list my-3\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"filteredProductOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"type\",\"name\",\"checked\"],[\"checkbox\",\"siteProducts\",[22,1,[\"selected\"]]]]],false],[0,\"\\n        \"],[7,\"label\"],[9],[1,[22,1,[\"name\"]],false],[0,\" \"],[1,[22,1,[\"sku\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"footer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"waiting\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"material-spinner\",null,[[\"size\"],[\"xs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[9],[0,\"cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"save\"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/components/link-products-to-site-modal/template.hbs"
    }
  });

  _exports.default = _default;
});