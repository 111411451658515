define("superfly/pods/shared/not-found/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lo36/5Bq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-10 offset-xs-1 col-md-6 offset-md-3\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"Not available\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"The flight you are looking for either doesn't exist, or is no longer shared.\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/shared/not-found/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});