define("superfly/mapbox/set-zoom-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(acres) {
    if (acres < 5.0) {
      return 18;
    } else if (acres < 13.0) {
      return 17;
    } else if (acres < 60) {
      return 16;
    } else if (acres < 270) {
      return 15;
    } else if (acres < 1000) {
      return 14;
    } else if (acres < 4330) {
      return 13;
    } else if (acres < 17500) {
      return 12;
    } else if (acres < 68040) {
      return 11;
    } else if (acres < 277880) {
      return 10;
    } else if (acres < 1026570) {
      return 9;
    } else if (acres < 4092060) {
      return 8;
    } else if (acres < 16208360) {
      return 7;
    } else if (acres < 71788840) {
      return 6;
    } else if (acres < 262593900) {
      return 5;
    } else if (acres < 1134774400) {
      return 4;
    } else if (acres < 4546323070) {
      return 3;
    } else if (acres < 17534307090) {
      return 2;
    } else {
      return 1;
    }
  }
});