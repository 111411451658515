define("superfly/helpers/overlay-filter-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.overlayFilterTitle = overlayFilterTitle;
  _exports.default = void 0;

  function overlayFilterTitle(params) {
    var overlay = params[0];

    if (!overlay) {
      return 'none';
    }

    var overlayType = Ember.get(overlay, 'overlayType') || '';

    switch (overlayType.toLowerCase()) {
      case 'ndviProcessed':
        return "NDVI";

      case 'ndviUnprocessed':
        return "Orthomosaic";

      default:
        return overlayType.toLowerCase();
    }
  }

  var _default = Ember.Helper.helper(overlayFilterTitle);

  _exports.default = _default;
});