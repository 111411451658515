define("superfly/pods/application/components/flyout-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/0r/wfrL",
    "block": "{\"symbols\":[\"collection\"],\"statements\":[[7,\"h4\"],[9],[0,\"Uploading Flights\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"uploads\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"collectionsToDisplay\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"application/components/flight-upload-status\",null,[[\"collection\",\"dismissUploadSet\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"dismissUploadSet\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Upload more\"],[3,\"action\",[[22,0,[]],\"newUpload\"]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/flyout-uploader/template.hbs"
    }
  });

  _exports.default = _default;
});