define("superfly/pods/application/components/action-type-icon-unchecked/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e1n20oaW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M17.41.553A1.839,1.839,0,0,1,18,1.992v13.98A2.015,2.015,0,0,1,15.971,18H2.029A1.949,1.949,0,0,1,.59,17.41,1.893,1.893,0,0,1,0,15.971V2.029A1.946,1.946,0,0,1,.553.59,2.06,2.06,0,0,1,2.029,0h13.98A1.98,1.98,0,0,1,17.41.553ZM2.029,2.029v13.98h13.98V2.029Z\"],[11,\"transform\",\"translate(-13367 -4851)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/action-type-icon-unchecked/template.hbs"
    }
  });

  _exports.default = _default;
});