define("superfly/pods/application/components/object-type-icon-vegetation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QymZSIUS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"g\"],[11,\"transform\",\"translate(-10629 -16236)\"],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"class\",\"a\"],[11,\"cx\",\"18\"],[11,\"cy\",\"18\"],[11,\"r\",\"18\"],[11,\"transform\",\"translate(22 703)\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"class\",\"b\"],[11,\"d\",\"M8.64,5.58c-2.88.9-5.22,3.24-7.02,7.2A4.455,4.455,0,0,1,.9,11.7,4.62,4.62,0,0,1,0,9.54a7.156,7.156,0,0,1,1.44-3.6C2.34,4.5,3.96,3.6,6.3,3.06c2.34-.36,4.86-.72,7.2-1.08S17.46.9,18,0c0,.72-.72,3.24-2.52,7.74S10.26,14.58,5.4,15.3a4.579,4.579,0,0,1-1.26-.36L3.42,17.1l-1.8-.54a28.831,28.831,0,0,1,3.6-7.02C6.84,7.38,9.54,5.58,13.5,4.68,13.14,4.32,11.52,4.68,8.64,5.58Z\"],[11,\"transform\",\"translate(31 712)\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/object-type-icon-vegetation/template.hbs"
    }
  });

  _exports.default = _default;
});