define("superfly/pods/frame/dashboard/flights/flight/components/route-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mainMap: Ember.inject.service(),
    flight: Ember.computed.alias('model.flight'),
    sites: Ember.computed.alias('model.sites'),
    siteMeasurement: Ember.computed.alias('model.siteMeasurement')
  });

  _exports.default = _default;
});