define("superfly/serializers/measurement-area", ["exports", "superfly/serializers/measurement"], function (_exports, _measurement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _measurement.default.extend();

  _exports.default = _default;
});