define("superfly/adapters/shared-flight", ["exports", "superfly/adapters/application", "superfly/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.BOTLINK_USERS_API_URL,
    headers: Ember.computed(function headers() {
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': Ember.get(this, 'session.shareToken')
      };
    }).volatile()
  });

  _exports.default = _default;
});