define("superfly/pods/frame/dashboard/flights/flight/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8XhscdKg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"application/components/content-scrollable\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"frame/dashboard/flights/flight/components/flight-properties-panel\",null,[[\"sites\",\"flight\",\"siteMeasurement\"],[[23,[\"sites\"]],[23,[\"flight\"]],[23,[\"siteMeasurement\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"footer\"],[9],[0,\"\\n  \"],[1,[27,\"frame/dashboard/flights/flight/components/flight-downloader\",null,[[\"sites\",\"flight\",\"siteMeasurement\"],[[23,[\"sites\"]],[23,[\"flight\"]],[23,[\"siteMeasurement\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/dashboard/flights/flight/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});