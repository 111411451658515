define("superfly/components/unit-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MOlGsBUx",
    "block": "{\"symbols\":[\"unit\"],\"statements\":[[7,\"div\"],[11,\"class\",\"btn-group\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"dropdown btn btn-sm btn-default\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"dropdown-label\"],[9],[1,[21,\"units\"],false],[10],[7,\"span\"],[11,\"class\",\"caret\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"openUnitOptions\"]],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"unitSelectVisible\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu dropdown-select\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"unitsAvailable\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[12,\"class\",[28,[[27,\"if\",[[27,\"eq\",[[22,1,[]],[23,[\"units\"]]],null],\"active\"],null]]]],[9],[0,\"\\n          \"],[7,\"a\"],[9],[1,[22,1,[]],false],[3,\"action\",[[22,0,[]],\"select\",[22,1,[]]]],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/components/unit-selector/template.hbs"
    }
  });

  _exports.default = _default;
});