define("superfly/pods/frame/dashboard/flights/flight/components/properties-viewable-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNameBindings: ['isListEmptyAndReadOnly:empty'],
    measurementService: Ember.inject.service('measurements'),
    notify: Ember.inject.service(),
    type: null,
    header: null,
    units: null,
    flight: null,
    site: null,
    siteMeasurement: null,
    readOnly: null,
    measurements: null,
    componentName: Ember.computed('type', function componentName() {
      return "frame/dashboard/flights/flight/components/properties-viewable-".concat(Ember.get(this, 'type'));
    }),
    sortedMeasurements: Ember.computed.sort('measurements', function sortedMeasurements(a, b) {
      var aCreatedAt = Ember.get(a, 'createdAt') || Date.now();
      var bCreatedAt = Ember.get(b, 'createdAt') || Date.now();

      if (aCreatedAt > bCreatedAt) {
        return -1;
      }

      if (bCreatedAt > aCreatedAt) {
        return 1;
      }

      return 0;
    }),
    isListEnabled: Ember.computed('flight.enabledMeasurements.length', 'type', function isListEnabled() {
      var enabledMeasurements = Ember.get(this, 'flight.enabledMeasurements') || [];
      var type = Ember.get(this, 'type');
      return enabledMeasurements.includes(type);
    }),
    hasMeasurements: Ember.computed.readOnly('measurements.length'),
    isListEmptyAndReadOnly: Ember.computed('hasMeasurements', 'readOnly', function isListEmptyAndReadOnly() {
      var _Ember$getProperties = Ember.getProperties(this, ['hasMeasurements', 'readOnly']),
          hasMeasurements = _Ember$getProperties.hasMeasurements,
          readOnly = _Ember$getProperties.readOnly;

      return !hasMeasurements && readOnly;
    }),
    actions: {
      toggleNewMeasurement: function toggleNewMeasurement() {
        var _Ember$getProperties2 = Ember.getProperties(this, ['type', 'measurementService']),
            type = _Ember$getProperties2.type,
            measurementService = _Ember$getProperties2.measurementService;

        if (Ember.get(measurementService, "is".concat(type.capitalize(), "Active"))) {
          return this.stop();
        }

        return this.start();
      },
      toggleVisibility: function toggleVisibility() {
        var _Ember$getProperties3 = Ember.getProperties(this, ['flight', 'isListEnabled', 'type', 'notify']),
            flight = _Ember$getProperties3.flight,
            isListEnabled = _Ember$getProperties3.isListEnabled,
            type = _Ember$getProperties3.type,
            notify = _Ember$getProperties3.notify;

        var enabledMeasurements = [].concat(Ember.get(flight, 'enabledMeasurements'));

        if (isListEnabled) {
          enabledMeasurements.removeObject(type);
        } else {
          enabledMeasurements.addObject(type);
        }

        Ember.set(flight, 'enabledMeasurements', enabledMeasurements);
        flight.save().catch(function (e) {
          Ember.debug(e);
          flight.rollbackAttribute('enabledMeasurements');
          notify.error('Could not save visibility');
        });
      }
    },
    start: function start() {
      Ember.get(this, 'measurementService').startCreate(Ember.get(this, 'type'));
    },
    stop: function stop() {
      Ember.get(this, 'measurementService').stopCreate();
    }
  });

  _exports.default = _default;
});