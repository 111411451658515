define("superfly/validations/create-product", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    productName: [(0, _validators.validatePresence)(true)],
    sku: [(0, _validators.validatePresence)(true)],
    globalDensity: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      allowBlank: true,
      positive: true
    })],
    globalCost: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      allowBlank: true,
      positive: true
    })]
  };
  _exports.default = _default;
});