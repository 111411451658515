define("superfly/pods/frame/sites/site/products/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RW7Rw0sj",
    "block": "{\"symbols\":[\"currentSite\",\"product\"],\"statements\":[[4,\"liquid-bind\",[[23,[\"site\"]]],[[\"use\"],[\"fade\"]],{\"statements\":[[4,\"each\",[[22,1,[\"products\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"frame/sites/site/products/components/product-card\",null,[[\"site\",\"product\",\"flight\"],[[22,1,[]],[22,2,[]],[23,[\"flight\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n  \"],[2,\" Add Product \"],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row col-12 py-5 pr-0 justify-content-center\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-primary text-uppercase\"],[9],[0,\"Add product to site\"],[3,\"action\",[[22,0,[]],\"openLinkProductsToSiteModal\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[2,\" End add product \"],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isLinkProductsToSiteModalVisible\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"frame/sites/components/link-products-to-site-modal\",null,[[\"site\",\"products\",\"close\"],[[22,1,[]],[23,[\"products\"]],[27,\"action\",[[22,0,[]],\"closeLinkProductsToSiteModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/site/products/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});