define("superfly/services/downloader", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    convertArrayOfObjectsToCSV: function convertArrayOfObjectsToCSV(args) {
      var result, ctr, keys, columnDelimiter, lineDelimiter, data;
      data = args.data || null;

      if (data == null || !data.length) {
        return null;
      }

      columnDelimiter = args.columnDelimiter || ',';
      lineDelimiter = args.lineDelimiter || '\n';
      keys = Object.keys(data[0]);
      result = '';
      result += keys.join(columnDelimiter);
      result += lineDelimiter;
      data.forEach(function (item) {
        ctr = 0;
        keys.forEach(function (key) {
          if (ctr > 0) result += columnDelimiter;
          result += "\"".concat((0, _lodash.replace)(item[key].toString(), /"/g, '""'), "\"");
          ctr++;
        });
        result += lineDelimiter;
      });
      return result;
    },
    // args = { fileName: this.exportCsvFileName, data: exportData }
    downloadCsv: function downloadCsv(args) {
      var csv = this.convertArrayOfObjectsToCSV({
        data: args.data
      });
      if (csv == null) return;
      var fileName = args.fileName || 'export.csv';
      var csvData = new Blob([csv], {
        type: 'text/csv'
      });

      if (window.navigator.msSaveBlob) {
        // FOR IE BROWSER
        navigator.msSaveBlob(csvData, fileName);
      } else {
        var csvUrl = URL.createObjectURL(csvData);
        var link = document.createElement('a');
        link.setAttribute('href', csvUrl);
        link.setAttribute('download', fileName);
        link.click();
      }
    }
  });

  _exports.default = _default;
});