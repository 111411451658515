define("superfly/pods/frame/products/product/components/route-component/component", ["exports", "lodash", "numeral", "moment", "superfly/utils/convert-units"], function (_exports, _lodash, _numeral, _moment, _convertUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    product: null,
    sites: [],
    lastFlights: null,
    editing: true,
    isCamVisible: false,
    isEditProductModalVisible: false,
    isDeleteProductModalVisible: false,
    columns: Ember.computed('product.id', function () {
      var massCustomUnitLabel = Ember.get(this, 'massCustomUnitLabel');
      var volumeCustomUnitLabel = Ember.get(this, 'volumeCustomUnitLabel');
      var densityCustomUnitLabel = Ember.get(this, 'densityCustomUnitLabel');
      return [{
        name: 'Location',
        valuePath: 'name'
      }, {
        name: "Volume (".concat(volumeCustomUnitLabel, ")"),
        valuePath: 'volume'
      }, {
        name: "Average Density (".concat(densityCustomUnitLabel, ")"),
        valuePath: 'density'
      }, {
        name: "Mass (".concat(massCustomUnitLabel, ")"),
        valuePath: 'mass'
      }, {
        name: "Site Density (".concat(densityCustomUnitLabel, ")"),
        valuePath: 'siteDensity'
      }, {
        name: "Site Cost ($/".concat(massCustomUnitLabel, ")"),
        valuePath: 'siteCost'
      }, {
        name: 'Total Value',
        valuePath: 'totalValue'
      }, {
        name: "Tracked (".concat(massCustomUnitLabel, ")"),
        valuePath: 'inventory'
      }, {
        name: 'Last Flown',
        valuePath: 'lastFlown'
      }];
    }),
    totalLocations: 0,
    totalMass: 0,
    totalValue: 0,
    totalTrackedInventory: 0,
    globalCost: Ember.computed('product.globalCost', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var globalCost = Ember.get(this, 'product.globalCost');
      return convertUtil.convertMassFromCustomUnit(globalCost);
    }),
    globalDensity: Ember.computed('product.globalDensity', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var globalDensity = Ember.get(this, 'product.globalDensity');
      return (0, _lodash.round)(convertUtil.convertDensityToCustomUnit(globalDensity), 2);
    }),
    // TODO: Need to fix when to recompute these props
    massCustomUnitLabel: Ember.computed('product.id', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('MassUnit').value.massUnitName;
      return unitName;
    }),
    volumeCustomUnitLabel: Ember.computed('product.id', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('VolumeUnit').value.volumeUnitName;
      return unitName;
    }),
    densityCustomUnitLabel: Ember.computed('product.id', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('DensityUnit').value.densityUnitName;
      return unitName;
    }),
    rows: Ember.computed('product.id', function rows() {
      var _this = this;

      var product = Ember.get(this, 'product');
      var productId = Ember.get(product, 'id');
      var sites = Ember.get(this, 'sites').toArray();
      var lastFlights = Ember.get(this, 'lastFlights');
      var validSites = [];

      var _loop = function _loop(i) {
        var site = sites[i];
        var siteId = Ember.get(site, 'id');
        var lastFlight = (0, _lodash.find)(lastFlights, function (flight) {
          return flight.siteId === siteId;
        });
        var siteProducts = Ember.get(site, 'products').toArray();
        var siteProduct = (0, _lodash.find)(siteProducts, function (siteProduct) {
          var siteProductProductId = Ember.get(siteProduct, 'product.id');
          return productId === siteProductProductId;
        });

        if (siteProduct) {
          var convertUtil = _convertUnits.default.create({
            container: Ember.getOwner(_this)
          }); // Cost should be the inverse


          var siteCost = convertUtil.convertMassFromCustomUnit(Ember.get(siteProduct, 'siteCost') || Ember.get(product, 'globalCost'));
          var volume = 0;
          var density = 0;
          var mass = 0;
          var _totalValue = 0;

          if (lastFlight) {
            var flightAttributes = (0, _lodash.isEmpty)(lastFlight.flight) ? [] : lastFlight.flight.readings[0].attributes;
            var flightPiles = ((0, _lodash.find)(flightAttributes, function (flightAttribute) {
              return flightAttribute.code === 'piles';
            }) || {}).value;
            var productPiles = (0, _lodash.filter)(flightPiles, function (pile) {
              return pile.productId === productId;
            });
            volume = convertUtil.convertVolumeToCustomUnit((0, _lodash.sumBy)(productPiles, function (pile) {
              return pile.volume;
            }));
            mass = convertUtil.convertMassToCustomUnit((0, _lodash.sumBy)(productPiles, function (pile) {
              return pile.volume * pile.density;
            }));
            var pileCount = productPiles.length === 0 ? 1 : productPiles.length;
            var summedPileDensity = (0, _lodash.sumBy)(productPiles, function (pile) {
              return pile.density;
            });
            density = convertUtil.convertDensityToCustomUnit(summedPileDensity / pileCount);
            _totalValue = (0, _lodash.sumBy)(productPiles, function (pile) {
              return pile.volume * pile.density * parseFloat(pile.cost);
            });
          }

          var lastFlown = Ember.get(site, 'lastFlown');
          var siteDensity = convertUtil.convertDensityToCustomUnit(Ember.get(siteProduct, 'siteDensity') || Ember.get(product, 'globalDensity'));
          var inventory = Ember.get(siteProduct, 'inventory');
          inventory = inventory ? (0, _lodash.round)(convertUtil.convertMassToCustomUnit(inventory), 2) : 0;
          var mappedSite = {
            name: Ember.get(site, 'name'),
            siteId: Ember.get(site, 'id'),
            volume: volume,
            density: (0, _numeral.default)(density).format('0,0.00'),
            siteDensity: siteDensity,
            mass: mass,
            siteCost: siteCost,
            totalValue: _totalValue,
            inventory: inventory,
            lastFlown: lastFlown === 1 ? 'N/A' : (0, _moment.default)(lastFlown).format('MMM DD, YYYY')
          };
          validSites.push(mappedSite);
        }
      };

      for (var i = 0; i < sites.length; i++) {
        _loop(i);
      }

      Ember.set(this, 'totalLocations', validSites.length);
      var totalMass = (0, _lodash.sumBy)(validSites, function (site) {
        return site.mass;
      });
      Ember.set(this, 'totalMass', totalMass);
      var totalValue = (0, _lodash.sumBy)(validSites, function (site) {
        return site.totalValue;
      });
      Ember.set(this, 'totalValue', totalValue);
      var totalTrackedInventory = (0, _lodash.sumBy)(validSites, function (site) {
        return site.inventory ? parseFloat(site.inventory) : 0;
      });
      Ember.set(this, 'totalTrackedInventory', totalTrackedInventory);
      return validSites;
    }),
    hasSites: Ember.computed('rows', function () {
      var hasSites = Ember.get(this, 'rows.length') > 0;
      return hasSites;
    }),
    actions: {
      sortFunction: function sortFunction(item1, item2, columns, defaultComparer) {
        var column = columns[0];
        var item1Value = Ember.get(item1, column.valuePath);
        var item2Value = Ember.get(item2, column.valuePath);

        if (column.valuePath === 'siteCost' || column.valuePath === 'siteDensity') {
          item1Value = parseFloat(item1Value);
          item2Value = parseFloat(item2Value);
        }

        if (column.isAscending) {
          return defaultComparer(item1Value, item2Value);
        }

        return defaultComparer(item2Value, item1Value);
      },
      onPrintClick: function onPrintClick() {
        console.log('On print click, TODO: Implement');
      },
      onShareClick: function onShareClick() {
        console.log('On share click, TODO: Implement');
      },
      closeEditProductModal: function closeEditProductModal() {
        Ember.set(this, 'isEditProductModalVisible', false);
      },
      openEditProductModal: function openEditProductModal() {
        Ember.set(this, 'isEditProductModalVisible', true);
      },
      closeDeleteProductModal: function closeDeleteProductModal() {
        Ember.set(this, 'isDeleteProductModalVisible', false);
      },
      openDeleteProductModal: function openDeleteProductModal() {
        Ember.set(this, 'isDeleteProductModalVisible', true);
      }
    }
  });

  _exports.default = _default;
});