define("superfly/mixins/inventory-management-paywall-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    subscriptionManager: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      // TODO: Check for inventory management subscription
      var hasValidInventoryManagementSubscription = Ember.get(this, 'subscriptionManager.hasValidInventoryManagementSubscription');

      if (!hasValidInventoryManagementSubscription) {
        // if no valid subscription is found redirect to the 
        // select a plan screen
        this.transitionTo('frame.contact-sales');
      }

      this._super(transition);
    }
  });

  _exports.default = _default;
});