define("superfly/components/option-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IQgN3bas",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"computedOptions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"d-flex option-group-item\"],[11,\"style\",\"cursor: pointer;\"],[11,\"data-toggle\",\"tooltip\"],[12,\"title\",[28,[[22,1,[\"tooltip\"]]]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[22,1,[\"value\"]],[23,[\"selected\",\"value\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"material-icons mr-2 option-group-item-icon\"],[9],[0,\"radio_button_checked\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"material-icons mr-2 option-group-item-icon\"],[9],[0,\"radio_button_unchecked\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n      \"],[7,\"a\"],[9],[1,[22,1,[\"label\"]],false],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"select\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/components/option-group/template.hbs"
    }
  });

  _exports.default = _default;
});