define("superfly/router", ["exports", "ember-cli-analytics/mixins/trackable", "superfly/config/environment"], function (_exports, _trackable, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RouterInstance = Ember.Router.extend(_trackable.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  RouterInstance.map(function () {
    // Legacy Route Redirects
    this.route('login');
    this.route('logout');
    this.route('signup');
    this.route('confirm');
    this.route('frame', {
      path: '/'
    }, function () {
      this.route('dashboard', function () {
        this.route('folders', function () {
          this.route('folder', {
            path: '/:folderId'
          });
        });
        this.route('flights', function () {
          this.route('flight', {
            path: '/:flightId'
          });
        });
      });
      this.route('settings', function () {
        this.route('profile');
        this.route('account');
        this.route('billing');
        this.route('integrations');
        this.route('units');
      });
      this.route('contact-sales');
      this.route('invoice');
      this.route('botlink-product', {
        path: '/:productName'
      }, function () {
        this.route('plans', function () {
          this.route('select-plan');
          this.route('checkout');
        });
      });
      this.route('sites', function () {
        this.route('site', {
          path: '/:siteId'
        }, function () {
          this.route('products');
          this.route('flights');
          this.route('maps');
        });
      });
      this.route('products', function () {
        this.route('product', {
          path: '/:productId'
        });
      });
    });
    this.route('auth', function () {
      this.route('login');
      this.route('forgot-password');
      this.route('logout');
      this.route('signup');
      this.route('confirm');
      this.route('set-new-password');
    });
    this.route('shared', {
      path: 'shared/:shareId'
    }, function () {
      this.route('locked');
      this.route('not-found');
    });
    this.route('not-found', {
      path: '/*path'
    }); // catchall
  });
  var _default = RouterInstance;
  _exports.default = _default;
});