define("superfly/pods/auth/login/route", ["exports", "superfly/mixins/unathenticated-route"], function (_exports, _unathenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unathenticatedRoute.default, {
    titleToken: 'Login',
    setupController: function setupController(controller, model) {
      Ember.setProperties(this, 'controller', {
        model: model
      });
    }
  });

  _exports.default = _default;
});