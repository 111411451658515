define("superfly/components/custom-modal-dialog/component", ["exports", "ember-modal-dialog/components/modal-dialog"], function (_exports, _modalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modalDialog.default.extend({
    translucentOverlay: true,
    clickOutsideToClose: true,
    containerClass: 'centered-scrolling-container',
    overlayClass: 'centered-scrolling-overlay',
    wrapperClass: 'centered-scrolling-wrapper'
  });

  _exports.default = _default;
});