define("superfly/pods/application/components/upload-status-tray/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uploadManager: Ember.inject.service(),
    collection: null,
    isListVisible: true,
    isVisible: Ember.computed.bool('uploadManager.collection.length'),
    collectionsToDisplay: Ember.computed.alias('uploadManager.collection'),
    actions: {
      dismissUploadSet: function dismissUploadSet(collection) {
        var manager = Ember.get(this, 'uploadManager');
        manager.removeCollectionFromQueue(collection);
      },
      minimize: function minimize() {
        this.toggleProperty('isListVisible');
      }
    }
  });

  _exports.default = _default;
});