define("superfly/adapters/plan", ["exports", "superfly/adapters/application", "superfly/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.BOTLINK_SUBSCRIPTIONS_API_URL,
    namespace: 'subscriptions',
    pathForType: function pathForType() {
      return 'plans';
    }
  });

  _exports.default = _default;
});