define("superfly/pods/frame/sites/site/index/components/route-component/component", ["exports", "ember-data", "superfly/utils/convert-units", "superfly/utils/static-flight-map", "superfly/utils/inventoryManagementDataLoader", "lodash"], function (_exports, _emberData, _convertUnits, _staticFlightMap, _inventoryManagementDataLoader, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    site: null,
    flight: null,
    editing: true,
    isEditSiteModalVisible: false,
    isDeleteSiteModalVisible: false,
    isCamVisible: false,
    columns: Ember.computed('densityCustomUnitLabel', function () {
      return [{
        name: 'Product',
        valuePath: 'productName'
      }, {
        name: "Volume (".concat(Ember.get(this, 'volumeCustomUnitLabel'), ")"),
        valuePath: 'volume'
      }, {
        name: "Average Density (".concat(Ember.get(this, 'densityCustomUnitLabel'), ")"),
        valuePath: 'density'
      }, {
        name: "Mass (".concat(Ember.get(this, 'massCustomUnitLabel'), ")"),
        valuePath: 'mass'
      }, {
        name: 'Cost',
        valuePath: 'cost'
      }, {
        name: 'Total Value',
        valuePath: 'totalValue'
      }, {
        name: "Inventory (".concat(Ember.get(this, 'massCustomUnitLabel'), ")"),
        valuePath: 'inventory'
      }];
    }),
    // TODO: Need to fix when to recompute these props
    massCustomUnitLabel: Ember.computed('session.currentUser.settings.@each.value', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('MassUnit').value.massUnitName;
      return unitName;
    }),
    volumeCustomUnitLabel: Ember.computed('session.currentUser.settings.@each.value', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('VolumeUnit').value.volumeUnitName;
      return unitName;
    }),
    densityCustomUnitLabel: Ember.computed('session.currentUser.settings.@each.value', function () {
      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      var unitName = convertUtil.getSetting('DensityUnit').value.densityUnitName;
      return unitName;
    }),
    rows: Ember.computed('site.products', function () {
      var inventoryManagementDataLoader = _inventoryManagementDataLoader.default.create({
        container: Ember.getOwner(this)
      });

      var site = Ember.get(this, 'site');
      var flight = Ember.get(this, 'flight');

      var convertUtil = _convertUnits.default.create({
        container: Ember.getOwner(this)
      });

      return inventoryManagementDataLoader.convertFlightIntoSiteStats(site, flight, convertUtil);
    }),
    siteTotalValue: Ember.computed('rows', function () {
      var productRows = Ember.get(this, 'rows');
      var siteTotalValue = productRows.reduce(function (a, pr) {
        a += pr.totalValue;
        return a;
      }, 0);
      return siteTotalValue;
    }),
    flightMap: Ember.computed('flight', function () {
      var flightMeasurement = Ember.get(this, 'flight');
      var flightAttributes = (0, _lodash.isEmpty)(flightMeasurement) ? [] : flightMeasurement.readings[0].attributes;
      var flightAttribute = (0, _lodash.find)(flightAttributes, function (attribute) {
        return attribute.code == "missionId";
      });

      if (flightAttribute) {
        var missionId = flightAttribute.value;
        var flightPromise = Ember.get(this, 'store').findRecord('flight', missionId, {
          reload: true
        }).then(function (flight) {
          var flightMapThumbnail = (0, _staticFlightMap.default)(flight, 250, 250);
          return flightMapThumbnail;
        });
        return _emberData.default.PromiseObject.create({
          promise: flightPromise
        });
      }

      return '/';
    }),
    actions: {
      sortFunction: function sortFunction(item1, item2, columns, defaultComparer) {
        var column = columns[0];
        var item1Value = Ember.get(item1, column.valuePath);
        var item2Value = Ember.get(item2, column.valuePath);

        if (column.valuePath === 'cost') {
          item1Value = parseFloat(Ember.get(item1, 'siteCost') || Ember.get(item1, 'product.globalCost'));
          item2Value = parseFloat(Ember.get(item2, 'siteCost') || Ember.get(item2, 'product.globalCost'));
        }

        if (column.isAscending) {
          return defaultComparer(item1Value, item2Value);
        }

        return defaultComparer(item2Value, item1Value);
      },
      onShareClick: function onShareClick() {
        console.log('On share click, TODO: Implement');
      },
      closeEditSiteModal: function closeEditSiteModal() {
        Ember.set(this, 'isEditSiteModalVisible', false);
      },
      openEditSiteModal: function openEditSiteModal() {
        Ember.set(this, 'isEditSiteModalVisible', true);
      },
      closeDeleteSiteModal: function closeDeleteSiteModal() {
        Ember.set(this, 'isDeleteSiteModalVisible', false);
      },
      openDeleteSiteModal: function openDeleteSiteModal() {
        Ember.set(this, 'isDeleteSiteModalVisible', true);
      }
    }
  });

  _exports.default = _default;
});