define("superfly/services/agx", ["exports", "superfly/mixins/integration-service", "superfly/config/environment"], function (_exports, _integrationService, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_integrationService.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    growers: null,
    farms: null,
    fields: null,
    connections: null,
    selectedGrower: null,
    selectedFarm: null,
    selectedField: null,
    // Connection Statuses
    isCheckingConnection: false,
    isLoadingConnections: false,
    isLoadingGrowers: false,
    isLoadingFarms: false,
    isLoadingFields: false,
    isDisconnecting: false,
    isLoadingAny: Ember.computed('isLoadingConnections', 'isLoadingFarms', 'isLoadingFields', function isLoadingAny() {
      var _Ember$getProperties = Ember.getProperties(this, ['isLoadingConnections', 'isLoadingFarms', 'isLoadingFields']),
          isLoadingConnections = _Ember$getProperties.isLoadingConnections,
          isLoadingFarms = _Ember$getProperties.isLoadingFarms,
          isLoadingFields = _Ember$getProperties.isLoadingFields;

      return isLoadingConnections || isLoadingFarms || isLoadingFields;
    }),
    doCheckConnection: function doCheckConnection() {
      var _this = this;

      var _Ember$getProperties2 = Ember.getProperties(this, ['userId', 'accessToken', 'hasUserAndToken', 'ajax']),
          userId = _Ember$getProperties2.userId,
          accessToken = _Ember$getProperties2.accessToken,
          hasUserAndToken = _Ember$getProperties2.hasUserAndToken,
          ajax = _Ember$getProperties2.ajax;

      if (hasUserAndToken) {
        Ember.set(this, 'isCheckingConnection', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/users/").concat(userId, "/identity?token=").concat(accessToken)).then(function (response) {
          return Ember.set(_this, 'isConnected', !!response);
        }).catch(function () {
          return Ember.set(_this, 'isConnected', false);
        }).finally(function () {
          return Ember.set(_this, 'isCheckingConnection', false);
        });
      }
    },
    checkConnection: function checkConnection() {
      Ember.run.debounce(this, this.doCheckConnection, 500);
    },
    onShouldCheckConnection: Ember.on('init', Ember.observer('userId', 'accessToken', function onShouldCheckConnection() {
      this.checkConnection();
    })),
    setConnections: Ember.on('init', Ember.observer('isConnected', function () {
      this.getConnections();
    })),
    setGrowers: Ember.observer('isConnected', function setGrowers() {
      var _this2 = this;

      var _Ember$getProperties3 = Ember.getProperties(this, ['userId', 'shouldConnect', 'ajax']),
          userId = _Ember$getProperties3.userId,
          shouldConnect = _Ember$getProperties3.shouldConnect,
          ajax = _Ember$getProperties3.ajax;

      if (!shouldConnect) {
        Ember.set(this, 'isLoadingGrowers', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/users/").concat(userId, "/growers")).then(function (growers) {
          return Ember.set(_this2, 'growers', growers);
        }).finally(function () {
          return Ember.set(_this2, 'isLoadingGrowers', false);
        });
      }
    }),
    setFarms: Ember.observer('selectedGrower', function setFarms() {
      var _this3 = this;

      var _Ember$getProperties4 = Ember.getProperties(this, ['userId', 'shouldConnect', 'ajax', 'selectedGrower']),
          userId = _Ember$getProperties4.userId,
          shouldConnect = _Ember$getProperties4.shouldConnect,
          ajax = _Ember$getProperties4.ajax,
          selectedGrower = _Ember$getProperties4.selectedGrower;

      if (!shouldConnect && selectedGrower) {
        var selectedGrowerId = Ember.get(selectedGrower, 'id');
        Ember.set(this, 'isLoadingFarms', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/users/").concat(userId, "/growers/").concat(selectedGrowerId, "/farms")).then(function (farms) {
          Ember.set(_this3, 'farms', farms);
          Ember.set(_this3, 'selectedFarm', null);
          Ember.set(_this3, 'selectedField', null);
        }).finally(function () {
          return Ember.set(_this3, 'isLoadingFarms', false);
        });
      }
    }),
    setFields: Ember.observer('selectedFarm', function setFields() {
      var _this4 = this;

      var _Ember$getProperties5 = Ember.getProperties(this, ['userId', 'shouldConnect', 'selectedGrower', 'selectedFarm', 'ajax']),
          userId = _Ember$getProperties5.userId,
          shouldConnect = _Ember$getProperties5.shouldConnect,
          selectedGrower = _Ember$getProperties5.selectedGrower,
          selectedFarm = _Ember$getProperties5.selectedFarm,
          ajax = _Ember$getProperties5.ajax;

      if (!shouldConnect && selectedGrower && selectedFarm) {
        var selectedFarmId = Ember.get(selectedFarm, 'id');
        var selectedGrowerId = Ember.get(selectedGrower, 'id');
        Ember.set(this, 'isLoadingFields', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/users/").concat(userId, "/growers/").concat(selectedGrowerId, "/farms/").concat(selectedFarmId, "/fields")).then(function (fields) {
          var selectedField = null;
          Ember.setProperties(_this4, {
            fields: fields,
            selectedField: selectedField
          });
        }).finally(function () {
          return Ember.set(_this4, 'isLoadingFields', false);
        });
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.getSeasons();
    },
    resetSelections: function resetSelections() {
      Ember.setProperties(this, {
        selectedGrower: null,
        selectedFarm: null,
        selectedField: null
      });
    },
    connect: function connect(callbackURL) {
      var _Ember$getProperties6 = Ember.getProperties(this, ['userId', 'accessToken', 'shouldConnect']),
          userId = _Ember$getProperties6.userId,
          accessToken = _Ember$getProperties6.accessToken,
          shouldConnect = _Ember$getProperties6.shouldConnect;

      if (shouldConnect) {
        var url = "".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/oauth/users/").concat(userId, "?token=").concat(accessToken, "&callback=").concat(callbackURL);
        window.location.replace(url);
      }
    },
    disconnect: function disconnect() {
      var _this5 = this;

      var _Ember$getProperties7 = Ember.getProperties(this, ['userId', 'hasUserAndToken', 'ajax']),
          userId = _Ember$getProperties7.userId,
          hasUserAndToken = _Ember$getProperties7.hasUserAndToken,
          ajax = _Ember$getProperties7.ajax;

      if (hasUserAndToken) {
        Ember.set(this, 'isDisconnecting', true);
        ajax.delete("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/users/").concat(userId), {
          dataType: 'text'
        }).then(function () {
          return Ember.set(_this5, 'isConnected', false);
        }).finally(function () {
          return Ember.set(_this5, 'isDisconnecting', false);
        });
      }
    },
    getSeasons: function getSeasons() {
      var _this6 = this;

      var ajax = Ember.get(this, 'ajax');
      Ember.set(this, 'isLoadingSeasons', true);
      ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/seasons")).then(function (seasons) {
        return Ember.set(_this6, 'seasons', seasons);
      }).finally(function () {
        return Ember.set(_this6, 'isLoadingSeasons', false);
      });
    },
    getConnections: function getConnections() {
      var _this7 = this;

      var _Ember$getProperties8 = Ember.getProperties(this, ['userId', 'shouldConnect', 'ajax']),
          userId = _Ember$getProperties8.userId,
          shouldConnect = _Ember$getProperties8.shouldConnect,
          ajax = _Ember$getProperties8.ajax;

      if (userId && !shouldConnect) {
        Ember.set(this, 'isLoadingConnections', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/users/").concat(userId, "/connections")).then(function (connections) {
          return Ember.set(_this7, 'connections', connections);
        }).finally(function () {
          return Ember.set(_this7, 'isLoadingConnections', false);
        });
      }
    },
    addConnection: function addConnection(companyId, projectId, albumId, folderId) {
      var _this8 = this;

      var _Ember$getProperties9 = Ember.getProperties(this, ['userId', 'hasUserAndToken', 'ajax']),
          userId = _Ember$getProperties9.userId,
          hasUserAndToken = _Ember$getProperties9.hasUserAndToken,
          ajax = _Ember$getProperties9.ajax;

      if (hasUserAndToken && projectId && albumId && folderId) {
        var fields = Ember.get(this, 'fields');
        var album = fields.findBy('id', albumId);
        var connection = {
          user_id: parseInt(userId, 10),
          botlink_folder: folderId,
          name: Ember.get(album, 'name'),
          grower_id: companyId,
          farm_id: projectId,
          field_id: albumId
        };
        var data = JSON.stringify(connection);
        return ajax.post("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/users/").concat(userId, "/connections"), {
          data: data,
          dataType: 'text'
        }).then(function () {
          return _this8.getConnections();
        });
      }

      return Ember.RSVP.reject('addConnection failed.');
    },
    deleteConnection: function deleteConnection(connectionId) {
      var _Ember$getProperties10 = Ember.getProperties(this, ['userId', 'hasUserAndToken', 'ajax']),
          hasUserAndToken = _Ember$getProperties10.hasUserAndToken,
          ajax = _Ember$getProperties10.ajax;

      if (hasUserAndToken && connectionId) {
        var connections = Ember.get(this, 'connections');
        var connection = connections.findBy('id', connectionId);
        return ajax.delete("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/connections/").concat(connectionId), {
          dataType: 'text'
        }).then(function () {
          return connections.removeObject(connection);
        });
      }

      return Ember.RSVP.reject('deleteConnection failed.');
    }
  });

  _exports.default = _default;
});