define("superfly/serializers/stitched-asset", ["exports", "superfly/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForRelationship: function keyForRelationship(key) {
      if (key === 'flight') {
        return 'flightID';
      }

      return "".concat(Ember.String.camelize(key), "Id");
    }
  });

  _exports.default = _default;
});