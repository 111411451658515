define("superfly/pods/application/components/map-controls/component", ["exports", "L"], function (_exports, _L) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['map-controls'],
    classNameBindings: ['hasBanner:has-banner'],
    locationState: Ember.inject.service(),
    mainMap: Ember.inject.service(),
    notify: Ember.inject.service(),
    subscriptionManager: Ember.inject.service(),
    currentPositionIsLoading: false,

    /**
     * Checks to see if the user's browser supports the current position API and
     * if the UI is displaying the Sites panel. If either is false, the current
     * position button is hidden.
     */
    toggleCurrentPositionControl: Ember.on('didInsertElement', Ember.observer('locationState.displayFlightsPanel', function () {
      var hasBrowserSupport = !!navigator.geolocation.getCurrentPosition;

      if (hasBrowserSupport) {
        Ember.$('.current-position').fadeIn();
      } else {
        Ember.$('.current-position').fadeOut();
      }
    })),
    actions: {
      /**
       * Marks the user's current position and centers map over it.
       * If position has already been marked once, that position is stored and
       * reused so there is only one current position marker - on subsequent calls, map
       * centering is all that happens.
       */
      markCurrentPosition: function markCurrentPosition() {
        var currentPosition = Ember.get(this, 'mainMap.currentPosition');

        if (currentPosition) {
          this._centerMapOverPoint(currentPosition);
        } else {
          this._getAndMarkCurrentPosition();
        }
      },
      zoomIn: function zoomIn() {
        Ember.get(this, 'mainMap').zoomIn();
      },
      zoomOut: function zoomOut() {
        Ember.get(this, 'mainMap').zoomOut();
      }
    },
    _getAndMarkCurrentPosition: function _getAndMarkCurrentPosition() {
      var _this = this;

      Ember.set(this, 'currentPositionIsLoading', true);
      new Ember.RSVP.Promise(function (resolve, reject) {
        return navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          maximumAge: 30000,
          timeout: 27000
        });
      }).then(function (position) {
        var point = _L.default.latLng(position.coords.latitude, position.coords.longitude);

        Ember.set(_this, 'currentPositionIsLoading', false);
        Ember.get(_this, 'mainMap').addCurrentPositionMarker(point);

        _this._centerMapOverPoint(point);
      }).catch(function (e) {
        Ember.get(_this, 'notify').error(Ember.get(e, 'message'));
      }).finally(function () {
        Ember.set(_this, 'currentPositionIsLoading', false);
      });
    },
    _centerMapOverPoint: function _centerMapOverPoint(point) {
      var map = Ember.get(this, 'mainMap.map');
      map.setView(point, 15);
    }
  });

  _exports.default = _default;
});