define("superfly/pods/application/components/loading-svg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NgqSc1sg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"44\"],[11,\"height\",\"44\"],[11,\"viewBox\",\"0 0 44 44\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"stroke\",\"#fff\"],[9],[0,\"\\n  \"],[7,\"g\"],[11,\"fill\",\"none\"],[11,\"fill-rule\",\"evenodd\"],[11,\"stroke-width\",\"2\"],[9],[0,\"\\n    \"],[7,\"circle\"],[11,\"cx\",\"22\"],[11,\"cy\",\"22\"],[11,\"r\",\"1\"],[9],[0,\"\\n      \"],[7,\"animate\"],[11,\"attributeName\",\"r\"],[11,\"begin\",\"0s\"],[11,\"dur\",\"1.8s\"],[11,\"values\",\"1; 20\"],[11,\"calcMode\",\"spline\"],[11,\"keyTimes\",\"0; 1\"],[11,\"keySplines\",\"0.165, 0.84, 0.44, 1\"],[11,\"repeatCount\",\"indefinite\"],[9],[10],[0,\"\\n      \"],[7,\"animate\"],[11,\"attributeName\",\"stroke-opacity\"],[11,\"begin\",\"0s\"],[11,\"dur\",\"1.8s\"],[11,\"values\",\"1; 0\"],[11,\"calcMode\",\"spline\"],[11,\"keyTimes\",\"0; 1\"],[11,\"keySplines\",\"0.3, 0.61, 0.355, 1\"],[11,\"repeatCount\",\"indefinite\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"circle\"],[11,\"cx\",\"22\"],[11,\"cy\",\"22\"],[11,\"r\",\"1\"],[9],[0,\"\\n      \"],[7,\"animate\"],[11,\"attributeName\",\"r\"],[11,\"begin\",\"-0.9s\"],[11,\"dur\",\"1.8s\"],[11,\"values\",\"1; 20\"],[11,\"calcMode\",\"spline\"],[11,\"keyTimes\",\"0; 1\"],[11,\"keySplines\",\"0.165, 0.84, 0.44, 1\"],[11,\"repeatCount\",\"indefinite\"],[9],[10],[0,\"\\n      \"],[7,\"animate\"],[11,\"attributeName\",\"stroke-opacity\"],[11,\"begin\",\"-0.9s\"],[11,\"dur\",\"1.8s\"],[11,\"values\",\"1; 0\"],[11,\"calcMode\",\"spline\"],[11,\"keyTimes\",\"0; 1\"],[11,\"keySplines\",\"0.3, 0.61, 0.355, 1\"],[11,\"repeatCount\",\"indefinite\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/loading-svg/template.hbs"
    }
  });

  _exports.default = _default;
});