define("superfly/pods/frame/contact-sales/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M7HeiwpB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row col-12 justify-content-center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"plan-title pt-5\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"trialEnded\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h2\"],[11,\"class\",\"title\"],[9],[0,\"Trial ended\"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"subtitle\"],[9],[0,\"\\n        Your free trial of the Botlink Professional plan has ended.\"],[7,\"br\"],[9],[10],[7,\"br\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n\\n    \"],[7,\"p\"],[11,\"class\",\"subtitle\"],[9],[0,\"\\n      Botlink offers custom pricing plans tailored to your business needs.\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"a\"],[11,\"target\",\"_blank\"],[11,\"href\",\"https://www.botlink.com/pricing\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Contact Sales\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"style\"],[9],[0,\"\\n  .plan-title {\\n    text-align: center\\n  }\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/contact-sales/template.hbs"
    }
  });

  _exports.default = _default;
});