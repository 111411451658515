define("superfly/pods/auth/login/components/login-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    session: Ember.inject.service(),
    identification: null,
    password: null,
    isLoading: false,
    isShowingError: false,
    actions: {
      submit: function submit() {
        var _this = this;

        Ember.set(this, 'isLoading', true);
        return Ember.get(this, 'session').authenticate('authenticator:custom', Ember.getProperties(this, ['identification', 'password'])).catch(function () {
          Ember.set(_this, 'isLoading', false);
          Ember.set(_this, 'isShowingError', true);
        });
      }
    }
  });

  _exports.default = _default;
});