define("superfly/pods/auth/login/components/login-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IxiC2kMm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isShowingError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"alert alert-danger\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-exclamation-circle\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" The email and/or password are incorrect\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"input\",null,[[\"value\",\"class\",\"placeholder\"],[[23,[\"identification\"]],\"form-control\",\"Email\"]]],false],[0,\"\\n\"],[1,[27,\"input\",null,[[\"value\",\"type\",\"class\",\"placeholder\"],[[23,[\"password\"]],\"password\",\"form-control\",\"Password\"]]],false],[0,\"\\n\\n\"],[7,\"a\"],[12,\"href\",[28,[[27,\"href-to\",[\"auth.forgot-password\"],null]]]],[9],[0,\"Forgot password?\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"button-container\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"      Login\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[3,\"action\",[[22,0,[]],\"submit\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/login/components/login-form/template.hbs"
    }
  });

  _exports.default = _default;
});