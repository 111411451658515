define("superfly/pods/shared/index/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EKd2tueQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"application/components/site-sidebar\",null,null,{\"statements\":[[4,\"application/components/content-scrollable\",null,null,{\"statements\":[[0,\"    \"],[1,[27,\"frame/dashboard/flights/flight/components/flight-properties-panel\",null,[[\"flight\",\"readOnly\"],[[23,[\"share\",\"sharedFlight\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"application/components/site-content\",null,null,{\"statements\":[[0,\"  \"],[1,[21,\"application/components/main-map-group\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/shared/index/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});