define("superfly/helpers/unit-abbreviation", ["exports", "superfly/utils/unit-abbr"], function (_exports, _unitAbbr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unitAbbreviation = unitAbbreviation;
  _exports.default = void 0;

  function unitAbbreviation(params) {
    var selectedUnits = params[0];
    var units = ['feet', 'meters', 'yards', 'kilometers'].includes(selectedUnits) ? selectedUnits : 'yards';

    if (selectedUnits === 'hectares') {
      units = 'meters';
    }

    return "".concat((0, _unitAbbr.default)(units));
  }

  var _default = Ember.Helper.helper(unitAbbreviation);

  _exports.default = _default;
});