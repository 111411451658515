define("superfly/pods/frame/dashboard/flights/flight/components/theme-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IGqG3LGe",
    "block": "{\"symbols\":[\"theme\"],\"statements\":[[4,\"each\",[[23,[\"themes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"class\",[28,[\"theme \",[22,1,[]]]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[22,1,[]],[23,[\"current\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"application/components/svg-icon\",null,[[\"family\",\"icon\"],[\"action\",\"color\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"select\",[22,1,[]]],null]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/dashboard/flights/flight/components/theme-selector/template.hbs"
    }
  });

  _exports.default = _default;
});