define("superfly/pods/frame/dashboard/components/measurement-toolbar-distance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dGjqiCBY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"isActive\"]],\"active\"],null],\" \",[27,\"if\",[[23,[\"isDisabled\"]],\"disabled\"],null]]]],[9],[0,\"\\n  \"],[1,[27,\"application/components/svg-icon\",null,[[\"family\",\"icon\"],[\"tool\",\"distance\"]]],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"toggleDraw\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/dashboard/components/measurement-toolbar-distance/template.hbs"
    }
  });

  _exports.default = _default;
});