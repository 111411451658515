define("superfly/pods/application/components/upload-type-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    name: null,
    type: null,
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    subscriptionManager: Ember.inject.service(),
    uploadOptions: Ember.computed.alias('subscriptionManager.uploadOptions'),
    modalContainerClass: Ember.computed('id', function containerClass() {
      return "".concat(Ember.get(this, 'componentCssClassName'), " custom-modal upload-type-selector");
    }),
    canSelect: Ember.computed('name', function shouldShowTypes() {
      var name = Ember.get(this, 'name') || '';
      return true; // name.trim().length > 0;
    }),
    actions: {
      close: function close() {
        this.sendAction('close');
      },
      select: function select(selection) {
        if (selection) {
          this.sendAction('typeSelected', '', selection);
        }
      }
    }
  });

  _exports.default = _default;
});