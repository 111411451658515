define("superfly/pods/application/components/upload-image-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dgq+sCqQ",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[4,\"each\",[[23,[\"files\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"class\",[28,[\"d-flex flex-row justify-content-between file-item \",[22,1,[\"selected\"]],\" \",[27,\"unless\",[[22,1,[\"exif\"]],\"error\"],null]]]],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"d-flex flex-row align-items-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[1,[27,\"if\",[[22,1,[\"exif\"]],\"image\",\"error\"],null],false],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"truncate\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"a\"],[11,\"class\",\"d-flex flex-row align-items-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"removeFile\",[22,1,[]]],null]]],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"markIcon\",[22,1,[]]],null]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/upload-image-list/template.hbs"
    }
  });

  _exports.default = _default;
});