define("superfly/helpers/numeral", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numeral = numeral;
  _exports.default = void 0;

  function numeral(params, hash) {
    var value = params[0];
    var format = Ember.get(hash, 'format');

    if (!value) {
      Ember.warn('No value passed to numeral helper', false, {
        id: 'numeral-helper.no-value'
      });
      return 0;
    }

    return (0, _numeral.default)(value).format(format);
  }

  var _default = Ember.Helper.helper(numeral);

  _exports.default = _default;
});