define("superfly/pods/frame/settings/integrations/components/integration-agx-connector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OBhgBup/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"logo\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/images/agx@2x.png\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"info\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"agX\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"helper\"],[9],[0,\"Automatically export your aerial data to agX\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"action\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"agx\",\"isCheckingConnection\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-block\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"agx\",\"isConnected\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-block\"],[9],[0,\"Disconnect\"],[3,\"action\",[[22,0,[]],\"disconnect\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-block\"],[9],[0,\"Connect\"],[3,\"action\",[[22,0,[]],\"connect\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/integrations/components/integration-agx-connector/template.hbs"
    }
  });

  _exports.default = _default;
});