define("superfly/models/measurement-volume", ["exports", "ember-data", "superfly/models/measurement"], function (_exports, _emberData, _measurement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _measurement.default.extend({
    ajax: Ember.inject.service(),
    color: attr('string', {
      defaultValue: 'red'
    }),
    volume: attr(),
    density: attr(),
    volumeType: attr()
  });

  _exports.default = _default;
});