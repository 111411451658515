define("superfly/pods/frame/settings/billing/components/payment-method-form-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JDf2A264",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal-dialog\",null,[[\"containerClass\"],[[23,[\"modalContainerClass\"]]]],{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"close\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-close\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"closeModal\"]],[10],[0,\"\\n\\n  \"],[7,\"h3\"],[11,\"class\",\"title\"],[9],[1,[21,\"buttonText\"],false],[10],[0,\"\\n  \"],[1,[27,\"payment-method-form\",null,[[\"triggerSubmit\",\"onSuccess\",\"onFailure\"],[[23,[\"triggerPaymentMethodSubmit\"]],[27,\"action\",[[22,0,[]],\"onSuccess\"],null],[27,\"action\",[[22,0,[]],\"onFailure\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"errorMsg\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"error-block\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[1,[21,\"errorMsg\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"submit-button btn btn-primary\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"material-spinner\",null,[[\"size\"],[\"xs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"buttonText\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[3,\"action\",[[22,0,[]],\"submit\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/billing/components/payment-method-form-modal/template.hbs"
    }
  });

  _exports.default = _default;
});