define("superfly/pods/auth/set-new-password/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ember-changeset", "ember-changeset-validations", "superfly/validations/set-new-password"], function (_exports, _unauthenticatedRouteMixin, _emberChangeset, _emberChangesetValidations, _setNewPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    token: null,
    changeSet: null,
    beforeModel: function beforeModel(transition) {
      Ember.set(this, 'token', transition.queryParams.token);
    },
    model: function model() {
      return {
        password: undefined,
        passwordConfirmation: undefined
      };
    },
    afterModel: function afterModel(model) {
      Ember.set(this, 'changeset', new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_setNewPassword.default), _setNewPassword.default));
    },
    setupController: function setupController(controller, model) {
      Ember.set(controller, 'changeset', Ember.get(this, 'changeset'));
      Ember.set(controller, 'token', Ember.get(this, 'token'));
    }
  });

  _exports.default = _default;
});