define("superfly/models/measurement-comment", ["exports", "ember-data", "L", "superfly/models/measurement"], function (_exports, _emberData, _L, _measurement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _measurement.default.extend({
    color: attr('string', {
      defaultValue: 'purple'
    }),
    layer: Ember.computed('layer', 'color', 'displayLabel', function () {
      var layer = this._super('layer');

      layer.options.icon = _L.default.divIcon({
        className: 'placeholder-class',
        // This was needed to remove the div icon class
        html: "\n      <div class=\"comment-marker\" style=\"height: inherit; width: inherit; background-color: ".concat(Ember.get(this, 'color'), "\">\n        <div class=\"comment-marker-label\">") + "".concat(Ember.get(this, 'displayLabel')) + "</div>\n      </div>",
        iconAnchor: [20, 20]
      });
      return layer;
    })
  });

  _exports.default = _default;
});