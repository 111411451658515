define("superfly/models/root-folder", ["exports", "ember-data", "superfly/models/folder"], function (_exports, _emberData, _folder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _folder.default.extend({
    company: belongsTo('company')
  });

  _exports.default = _default;
});