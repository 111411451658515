define("superfly/serializers/measurement", ["exports", "superfly/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(modelClass, resourceHash) {
      var type = Ember.get(resourceHash, 'type');
      var modelSubClass = modelClass;

      if (type) {
        modelSubClass = this.store.modelFor("measurement-".concat(type));
      }

      return this._super(modelSubClass, resourceHash);
    }
  });

  _exports.default = _default;
});