define("superfly/pods/application/components/upload-type-icon-survey/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L8+zJgJ0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M88.8,89.188A9.617,9.617,0,0,1,81.6,92H10.4a9.617,9.617,0,0,1-7.2-2.812A8.741,8.741,0,0,1,0,81.956V10.445A9.7,9.7,0,0,1,2.8,3.214,9.437,9.437,0,0,1,10.4,0H81.6a9.617,9.617,0,0,1,7.2,2.812A10.308,10.308,0,0,1,92,10.445V81.956A10.08,10.08,0,0,1,88.8,89.188ZM10.4,76.734H81.6L58.8,45.8,41.2,68.7,28,53.834Z\"],[11,\"transform\",\"translate(-20571 -12083)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/upload-type-icon-survey/template.hbs"
    }
  });

  _exports.default = _default;
});