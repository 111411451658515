define("superfly/pods/application/components/flight-planner/component", ["exports", "moment", "numeral", "superfly/utils/layer-area", "superfly/utils/layer-length", "superfly/utils/normalize-flight-type"], function (_exports, _moment, _numeral, _layerArea, _layerLength, _normalizeFlightType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uploadManager: Ember.inject.service(),
    agx: Ember.inject.service(),
    subscriptionManager: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    isVisible: false,
    mapTypeHelpVisible: false,
    units: 'meters',
    areaLayer: null,
    isNdviImagery: false,
    showErrors: false,
    mapTypeOptions: [{
      value: 'survey',
      label: 'Survey'
    }, {
      value: 'ndvi',
      label: 'Vegitation Index'
    }, {
      value: 'threeDimensional',
      label: '3D Model'
    }],
    selectedMapType: null,
    resetAreaBounds: null,
    flightName: Ember.computed.alias('collection.name'),
    flightNameErrorMessage: Ember.computed('flightName', 'showErrors', function () {
      var flightName = Ember.get(this, 'flightName');
      var showErrors = Ember.get(this, 'showErrors');
      return flightName || !showErrors ? '' : 'Flight name is required.';
    }),
    mapTypeErrorMessage: Ember.computed('collection.type', 'showErrors', function () {
      var mapType = Ember.get(this, 'collection.type');
      var showErrors = Ember.get(this, 'showErrors');
      return mapType || !showErrors ? '' : 'Map type is required.';
    }),
    collection: Ember.computed.alias('uploadManager.flightBeingPlanned'),
    filterType: Ember.computed.alias('collection.filterType'),
    flightDate: Ember.computed('collection.collectionDate', function () {
      var collectionDate = Ember.get(this, 'collection.collectionDate');
      return collectionDate.format('MMMM Do, YYYY');
    }),
    flightTime: Ember.computed('collection.collectionDate', function () {
      var collectionDate = Ember.get(this, 'collection.collectionDate');
      return collectionDate.format('h:mm a');
    }),
    displayTotalSize: Ember.computed('collection.collectionSize', function () {
      return (0, _numeral.default)(Ember.get(this, 'collection.collectionSize')).format('0.00b');
    }),
    canUpload: Ember.computed('flightName', 'collection.type', function canUpload() {
      var name = Ember.get(this, 'flightName');
      var mapType = Ember.get(this, 'collection.type');
      var numberOfImages = Ember.get(this, 'collection.numberOfImages');
      var remainingPhotos = Ember.get(this, 'collection.remainingPhotos');
      return remainingPhotos - numberOfImages > 0; // && name && mapType && name.trim().length > 0;
    }),
    isImageCountExceeded: Ember.computed.bool('imageCountExceededBy'),
    imageCountExceededBy: Ember.computed('collection.{numberOfFilesTotal,remainingPhotos}', function () {
      var max = Ember.get(this, 'collection.remainingPhotos');
      var count = Ember.get(this, 'collection.numberOfFilesTotal') || 0;
      return count >= max ? +count - +max : 0;
    }),
    modalContainerClass: Ember.computed('id', function containerClass() {
      return "".concat(Ember.get(this, 'componentCssClassName'), " custom-modal flight-planner-modal");
    }),
    mapTypeModalContainerClass: Ember.computed('id', function containerClass() {
      return "".concat(Ember.get(this, 'componentCssClassName'), " map-type-help-modal ember-modal-dialog modal-sm");
    }),
    destinationIsAgX: Ember.computed('agx.connections.[]', 'collection.folder.id', function destinationIsAgX() {
      var connections = Ember.get(this, 'agx.connections') || [];
      var folderId = Ember.get(this, 'collection.folder.id');
      return !!connections.findBy('botlink_folder', folderId);
    }),
    area: null,
    length: null,
    collectionNeedsPlanning: Ember.on('init', Ember.observer('collection', function () {
      var collection = Ember.get(this, 'collection');

      if (collection) {
        Ember.set(this, 'isVisible', true);
      } else {
        Ember.set(this, 'isVisible', false);
      }
    })),
    actions: {
      flashWarning: function flashWarning($element) {
        $element.velocity({
          backgroundColor: '#ffffdd'
        }, {
          duration: 50
        }).velocity({
          opacity: 0
        }, {
          duration: 250
        }).velocity({
          opacity: 1
        }, {
          duration: 250
        }).velocity({
          opacity: 0
        }, {
          duration: 250
        }).velocity({
          opacity: 1
        }, {
          duration: 250
        }).velocity({
          backgroundColor: '#ffffff'
        }, {
          duration: 1500
        });
      },
      resetBounds: function resetBounds() {
        var resetAreaBounds = Ember.get(this, 'resetAreaBounds');
        resetAreaBounds();
      },
      startUpload: function startUpload() {
        var canUpload = Ember.get(this, 'canUpload');
        Ember.set(this, 'showErrors', true);
        var flightNameErrorMessage = Ember.get(this, 'flightNameErrorMessage');
        var mapTypeErrorMessage = Ember.get(this, 'mapTypeErrorMessage');

        if (canUpload && !flightNameErrorMessage && !mapTypeErrorMessage) {
          if (Ember.get(this, 'isImageCountExceeded')) {
            this.send('flashWarning', Ember.$('.flight-planner-modal .upload-warning'));
            return;
          }

          Ember.set(this, 'collection.name', Ember.get(this, 'flightName'));
          Ember.set(this, 'collection.isDestinationAgx', Ember.get(this, 'destinationIsAgX'));
          Ember.get(this, 'uploadManager').finalizeCollectionPlan();
          var freeTrialEnd = Ember.get(this, 'subscriptionManager.session.currentCompany.freeTrialEnd');

          if (!freeTrialEnd) {
            // TODO: This should not be hardcoded the api should
            // return this or a websocket should update the freeTrialEnd end.
            freeTrialEnd = (0, _moment.default)().add(14, 'days');
            Ember.set(this, 'subscriptionManager.session.currentCompany.freeTrialEnd', freeTrialEnd.toDate());
          }

          Ember.get(this, 'eventBus').publish('site-left-flyout', 'uploader', 'open');
          this.send('closeModal');
        }
      },
      closeModal: function closeModal() {
        Ember.set(this, 'showErrors', false);
        Ember.set(this, 'isVisible', false);
      },
      areaChanged: function areaChanged(layer) {
        Ember.setProperties(this, {
          area: (0, _layerArea.default)(layer),
          length: +(0, _layerLength.default)(layer, true)
        });
        Ember.set(this, 'collection.geoJSON', layer.toGeoJSON());
      },
      unitsChanged: function unitsChanged(units) {
        Ember.set(this, 'units', units);
      },
      seasonSelected: function seasonSelected(season) {
        Ember.set(this, 'collection.season', season);
      },
      upgrade: function upgrade() {
        this.send('closeModal');
        Ember.get(this, 'routing.router').transitionTo('plans.select-plan');
      },
      openMapTypeHelp: function openMapTypeHelp() {
        Ember.set(this, 'mapTypeHelpVisible', true);
      },
      closeMapTypeHelp: function closeMapTypeHelp() {
        Ember.set(this, 'mapTypeHelpVisible', false);
      }
    }
  });

  _exports.default = _default;
});