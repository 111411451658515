define("superfly/pods/application/components/rename-object-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    object: null,
    newValue: null,
    error: null,
    isWaiting: false,
    hasError: Ember.computed.bool('error'),
    canSubmit: Ember.computed('newValue', function canSubmit() {
      var _Ember$getProperties = Ember.getProperties(this, ['object', 'newValue', 'isWaiting']),
          object = _Ember$getProperties.object,
          newValue = _Ember$getProperties.newValue,
          isWaiting = _Ember$getProperties.isWaiting;

      var oldValue = Ember.get(object, 'name');
      return !isWaiting && oldValue !== newValue && newValue.length > 3;
    }),
    containerClass: Ember.computed('id', function () {
      return 'modal-xs';
    }),
    willInsertElement: function willInsertElement() {
      Ember.set(this, 'newValue', Ember.get(this, 'object.displayName'));
    },
    didInsertElement: function didInsertElement() {
      this.$('input').select().focus();
    },
    actions: {
      save: function save() {
        var _this = this;

        var _Ember$getProperties2 = Ember.getProperties(this, ['notify', 'object', 'newValue', 'canSubmit']),
            notify = _Ember$getProperties2.notify,
            object = _Ember$getProperties2.object,
            newValue = _Ember$getProperties2.newValue,
            canSubmit = _Ember$getProperties2.canSubmit;

        if (canSubmit) {
          Ember.set(object, 'name', newValue);
          Ember.set(this, 'isWaiting', true);
          object.save().then(function () {
            Ember.set(_this, 'isWaiting', false);
            notify.success('Name changed successfully');

            _this.sendAction('close');
          }).catch(function (e) {
            Ember.set(_this, 'isWaiting', false);
            object.rollbackAttributes('name');
            notify.error('Could not save name change');
            Ember.set(_this, 'error', e);
          });
        }
      },
      cancel: function cancel() {
        this.sendAction('close');
      }
    }
  });

  _exports.default = _default;
});