define("superfly/serializers/company", ["exports", "superfly/serializers/application", "superfly/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      return this._super(typeClass, Ember.assign({}, hash, {
        links: {
          pendingPlans: "".concat(_environment.default.BOTLINK_SUBSCRIPTIONS_API_URL, "/subscriptions/organization/pendingPlans/").concat(hash.stripeCustomerId),
          paymentMethods: "".concat(_environment.default.BOTLINK_SUBSCRIPTIONS_API_URL, "/subscriptions/organization/").concat(hash.id, "/paymentSource"),
          subscriptions: "".concat(_environment.default.BOTLINK_SUBSCRIPTIONS_API_URL, "/subscriptions/organization/").concat(hash.stripeCustomerId),
          rootFolder: "".concat(_environment.default.BOTLINK_USERS_API_URL, "/folders/company/").concat(hash.id, "/root")
        }
      }));
    }
  });

  _exports.default = _default;
});