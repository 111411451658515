define("superfly/mixins/model-pollable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    shouldPoll: Ember.computed('isComplete', 'isProcessingFailure', 'isProcessingSuccess', 'isDestroying', 'isDestroyed', function shouldPoll() {
      var _Ember$getProperties = Ember.getProperties(this, ['isComplete', 'isProcessingFailure', 'isProcessingSuccess', 'isStitchFailed', 'isDestroying', 'isDestroyed']),
          isComplete = _Ember$getProperties.isComplete,
          isProcessingFailure = _Ember$getProperties.isProcessingFailure,
          isProcessingSuccess = _Ember$getProperties.isProcessingSuccess,
          isStitchFailed = _Ember$getProperties.isStitchFailed,
          isDestroying = _Ember$getProperties.isDestroying,
          isDestroyed = _Ember$getProperties.isDestroyed;

      return !isDestroying && !isDestroyed && isComplete && !(isProcessingFailure || isProcessingSuccess || isStitchFailed);
    }),
    onLoad: Ember.on('didLoad', function () {
      this.poll();
    }),
    poll: function poll() {
      var _this = this;

      if (Ember.get(this, 'shouldPoll')) {
        Ember.run.later(function () {
          _this.reload();

          _this.poll();
        }, 30000);
      }
    }
  });

  _exports.default = _default;
});