define("superfly/pods/frame/settings/units/components/mass-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Y3bUDw8",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"info\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Mass units\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"helper\"],[9],[0,\"Select a default unit for mass that you would like to use across the site.\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"action\"],[9],[0,\"\\n  \"],[1,[27,\"option-group\",null,[[\"value\",\"options\",\"onChange\"],[[23,[\"selectedMassUnit\"]],[23,[\"massUnitOptions\"]],[27,\"action\",[[22,0,[]],\"onSelectedMassUnitChange\"],null]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row col-12 mt-1\"],[9],[0,\"\\n    \"],[1,[27,\"form-text-input\",null,[[\"class\",\"value\",\"label\",\"placeholder\",\"disabled\",\"error\"],[\"col-6\",[23,[\"massUnitName\"]],\"Unit\",\"Unit\",[27,\"not\",[[27,\"eq\",[[23,[\"selectedMassUnit\"]],\"custom\"],null]],null],[23,[\"massUnitNameErrorMessage\"]]]]],false],[0,\"\\n\\n    \"],[1,[27,\"form-text-input\",null,[[\"class\",\"value\",\"label\",\"placeholder\",\"disabled\",\"error\"],[\"col-6\",[23,[\"massMultiplier\"]],\"Ratio to kg\",\"1 unit = X kg\",[27,\"not\",[[27,\"eq\",[[23,[\"selectedMassUnit\"]],\"custom\"],null]],null],[23,[\"massMultiplierErrorMessage\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row col-12 justify-content-end\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onSave\"],null]],[9],[0,\"Save\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/units/components/mass-selection/template.hbs"
    }
  });

  _exports.default = _default;
});