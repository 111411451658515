define("superfly/pods/application/components/flyout-uploader/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uploadManager: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    collectionsToDisplay: Ember.computed.alias('uploadManager.collection'),
    actions: {
      dismissUploadSet: function dismissUploadSet(collection) {
        var manager = Ember.get(this, 'uploadManager');
        manager.removeCollectionFromQueue(collection);
      },
      newUpload: function newUpload() {
        Ember.get(this, 'eventBus').publish('upload-collection-builder', 'start');
      }
    }
  });

  _exports.default = _default;
});