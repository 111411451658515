define("superfly/pods/application/components/upload-type-icon-terrain/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1MgWLeh7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"g\"],[11,\"transform\",\"translate(-21089 -12562)\"],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M5.7,11.321a61.81,61.81,0,0,1,7.633-3.214A51.663,51.663,0,0,1,20.966,6.5c2.41.4,5.223.8,7.633,2.41s5.223,2.009,7.633,2.41c2.41-.4,5.223-.8,7.633-2.41A28.006,28.006,0,0,1,51.5,6.5v6.83c-2.41.4-5.223.8-7.633,2.41a28.006,28.006,0,0,1-7.633,2.41c-2.41-.4-5.223-.8-7.633-2.41a28.006,28.006,0,0,0-7.633-2.41,17.409,17.409,0,0,0-7.633,1.607c-2.41,1.205-5.223,2.009-7.633,3.214Zm0,14.061a61.81,61.81,0,0,1,7.633-3.214,25.937,25.937,0,0,1,7.633-1.607c2.41.4,5.223.8,7.633,2.41a28.006,28.006,0,0,0,7.633,2.41c2.41-.4,5.223-.8,7.633-2.41a28.006,28.006,0,0,1,7.633-2.41v6.83c-2.41.4-5.223.8-7.633,2.41a28.006,28.006,0,0,1-7.633,2.41c-2.41-.4-5.223-.8-7.633-2.41a28.006,28.006,0,0,0-7.633-2.41A17.409,17.409,0,0,0,13.333,29C10.923,30.2,8.11,31.007,5.7,32.212Zm0,13.659a61.811,61.811,0,0,1,7.633-3.214,51.663,51.663,0,0,1,7.633-1.607c2.41.4,5.223.8,7.633,2.41a28.006,28.006,0,0,0,7.633,2.41c2.41-.4,5.223-.8,7.633-2.41a28.006,28.006,0,0,1,7.633-2.41v6.83c-2.41.4-5.223.8-7.633,2.41a28.006,28.006,0,0,1-7.633,2.41c-2.41-.4-5.223-.8-7.633-2.41a28.006,28.006,0,0,0-7.633-2.41,17.409,17.409,0,0,0-7.633,1.607c-2.41,1.205-5.223,2.009-7.633,3.214Z\"],[11,\"transform\",\"translate(1084.2 499.614)\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M88.384,3.616A13.553,13.553,0,0,1,92,11.651v68.7a10.114,10.114,0,0,1-3.616,8.035A14.751,14.751,0,0,1,80.349,92h-68.7a10.114,10.114,0,0,1-8.035-3.616C1.607,85.974,0,83.563,0,80.349v-68.7A10.114,10.114,0,0,1,3.616,3.616C6.026,1.607,8.437,0,11.651,0h68.7A10.114,10.114,0,0,1,88.384,3.616Zm-8.035,8.035h-68.7v68.7h68.7Z\"],[11,\"transform\",\"translate(1067 480)\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/upload-type-icon-terrain/template.hbs"
    }
  });

  _exports.default = _default;
});