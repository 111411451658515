define("superfly/components/option-group/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown-selector', 'd-flex', 'flex-row'],
    value: null,
    selected: null,
    options: [],
    computedOptions: Ember.computed('options', function canSubmit() {
      var options = Ember.get(this, 'options');
      return options;
    }),
    willInsertElement: function willInsertElement() {
      // Load selected value if one was defined
      var value = Ember.get(this, 'value');
      var options = Ember.get(this, 'computedOptions');
      var selected = (0, _lodash.find)(options, function (option) {
        return option.value === value;
      });
      Ember.set(this, 'selected', selected);
    },
    didInsertElement: function didInsertElement() {
      // Event listener for tool tips
      $('[data-toggle="tooltip"]').tooltip({
        animation: false,
        delay: {
          show: 300,
          hide: 100
        },
        template: '<div class="tooltip quickbar-tooltip" role="tooltip"><div class="tooltip-container animated fade-in-right" style="position:relative;"><div class="arrow" style=" left: -5px;"></div><div class="tooltip-inner"></div></div></div>'
      });
    },
    actions: {
      select: function select(option) {
        Ember.set(this, 'value', option.value);
        Ember.set(this, 'selected', option);
        this.onChange(option);
      }
    }
  });

  _exports.default = _default;
});