define("superfly/models/measurement-area", ["exports", "ember-data", "superfly/models/measurement", "superfly/utils/layer-length", "superfly/utils/layer-area"], function (_exports, _emberData, _measurement, _layerLength, _layerArea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var attr = _emberData.default.attr;

  var _default = _measurement.default.extend({
    color: attr('string', {
      defaultValue: '#FFA500'
    }),
    // CSS Orange
    volume: attr(),
    density: attr(),
    volumeType: attr(),

    /**
     * Calculates the area of a shape. This will only return a value
     * when the measurement type is 'area'.
     *
     * @returns {null|number} Area of shape in square meters
     */
    area: Ember.computed('layer', function area() {
      return (0, _layerArea.default)(this.get('layer')); // meters
    }),

    /**
     * Calculates the perimeter of a shape.
     *
     * @returns {null|number} Perimeter of shape in meters
     */
    perimeter: Ember.computed('layer', function () {
      return (0, _layerLength.default)(Ember.get(this, 'layer'), true);
    }),

    /**
     * Calculates the segment count of a shape.
     *
     * @returns {null|number} Number of segments of shape
     */
    segments: Ember.computed('layer', function segments() {
      var _ref;

      var layer = Ember.get(this, 'layer');

      var points = (_ref = []).concat.apply(_ref, _toConsumableArray(layer.getLatLngs()));

      return Ember.get(points, 'length');
    })
  });

  _exports.default = _default;
});