define("superfly/pods/shared/index/components/route-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    share: Ember.computed.alias('model.share')
  });

  _exports.default = _default;
});