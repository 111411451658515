define("superfly/pods/application/components/site-header-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cVuiDKqm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"class\",[28,[\"sidebar-toggle navbar-toggler \",[27,\"if\",[[23,[\"sidebar\",\"isVisible\"]],\"on\"],null]]]],[11,\"aria-label\",\"Toggle sidebar\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-bars\"],[11,\"aria-hidden\",\"true\"],[9],[10],[3,\"action\",[[22,0,[]],\"toggleSidebar\"]],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"frame\"],[[\"class\"],[\"navbar-brand mr-xs-0 d-md-none\"]],{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"src\",\"/assets/images/botlink_logo_small.png\"],[11,\"height\",\"40\"],[11,\"alt\",\"Botlink Logo\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"a\"],[11,\"class\",\"navbar-toggler\"],[12,\"href\",[28,[[27,\"href-to\",[\"frame.settings\"],null]]]],[11,\"aria-label\",\"Toggle settings\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-gear\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"collapse navbar-collapse\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"navbar-nav mr-auto\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"flight-specific-header\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/site-header-nav/template.hbs"
    }
  });

  _exports.default = _default;
});