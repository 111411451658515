define("superfly/pods/shared/locked/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Locked',
    beforeModel: function beforeModel(transition) {
      Ember.set(this, 'params', Ember.get(transition, 'params'));

      this._super(transition);
    },
    setupController: function setupController(controller, model) {
      Ember.setProperties(controller, {
        params: Ember.get(this, 'params')
      });

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});