define("superfly/pods/application/components/object-type-icon-survey/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vl7UPj8a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"g\"],[11,\"transform\",\"translate(-10629 -17601)\"],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"class\",\"a\"],[11,\"cx\",\"18\"],[11,\"cy\",\"18\"],[11,\"r\",\"18\"],[11,\"transform\",\"translate(22 703)\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"class\",\"b\"],[11,\"d\",\"M17.374,17.45a1.882,1.882,0,0,1-1.409.55H2.035a1.882,1.882,0,0,1-1.409-.55A1.71,1.71,0,0,1,0,16.035V2.044A1.9,1.9,0,0,1,.548.629,1.846,1.846,0,0,1,2.035,0h13.93a1.882,1.882,0,0,1,1.409.55A2.017,2.017,0,0,1,18,2.044V16.035A1.972,1.972,0,0,1,17.374,17.45ZM2.035,15.013h13.93L11.5,8.961l-3.443,4.48L5.478,10.533Z\"],[11,\"transform\",\"translate(31 712)\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/object-type-icon-survey/template.hbs"
    }
  });

  _exports.default = _default;
});