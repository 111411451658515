define("superfly/pods/application/components/object-type-icon-3d/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M+jt6kh5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"g\"],[11,\"transform\",\"translate(-8509 -13676)\"],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"class\",\"a\"],[11,\"cx\",\"18\"],[11,\"cy\",\"18\"],[11,\"r\",\"18\"],[11,\"transform\",\"translate(22 922)\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"class\",\"b\"],[11,\"d\",\"M42.6,14.4c0,.4-.2.8-.6.8l-8,4.4c0,.4-.2.4-.4.4a.735.735,0,0,1-.6-.2l-8-4.4c-.4-.2-.4-.4-.6-.8V5.4c0-.4.2-.8.6-.8L33,.2a1,1,0,0,1,1.2,0l8,4.4c.4.2.4.4.6.8v9Zm-16-.6,6,3.4V10.6l-6-3.4Zm7-11.6-6,3.4,6,3.4,6-3.4Zm7,11.6V7.2l-6,3.4v6.8Z\"],[11,\"transform\",\"translate(6.599 930)\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/object-type-icon-3d/template.hbs"
    }
  });

  _exports.default = _default;
});