define("superfly/pods/frame/settings/profile/components/user-timezone/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    continents: ['Africa', 'Asia', 'America', 'Europe', 'Pacific'],
    timezones: Ember.computed('id', function () {
      var c = Ember.get(this, 'continents');
      return _moment.default.tz.names().filter(function (name) {
        return name.indexOf('/') >= 0;
      }).filter(function (name) {
        return c.indexOf(name.split('/')[0]) !== -1;
      });
    })
  });

  _exports.default = _default;
});