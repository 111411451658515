define("superfly/pods/frame/products/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hbzos/uR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"application/components/site-sidebar\",null,[[\"classNames\"],[\"products-nav\"]],{\"statements\":[[0,\"  \"],[1,[27,\"frame/products/components/products-nav\",null,[[\"products\",\"sites\",\"lastFlights\"],[[23,[\"model\",\"products\"]],[23,[\"model\",\"sites\"]],[23,[\"model\",\"lastFlights\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"application/components/site-content\",null,null,{\"statements\":[[0,\"  \"],[1,[21,\"liquid-outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/products/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});