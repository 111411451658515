define("superfly/pods/frame/sites/site/components/delete-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nfRGVqSQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal-dialog\",null,[[\"containerClass\"],[[23,[\"containerClass\"]]]],{\"statements\":[[0,\"  \"],[7,\"header\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"Are you sure you want to delete?\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"section\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"warning-banner\"],[9],[0,\"Negative consequences can occur if you don't read this!\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"This action \"],[7,\"strong\"],[9],[0,\"CANNOT\"],[10],[0,\" be undone. This will \"],[7,\"strong\"],[9],[0,\"PERMANENTLY\"],[10],[0,\" delete the site \\\"\"],[1,[23,[\"site\",\"name\"]],false],[0,\"\\\" and linked product measurements.\"],[10],[0,\"\\n    \"],[1,[27,\"form-text-input\",null,[[\"label\",\"placeholder\",\"value\"],[\"Please type DELETE to confirm\",\"DELETE\",[23,[\"deleteConfirmed\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"footer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"waiting\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"material-spinner\",null,[[\"size\"],[\"xs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[9],[0,\"cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n      \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-danger \",[27,\"unless\",[[23,[\"canSubmit\"]],\"disabled\"],null]]]],[9],[0,\"delete\"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/site/components/delete-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});