define("superfly/pods/not-found/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7VkQynsc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content-band\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"404\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"Page Not Found\"],[10],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[28,[[27,\"href-to\",[\"frame.dashboard\"],null]]]],[11,\"class\",\"mt-4\"],[9],[0,\"Return to dashboard\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/not-found/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});