define("superfly/pods/application/components/site-left-flyout/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['flyout', 'left-flyout'],
    eventBus: Ember.inject.service(),
    activeComponent: null,
    didInsertElement: function didInsertElement() {
      Ember.get(this, 'eventBus').subscribe('site-left-flyout', this, this.consumeEvent);
    },
    consumeEvent: function consumeEvent(component, action) {
      Ember.set(this, 'activeComponent', "application/components/flyout-".concat(component));

      if (action === 'open') {
        this.show();
      }

      if (action === 'close') {
        this.hide();
      }
    },
    show: function show() {
      this.$().addClass('d-block');
    },
    hide: function hide() {
      Ember.set(this, 'activeComponent', null);
      this.$().removeClass('d-block');
    },
    actions: {
      close: function close() {
        this.hide();
      }
    }
  });

  _exports.default = _default;
});