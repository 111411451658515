define("superfly/pods/frame/dashboard/components/map-toolbar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isActive:active'],
    router: Ember.inject.service(),
    measurements: Ember.inject.service(),
    subscriptionManager: Ember.inject.service(),
    mainMap: Ember.inject.service(),
    flight: Ember.computed.alias('measurements.flight'),
    isActive: Ember.computed.alias('measurements.isActionActive'),
    isInitialized: Ember.computed.alias('measurements.isInitialized'),
    isVisible: Ember.computed('router.currentRouteName', 'isInitialized', 'mainMap.sketchfabActive', function isVisible() {
      var routeName = Ember.get(this, 'router.currentRouteName');
      return routeName.indexOf('frame.dashboard.flights.flight') !== -1 && Ember.get(this, 'isInitialized') && !Ember.get(this, 'mainMap.sketchfabActive');
    })
  });

  _exports.default = _default;
});