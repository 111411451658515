define("superfly/pods/frame/settings/account/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ruK8/777",
    "block": "{\"symbols\":[],\"statements\":[[4,\"frame/settings/components/settings-section\",null,[[\"title\"],[\"Map style\"]],{\"statements\":[[0,\"  \"],[1,[21,\"frame/settings/account/components/mapstyle-selector\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/account/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});