define("superfly/pods/frame/dashboard/folders/folder/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    mainMap: Ember.inject.service(),
    ajax: Ember.inject.service(),
    navigationManager: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return Ember.get(model, 'folder.name') || 'Flights';
    },
    model: function model(params) {
      var folderId = params.folderId;
      return Ember.RSVP.hash({
        folder: this.store.findRecord('folder', folderId, {
          reload: true
        })
      });
    },
    afterModel: function afterModel(model) {
      var folder = Ember.get(model, 'folder');
      var children = Ember.get(folder, 'folders');
      var parent = Ember.get(folder, 'folder');
      var flights = Ember.get(folder, 'flights');
      return Ember.RSVP.all([children, parent, flights]);
    },
    setupController: function setupController(controller, model) {
      var folder = model.folder;
      Ember.set(this, 'navigationManager.target', folder);
      Ember.setProperties(controller, {
        folder: folder
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        Ember.set(this, 'navigationManager.target', null);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this._removeAllFlightMarkers();
      }
    },
    _removeAllFlightMarkers: function _removeAllFlightMarkers() {
      var mapService = Ember.get(this, 'mainMap');
      mapService.removeAllFlightMarkers();
    }
  });

  _exports.default = _default;
});