define("superfly/pods/application/components/action-type-icon-color/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T7uQzVXK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M13.672,2.1A6.746,6.746,0,0,1,16,7.148a4.368,4.368,0,0,1-4.459,4.393H10a1.359,1.359,0,0,0-.951.426,1.21,1.21,0,0,0-.361.918,1.187,1.187,0,0,0,.328.885,1.265,1.265,0,0,1,.328.918,1.428,1.428,0,0,1-.361.951A1.191,1.191,0,0,1,8.033,16,8,8,0,1,1,8,0,8.611,8.611,0,0,1,13.672,2.1ZM4.033,7.639a1.236,1.236,0,0,0,.361-.951,1.312,1.312,0,0,0-.361-.951,1.312,1.312,0,0,0-.951-.361,1.368,1.368,0,0,0-.951.361,1.236,1.236,0,0,0-.361.951,1.312,1.312,0,0,0,.361.951A1.236,1.236,0,0,0,3.082,8,1.312,1.312,0,0,0,4.033,7.639ZM6.721,4.033a1.236,1.236,0,0,0,.361-.951,1.368,1.368,0,0,0-.361-.951A1.191,1.191,0,0,0,5.77,1.77a1.368,1.368,0,0,0-.951.361,1.191,1.191,0,0,0-.361.951A1.343,1.343,0,0,0,5.77,4.459,1.425,1.425,0,0,0,6.721,4.033Zm4.459,0a1.236,1.236,0,0,0,.361-.951,1.368,1.368,0,0,0-.361-.951,1.191,1.191,0,0,0-.951-.361,1.368,1.368,0,0,0-.951.361,1.191,1.191,0,0,0-.361.951,1.368,1.368,0,0,0,.361.951,1.346,1.346,0,0,0,.918.426A1.507,1.507,0,0,0,11.18,4.033Zm2.656,3.607a1.236,1.236,0,0,0,.361-.951,1.312,1.312,0,0,0-.361-.951,1.236,1.236,0,0,0-.951-.361,1.368,1.368,0,0,0-.951.361,1.236,1.236,0,0,0-.361.951,1.312,1.312,0,0,0,.361.951A1.21,1.21,0,0,0,12.852,8,1.451,1.451,0,0,0,13.836,7.639Z\"],[11,\"transform\",\"translate(-13318 -5489)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/action-type-icon-color/template.hbs"
    }
  });

  _exports.default = _default;
});