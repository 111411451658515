define("superfly/components/payment-method-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bYgnvXXJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"paymentMethod\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"payment-info mb-4\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[1,[23,[\"paymentMethod\",\"brand\"]],false],[10],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"**** **** **** \"],[1,[23,[\"paymentMethod\",\"last4\"]],false],[10],[0,\"\\n    \"],[7,\"h4\"],[9],[1,[27,\"moment-format\",[[23,[\"paymentMethod\",\"paymentExp\"]],\"MMM YYYY\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"payment-failure-container\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"paymentMethodFailureReason\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[9],[0,\"Payment failed. Please update your card to continue using Botlink services.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"UPDATE CARD\"],[3,\"action\",[[22,0,[]],\"openModal\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"ADD CARD\"],[3,\"action\",[[22,0,[]],\"openModal\"]],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"showModal\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"frame/settings/billing/components/payment-method-form-modal\",null,[[\"onSuccess\",\"onFailure\",\"closeModal\"],[[27,\"action\",[[22,0,[]],\"onPaymentMethodSuccess\"],null],[27,\"action\",[[22,0,[]],\"onPaymentMethodFailure\"],null],[27,\"action\",[[22,0,[]],\"closeModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/components/payment-method-info/template.hbs"
    }
  });

  _exports.default = _default;
});