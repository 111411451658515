define("superfly/templates/components/object-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+N/W7Z/s",
    "block": "{\"symbols\":[\"obj\",\"&default\"],\"statements\":[[4,\"draggable-object-target\",null,[[\"action\"],[[27,\"action\",[[22,0,[]],\"handleObjectDropped\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"object-bin-title\"],[9],[1,[21,\"name\"],false],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[4,\"draggable-object\",null,[[\"action\",\"content\"],[\"handleObjectDragged\",[22,1,[]]]],{\"statements\":[[0,\"      \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/templates/components/object-bin.hbs"
    }
  });

  _exports.default = _default;
});