define("superfly/pods/frame/settings/billing/components/invoice-history-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "po+b56il",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"invoice\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"name\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"title\"],[9],[0,\"March 2017\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"title light amount\"],[9],[0,\"$150.00\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"download\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"invisible\"],[9],[0,\"PDF\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"invoice\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"name\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"title\"],[9],[0,\"March 2017\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"title light amount\"],[9],[0,\"$150.00\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"download\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"invisible\"],[9],[0,\"PDF\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"invoice\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"name\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"title\"],[9],[0,\"March 2017\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"title light amount\"],[9],[0,\"$150.00\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"download\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"invisible\"],[9],[0,\"PDF\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/billing/components/invoice-history-section/template.hbs"
    }
  });

  _exports.default = _default;
});