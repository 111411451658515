define("superfly/services/session", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service for managing a user's session.
   *
   * Custom implementation of the `session` service supplied by
   * [ember-simple-auth](http://ember-simple-auth.com/api/classes/SessionService.html).
   * The current user and his company are looked up and stored here for future use.
   * The user's auth token is also stored in the `token` property for easy access.
   *
   * @module
   * @augments ember-simple-auth/services/session
   */
  var _default = _session.default.extend({
    intercom: Ember.inject.service(),

    /**
     * The current user's ID.
     *
     * @type {Number}
     * @default undefined
     */
    currentUserID: undefined,

    /**
     * The current user's auth token.
     *
     * @type {String}
     * @default undefined
     */
    accessToken: undefined,
    refreshToken: undefined,

    /**
     * The user record for the currently-signed-in user.
     *
     * @type {DS.Model}
     * @default undefined
     */
    currentUser: undefined,

    /**
     * The company record belonging to the currently-signed-in user.
     *
     * @type {DS.Model}
     * @default undefined
     */
    currentCompany: undefined,

    /**
     * The injected `DS.Store` service.
     *
     * @type {Ember.Service}
     */
    store: Ember.inject.service(),

    /**
     * `Ember.Observer` that reacts to changes in `session.content.authenticated`.
     *
     * Takes the items ESA stores in session data (on authentication) and sets
     * their values to `currentUserID` and `token` as top-level properties, for
     * convenience.
     *
     * @function
     * @returns {undefined}
     */
    setTokenAndCurrentUserId: Ember.observer('session.content.authenticated', function () {
      var _Ember$get = Ember.get(this, 'session.content.authenticated'),
          accessToken = _Ember$get.accessToken,
          refreshToken = _Ember$get.refreshToken,
          currentUserID = _Ember$get.currentUserID;

      Ember.setProperties(this, {
        accessToken: accessToken,
        refreshToken: refreshToken,
        currentUserID: currentUserID
      });
    }),
    onAuthenticatedSession: Ember.on('init', Ember.observer('currentUser', 'accessToken', function () {
      var user = Ember.get(this, 'currentUser');

      if (user) {
        var intercom = Ember.get(this, 'intercom');
        Ember.set(intercom, 'user', {
          name: Ember.get(this, 'currentUser.fullName'),
          email: Ember.get(this, 'currentUser.email'),
          createdAt: Ember.get(this, 'currentUser.createdAt')
        });
      }
    })),
    customRequestHeaders: Ember.computed('accessToken', function () {
      var authorization = Ember.get(this, 'accessToken');
      return {
        authorization: authorization
      };
    }),

    /**
     * Sets the `currentUser` and `currentCompany` properties based on `currentUserID`.
     *
     * @function
     * @returns {null}
     */
    setCurrentUserAndCompany: function setCurrentUserAndCompany() {
      var _this = this;

      return this._getCurrentUser().then(function (user) {
        return _this._getUserCompany(user).then(function (company) {
          Ember.set(_this, 'currentUser', user);
          Ember.set(_this, 'currentCompany', company);
          return null;
        });
      });
    },

    /**
     * Gets the user record for `currentUserID`.
     *
     * @function
     * @private
     * @returns {DS.Model}
     */
    _getCurrentUser: function _getCurrentUser() {
      var _this2 = this;

      var userId = Ember.get(this, 'currentUserID');
      var store = Ember.get(this, 'store');
      return store.findRecord('user', userId).then(function (user) {
        return user;
      }).catch(function () {
        _this2.invalidate();
      });
    },

    /**
     * Gets the first company record belonging to `currentUser`.
     *
     * @function
     * @private
     * @returns {DS.Model}
     */
    _getUserCompany: function _getUserCompany(user) {
      var _this3 = this;

      return Ember.get(user, 'company').then(function (company) {
        return company;
      }).catch(function () {
        _this3.invalidate();
      });
    }
  });

  _exports.default = _default;
});