define("superfly/pods/application/components/action-type-icon-delete/component", ["exports", "superfly/components/base-svg/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    viewBox: '-13074.729 -5489.2 10.873 14'
  });

  _exports.default = _default;
});