define("superfly/initializers/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var PAGE_VIEW = 'PageView';

  function initialize() {
    Ember.Router.reopen({
      facebookPixel: Ember.inject.service(),
      facebookTrackPageView: Ember.on('didTransition', function () {
        this.get('facebookPixel').track(PAGE_VIEW);
      })
    });
  }

  var _default = {
    name: 'router',
    initialize: initialize
  };
  _exports.default = _default;
});