define("superfly/pods/frame/invoice/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    subscriptionManager: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      var pendingPlans = Ember.get(this, 'subscriptionManager.session.currentCompany.pendingPlans').toArray();

      if (pendingPlans.length <= 0) {
        this.transitionTo('frame.settings.billing');
      }
    }
  });

  _exports.default = _default;
});