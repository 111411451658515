define("superfly/pods/application/components/sketchfab-viewer/component", ["exports", "sketchfab"], function (_exports, _sketchfab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    allowfullscreen: true,
    mozallowfullscreen: true,
    webkitallowfullscreen: true,
    mainMap: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var iframe = this.$('iframe')[0];
      var version = '1.0.0';
      var urlid = Ember.get(this, 'mainMap.flightSketchfabViewable.sketchfabUid');
      var client = new _sketchfab.default(version, iframe);
      client.init(urlid, {
        autospin: 0.01,
        autostart: 1,
        camera: 0,
        preload: 1,
        ui_vr: 0,
        ui_stop: 0,
        ui_infos: 0,
        ui_annotations: 0,
        ui_animations: 0,
        ui_help: 0,
        ui_fullscreen: 0,
        transparent: 1,
        success: function success(api) {
          api.start();
        },
        error: function error() {}
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        var map = Ember.get(_this, 'mainMap');
        Ember.get(_this, 'router').on('willTransition', function () {
          Ember.set(map, 'flightSketchfabViewable', false);
        });
      });
    },
    ready: function ready() {}
  });

  _exports.default = _default;
});