define("superfly/pods/application/components/site-header-banner/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    subscriptionManager: Ember.inject.service(),
    remainingDaysOnTrial: Ember.computed('subscriptionManager.session.currentCompany.freeTrialEnd', function remainingDaysOnTrial() {
      var freeTrialEnd = Ember.get(this, 'subscriptionManager.session.currentCompany.freeTrialEnd');
      var trialEndedAt = (0, _moment.default)(freeTrialEnd);
      var remainingDaysOnTrial = trialEndedAt.diff((0, _moment.default)(), 'days');
      return remainingDaysOnTrial;
    }),
    hasHadFreeTrail: Ember.computed('remainingDaysOnTrial', function hasHadFreeTrail() {
      var remainingDaysOnTrial = Ember.get(this, 'remainingDaysOnTrial');
      var stitchingPlan = Ember.get(this, 'subscriptionManager.session.currentCompany.stitchingPlan');
      return remainingDaysOnTrial <= 0 || stitchingPlan.photoLimit > 0 && stitchingPlan.name != 'Free Trial';
    }),
    hasPendingPlan: Ember.computed('subscriptionManager.session.currentCompany.pendingPlan', function hasPendingPlan() {
      var pendingPlans = Ember.get(this, 'subscriptionManager.session.currentCompany.pendingPlans').toArray();
      return pendingPlans.length > 0;
    }),
    pastDue: Ember.computed('subscriptionManager.session.currentCompany.stitchingPlan', function pastDue() {
      var stitchingPlan = Ember.get(this, 'subscriptionManager.session.currentCompany.stitchingPlan');
      return stitchingPlan.status === 'past_due';
    })
  });

  _exports.default = _default;
});