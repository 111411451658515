define("superfly/pods/frame/settings/units/components/distance-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+rhz8RJl",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"info\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Distance units\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"helper\"],[9],[0,\"Select a default unit for distance that you would like to use across the site.\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"action\"],[9],[0,\"\\n  \"],[1,[27,\"option-group\",null,[[\"value\",\"options\",\"onChange\"],[[23,[\"selectedDistanceUnit\"]],[23,[\"distanceUnitOptions\"]],[27,\"action\",[[22,0,[]],\"onSelectedDistanceUnitChange\"],null]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row col-12 mt-1\"],[9],[0,\"\\n    \"],[1,[27,\"form-text-input\",null,[[\"class\",\"value\",\"label\",\"placeholder\",\"disabled\",\"error\"],[\"col-6\",[23,[\"distanceUnitName\"]],\"Unit\",\"Unit\",[27,\"not\",[[27,\"eq\",[[23,[\"selectedDistanceUnit\"]],\"custom\"],null]],null],[23,[\"distanceUnitNameErrorMessage\"]]]]],false],[0,\"\\n\\n    \"],[1,[27,\"form-text-input\",null,[[\"class\",\"value\",\"label\",\"placeholder\",\"disabled\",\"error\"],[\"col-6\",[23,[\"distanceMultiplier\"]],\"Ratio to m\",\"1 unit = X m\",[27,\"not\",[[27,\"eq\",[[23,[\"selectedDistanceUnit\"]],\"custom\"],null]],null],[23,[\"distanceMultiplierErrorMessage\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row col-12 justify-content-end\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onSave\"],null]],[9],[0,\"Save\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/units/components/distance-selection/template.hbs"
    }
  });

  _exports.default = _default;
});