define("superfly/pods/frame/dashboard/components/upload-dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aVxTDPlB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"dropzone\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"loadingImages\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"instructions\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        Please wait...\\n      \"],[10],[0,\"\\n\\n      \"],[1,[27,\"material-spinner\",null,[[\"size\",\"color\"],[\"lg\",\"white\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"cancel\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-close\"],[11,\"aria-hidden\",\"true\"],[9],[10],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"instructions\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"icons\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"photo\"],[10],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"photo\"],[10],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"material-icons bright\"],[9],[0,\"cloud_upload\"],[10],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"photo\"],[10],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"photo\"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"Drag and drop\"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"a folder to upload to:\"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[1,[21,\"folderDisplayName\"],false],[10],[0,\"\\n\\n      \"],[7,\"p\"],[11,\"class\",\"choice-break\"],[9],[0,\"or\"],[10],[0,\"\\n\\n      \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"application/components/upload-browser-trigger\",null,[[\"folder\",\"filesFound\"],[[23,[\"folder\"]],[27,\"action\",[[22,0,[]],\"filesFound\"],null]]],{\"statements\":[[0,\"          \"],[7,\"a\"],[9],[0,\"select a folder from your computer\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/dashboard/components/upload-dropzone/template.hbs"
    }
  });

  _exports.default = _default;
});