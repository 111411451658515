define("superfly/pods/frame/dashboard/components/measurement-toolbar-comment/component", ["exports", "superfly/mixins/map-drawable"], function (_exports, _mapDrawable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mapDrawable.default, {
    classNames: ['comment', 'tool'],
    type: 'comment',
    isActive: Ember.computed.alias('measurements.isCommentActive')
  });

  _exports.default = _default;
});