define("superfly/pods/frame/dashboard/flights/flight/components/properties-viewable-distance/component", ["exports", "convert-units", "lodash", "superfly/mixins/map-editable", "superfly/utils/convert-units"], function (_exports, _convertUnits, _lodash, _mapEditable, _convertUnits2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mapEditable.default, {
    classNames: ['distance-object', 'properties-viewable', 'flight-properties-viewable'],
    classNameBindings: ['measurement.color', 'isEditing:editing'],
    measurement: null,
    preferredUnitsSelected: Ember.computed('units', function () {
      var units = Ember.get(this, 'units');
      return units === 'preferred units';
    }),
    isMetric: Ember.computed('units', function () {
      var units = Ember.get(this, 'units');
      var isMetric = units.includes('meters') || units === 'hectares';
      return isMetric;
    }),
    distanceUnitLabel: Ember.computed('units', function () {
      var convertUtil = _convertUnits2.default.create({
        container: Ember.getOwner(this)
      });

      var preferredUnitsSelected = Ember.get(this, 'preferredUnitsSelected');

      if (preferredUnitsSelected) {
        var distanceUnitName = convertUtil.getSetting('DistanceUnit').value.distanceUnitName;
        return distanceUnitName;
      } else {
        var units = Ember.get(this, 'units');
        var unitString = units;

        switch (units) {
          case 'meters':
            unitString = 'm';
            break;

          case 'hectares':
            unitString = 'm';
            break;

          case 'feet':
            unitString = 'ft';
            break;

          case 'yards':
            unitString = 'yd';
            break;

          case 'acres':
            unitString = 'yd';
            break;

          case 'miles':
            unitString = 'mi';
            break;

          case 'kilometers':
            unitString = 'km';
            break;
        }

        return unitString;
      }
    }),
    distanceConvertedToUnits: Ember.computed('units', 'measurement.length', function () {
      var distance = Ember.get(this, 'measurement.length');
      var preferredUnitsSelected = Ember.get(this, 'preferredUnitsSelected');
      var convertedDistance;

      if (preferredUnitsSelected) {
        var convertUtil = _convertUnits2.default.create({
          container: Ember.getOwner(this)
        });

        convertedDistance = convertUtil.convertDistanceToCustomUnit(distance);
      } else {
        var units = Ember.get(this, 'units');
        var unitString = units;

        switch (units) {
          case 'meters':
            unitString = 'm';
            break;

          case 'hectares':
            unitString = 'm';
            break;

          case 'feet':
            unitString = 'ft';
            break;

          case 'yards':
            unitString = 'yd';
            break;

          case 'acres':
            unitString = 'yd';
            break;

          case 'miles':
            unitString = 'mi';
            break;

          case 'kilometers':
            unitString = 'km';
            break;
        }

        convertedDistance = (0, _convertUnits.default)(distance).from('m').to(unitString);
      }

      return (0, _lodash.round)(convertedDistance, 2);
    })
  });

  _exports.default = _default;
});