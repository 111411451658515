define("superfly/pods/frame/settings/profile/components/user-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GMouaZvM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"helper\"],[9],[0,\"Your primary email address is the email where all Botlink notifications will be sent\"],[10],[0,\"\\n\\n\"],[7,\"h4\"],[9],[0,\"Personal email\"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[7,\"span\"],[11,\"class\",\"email\"],[9],[1,[23,[\"session\",\"currentUser\",\"email\"]],false],[10],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/profile/components/user-email/template.hbs"
    }
  });

  _exports.default = _default;
});