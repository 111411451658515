define("superfly/pods/frame/sites/site/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "60Dz7yjq",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"frame/sites/site/components/route-component\",null,[[\"site\"],[[23,[\"site\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/site/template.hbs"
    }
  });

  _exports.default = _default;
});