define("superfly/serializers/user", ["exports", "superfly/serializers/application", "superfly/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      firstName: 'givenName',
      lastName: 'surname'
    },
    normalize: function normalize(typeClass, hash) {
      return this._super(typeClass, Ember.assign({}, hash, {
        links: {
          companies: "".concat(_environment.default.BOTLINK_USERS_API_URL, "/users/").concat(hash.id, "/companies")
        }
      }));
    }
  });

  _exports.default = _default;
});