define("superfly/models/billing-cycle", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    endDate: attr('date'),
    invoiceClosed: attr('boolean'),
    invoicePaid: attr('boolean'),
    invoicePaymentFailed: attr('boolean'),
    invoicePaymentFailureReason: attr('string'),
    invoicePaymentLastAttempt: attr('date'),
    stitchesUsed: attr('number'),
    stripeInvoiceId: attr('string'),
    startDate: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    deletedAt: attr('date'),
    company: belongsTo('company'),
    subscription: belongsTo('subscription')
  });

  _exports.default = _default;
});