define("superfly/models/shared-overlay", ["exports", "ember-data", "superfly/models/overlay"], function (_exports, _emberData, _overlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _overlay.default.extend({
    flight: belongsTo('shared-flight', {
      inverse: 'overlays'
    })
  });

  _exports.default = _default;
});