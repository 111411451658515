define("superfly/pods/auth/signup/route", ["exports", "superfly/mixins/unathenticated-route"], function (_exports, _unathenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unathenticatedRoute.default, {
    titleToken: 'Sign Up',
    facebookPixel: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      Ember.get(this, 'facebookPixel').track('ViewContent');
      Ember.set(this, 'appBeingUsed', transition.queryParams.app);
      Ember.set(this, 'platformBeingUsed', transition.queryParams.platform);
      Ember.set(this, 'deviceString', transition.queryParams.deviceString);
      Ember.set(this, 'appVer', transition.queryParams.appVer);
    },
    model: function model(params) {
      Ember.set(this, 'params', params);
    },
    setupController: function setupController(controller) {
      Ember.set(controller, 'params', Ember.get(this, 'params'));
      Ember.set(controller, 'appBeingUsed', Ember.get(this, 'appBeingUsed'));
      Ember.set(controller, 'platformBeingUsed', Ember.get(this, 'platformBeingUsed'));
      Ember.set(controller, 'deviceString', Ember.get(this, 'deviceString'));
      Ember.set(controller, 'appVer', Ember.get(this, 'appVer'));
    }
  });

  _exports.default = _default;
});