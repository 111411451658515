define("superfly/pods/frame/dashboard/flights/flight/components/overlay-properties-dsm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GTPVFOoJ",
    "block": "{\"symbols\":[\"value\",\"color\"],\"statements\":[[7,\"header\"],[9],[0,\"Legend\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"hasDsmRangeData\"]]],null,{\"statements\":[[0,\"  \"],[7,\"svg\"],[11,\"class\",\"gradient\"],[11,\"version\",\"1.1\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n    \"],[7,\"defs\"],[9],[0,\"\\n      \"],[7,\"linearGradient\"],[11,\"id\",\"dsm-gradient\"],[11,\"x1\",\"0\"],[11,\"x2\",\"1\"],[11,\"y1\",\"0\"],[11,\"y2\",\"0\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"colors\"]]],null,{\"statements\":[[0,\"          \"],[7,\"stop\"],[12,\"offset\",[28,[[22,2,[\"startOffset\"]],\"%\"]]],[12,\"stop-color\",[28,[\"rgba(\",[22,2,[\"r\"]],\", \",[22,2,[\"g\"]],\", \",[22,2,[\"b\"]],\", 1)\"]]],[9],[10],[0,\"\\n          \"],[7,\"stop\"],[12,\"offset\",[28,[[22,2,[\"stopOffset\"]],\"%\"]]],[12,\"stop-color\",[28,[\"rgba(\",[22,2,[\"r\"]],\", \",[22,2,[\"g\"]],\", \",[22,2,[\"b\"]],\", 1)\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"rect\"],[11,\"x\",\"0\"],[11,\"y\",\"0\"],[11,\"height\",\"100%\"],[11,\"width\",\"100%\"],[11,\"fill\",\"url(#dsm-gradient)\"],[9],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"legend\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"displayValues\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"tick-group\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"tick\"],[9],[0,\"|\"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[1,[27,\"numeral\",[[22,1,[]]],[[\"format\"],[\"000\"]]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"no-data\"],[9],[0,\"No DSM data available\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/dashboard/flights/flight/components/overlay-properties-dsm/template.hbs"
    }
  });

  _exports.default = _default;
});