define("superfly/pods/application/components/tool-type-icon-area/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'svg',
    attributeBindings: ['xmlns', 'viewBox'],
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '-13028.5 -5687.5 20 20'
  });

  _exports.default = _default;
});