define("superfly/services/breadcrumb-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    routing: Ember.inject.service('-routing'),
    navigationManager: Ember.inject.service(),
    routeTitleMap: {
      'settings': 'Settings',
      'settings.index': 'Settings',
      'settings.billing': 'Settings',
      'settings.integrations': 'Settings',
      'settings.profile': 'Settings',
      'settings.account': 'Settings'
    },
    target: Ember.computed.alias('navigationManager.target'),
    isDirectoryRoot: Ember.computed.equal('flattenedRouteName', 'dashboard.folders'),
    isDirectoryRoute: Ember.computed('flattenedRouteName', function () {
      return Ember.get(this, 'flattenedRouteName').indexOf('dashboard.folders') !== -1;
    }),
    isFlightViewRoute: Ember.computed('flattenedRouteName', function () {
      return Ember.get(this, 'flattenedRouteName').indexOf('dashboard.flights') !== -1;
    }),
    shouldUseFileExplorerBreadcrumbs: Ember.computed('isDirectoryRoute', 'isDirectoryRoot', 'isFlightViewRoute', function () {
      var _Ember$getProperties = Ember.getProperties(this, ['isDirectoryRoute', 'isDirectoryRoot', 'isFlightViewRoute']),
          isDirectoryRoute = _Ember$getProperties.isDirectoryRoute,
          isDirectoryRoot = _Ember$getProperties.isDirectoryRoot,
          isFlightViewRoute = _Ember$getProperties.isFlightViewRoute;

      return !isDirectoryRoot && isDirectoryRoute || isFlightViewRoute;
    }),
    currentRouteCrumb: Ember.computed('flattenedRouteName', function () {
      var route = Ember.get(this, 'flattenedRouteName');
      var map = Ember.get(this, 'routeTitleMap');
      return map[route];
    }),
    flattenedRouteName: Ember.computed('routing.currentRouteName', function () {
      return (Ember.get(this, 'routing.currentRouteName') || '').replace('.loading', '').replace('.index', '');
    }),
    breadcrumbComponent: Ember.computed('shouldUseFileExplorerBreadcrumbs', function () {
      var shouldUseFileExplorer = Ember.get(this, 'shouldUseFileExplorerBreadcrumbs');
      var name = shouldUseFileExplorer ? 'file-explorer-breadcrumbs' : 'standard-site-breadcrumbs';
      return "application/components/".concat(name);
    })
  });

  _exports.default = _default;
});