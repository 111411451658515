define("superfly/pods/application/components/new-folder-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EzmDAoGR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal-dialog\",null,[[\"containerClass\",\"onClose\"],[[23,[\"containerClass\"]],[23,[\"cancel\"]]]],{\"statements\":[[0,\"  \"],[7,\"form\"],[9],[0,\"\\n    \"],[7,\"header\"],[9],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"New folder\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"class\",\"placeholder\",\"value\"],[\"form-control\",\"Folder name\",[23,[\"name\"]]]]],false],[0,\"\\n    \"],[7,\"footer\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"waiting\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isWaiting\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"material-spinner\",null,[[\"size\",\"color\"],[\"xs\",\"white\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n        \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-primary \",[27,\"unless\",[[23,[\"canSubmit\"]],\"disabled\"],null]]]],[9],[0,\"save\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/new-folder-modal/template.hbs"
    }
  });

  _exports.default = _default;
});