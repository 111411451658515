define("superfly/pods/frame/dashboard/components/map-toolbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6vwRNTcU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"tools\"],[9],[0,\"\\n    \"],[1,[27,\"frame/dashboard/components/measurement-toolbar-select\",null,[[\"flight\"],[[23,[\"flight\"]]]]],false],[0,\"\\n    \"],[1,[27,\"frame/dashboard/components/measurement-toolbar-area\",null,[[\"flight\"],[[23,[\"flight\"]]]]],false],[0,\"\\n    \"],[1,[27,\"frame/dashboard/components/measurement-toolbar-distance\",null,[[\"flight\"],[[23,[\"flight\"]]]]],false],[0,\"\\n    \"],[1,[27,\"frame/dashboard/components/measurement-toolbar-comment\",null,[[\"flight\"],[[23,[\"flight\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/dashboard/components/map-toolbar/template.hbs"
    }
  });

  _exports.default = _default;
});