define("superfly/pods/application/components/object-type-icon-terrain/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "elmw9jx8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"g\"],[11,\"transform\",\"translate(-8509 -13676)\"],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"class\",\"a\"],[11,\"cx\",\"18\"],[11,\"cy\",\"18\"],[11,\"r\",\"18\"],[11,\"transform\",\"translate(22 651)\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"class\",\"b\"],[11,\"d\",\"M0,2.6A13.841,13.841,0,0,1,2.72,1.48,7.957,7.957,0,0,1,5.28,1,5.9,5.9,0,0,1,8,1.8a8.781,8.781,0,0,0,2.72.8,5.9,5.9,0,0,0,2.72-.8A5.312,5.312,0,0,1,16,1V3.4a5.9,5.9,0,0,0-2.72.8,9.308,9.308,0,0,1-2.56.8A8.781,8.781,0,0,1,8,4.2a5.9,5.9,0,0,0-2.72-.8,8.127,8.127,0,0,0-2.72.48A12.385,12.385,0,0,0,0,5ZM0,7.4A13.841,13.841,0,0,1,2.72,6.28,7.957,7.957,0,0,1,5.28,5.8,5.9,5.9,0,0,1,8,6.6a8.781,8.781,0,0,0,2.72.8,5.9,5.9,0,0,0,2.72-.8A5.312,5.312,0,0,1,16,5.8V8.2a5.9,5.9,0,0,0-2.72.8,9.308,9.308,0,0,1-2.56.8A8.781,8.781,0,0,1,8,9a5.9,5.9,0,0,0-2.72-.8,8.127,8.127,0,0,0-2.72.48A12.385,12.385,0,0,0,0,9.8Zm0,4.8a13.841,13.841,0,0,1,2.72-1.12,7.957,7.957,0,0,1,2.56-.48A5.9,5.9,0,0,1,8,11.4a8.781,8.781,0,0,0,2.72.8,5.9,5.9,0,0,0,2.72-.8A5.312,5.312,0,0,1,16,10.6V13a5.9,5.9,0,0,0-2.72.8,9.309,9.309,0,0,1-2.56.8A8.781,8.781,0,0,1,8,13.8,5.9,5.9,0,0,0,5.28,13a8.127,8.127,0,0,0-2.72.48A12.385,12.385,0,0,0,0,14.6Z\"],[11,\"transform\",\"translate(32 661)\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/object-type-icon-terrain/template.hbs"
    }
  });

  _exports.default = _default;
});