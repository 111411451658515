define("superfly/pods/frame/components/route-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    subscriptionManager: Ember.inject.service(),
    actions: {
      reload: function reload() {
        location.reload();
      }
    }
  });

  _exports.default = _default;
});