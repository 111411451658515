define("superfly/pods/frame/dashboard/folders/folder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nf34b1ro",
    "block": "{\"symbols\":[\"currentFolder\"],\"statements\":[[4,\"liquid-bind\",[[23,[\"folder\"]]],[[\"use\"],[\"fade\"]],{\"statements\":[[0,\"  \"],[1,[27,\"frame/dashboard/folders/components/folder-explorer\",null,[[\"folder\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/dashboard/folders/folder/template.hbs"
    }
  });

  _exports.default = _default;
});