define("superfly/pods/frame/settings/profile/components/user-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kmYpnVJJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"user-icon\"],[9],[7,\"span\"],[9],[1,[23,[\"user\",\"initials\"]],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"form\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"First name\"],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"class\"],[[23,[\"user\",\"firstName\"]],\"form-control\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"Last name\"],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"value\",\"class\"],[[23,[\"user\",\"lastName\"]],\"form-control\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"footer\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"loading\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-text mr-2\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"cancelEdit\"]],[10],[0,\"\\n      \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-primary \",[27,\"unless\",[[23,[\"canSubmit\"]],\"disabled\"],null]]]],[11,\"type\",\"submit\"],[9],[0,\"Save\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"saveEdit\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"user-name\"],[9],[1,[23,[\"user\",\"fullName\"]],false],[10],[0,\"\\n  \"],[7,\"a\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"edit\"],[10],[3,\"action\",[[22,0,[]],\"startEdit\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/profile/components/user-name/template.hbs"
    }
  });

  _exports.default = _default;
});