define("superfly/pods/frame/dashboard/components/auto-export-agx/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNameBindings: ['hasNoConnections:empty'],
    routing: Ember.inject.service('-routing'),
    agx: Ember.inject.service(),
    folder: null,
    step: 1,
    addExportModalVisible: false,
    selectedGrower: Ember.computed.alias('agx.selectedGrower'),
    selectedFarm: Ember.computed.alias('agx.selectedFarm'),
    selectedField: Ember.computed.alias('agx.selectedField'),
    hasNoConnections: Ember.computed.empty('filteredConnections'),
    filteredConnections: Ember.computed.filter('agx.connections', function filteredConnections(item) {
      var folderId = Ember.get(this, 'folder.id');
      return item.botlink_folder === folderId;
    }),
    modalContainerClass: Ember.computed('id', function containerClass() {
      return "".concat(Ember.get(this, 'componentCssClassName'), " custom-modal modal-sm auto-export-modal");
    }),
    finalizeAddExport: Ember.computed('step', 'selectedField', function finalizeAddExport() {
      return Ember.get(this, 'step') === 3 && Ember.get(this, 'selectedField');
    }),
    hasOptionChosen: Ember.computed('step', 'selectedGrower', 'selectedFarm', 'selectedField', function hasOptionChosen() {
      var _this$getProperties = this.getProperties(['step', 'selectedGrower', 'selectedFarm', 'selectedField']),
          step = _this$getProperties.step,
          selectedGrower = _this$getProperties.selectedGrower,
          selectedFarm = _this$getProperties.selectedFarm,
          selectedField = _this$getProperties.selectedField;

      switch (+step) {
        case 1:
          return selectedGrower !== null;

        case 2:
          return selectedFarm !== null;

        case 3:
          return selectedField !== null;

        default:
          return false;
      }
    }),
    actions: {
      resetAddExport: function resetAddExport() {
        this.setProperties({
          step: 1,
          selectedGrower: null,
          selectedFarm: null,
          selectedField: null
        });
      },
      exportSettings: function exportSettings() {
        Ember.get(this, 'routing').transitionTo('dashboard.folders.settings');
      },
      openAddExportModal: function openAddExportModal() {
        this.setProperties({
          step: 1,
          addExportModalVisible: true
        });
      },
      stepForward: function stepForward() {
        if (Ember.get(this, 'hasOptionChosen')) {
          var step = Ember.get(this, 'step');

          if (step < 3) {
            this.incrementProperty('step');
          }
        }
      },
      stepBackward: function stepBackward() {
        var step = Ember.get(this, 'step');

        if (step > 1) {
          this.decrementProperty('step');
        }
      },
      closeAddExportModal: function closeAddExportModal() {
        Ember.set(this, 'addExportModalVisible', false);
      },
      selectGrower: function selectGrower(company) {
        Ember.set(this, 'selectedGrower', company);
      },
      selectFarm: function selectFarm(farm) {
        Ember.set(this, 'selectedFarm', farm);
      },
      selectField: function selectField(field) {
        Ember.set(this, 'selectedField', field);
      },
      completeAddExport: function completeAddExport() {
        var _this = this;

        var _Ember$getProperties = Ember.getProperties(this, ['selectedGrower', 'selectedFarm', 'selectedField', 'folder', 'agx']),
            selectedGrower = _Ember$getProperties.selectedGrower,
            selectedFarm = _Ember$getProperties.selectedFarm,
            selectedField = _Ember$getProperties.selectedField,
            folder = _Ember$getProperties.folder,
            agx = _Ember$getProperties.agx;

        agx.addConnection(Ember.get(selectedGrower, 'id'), Ember.get(selectedFarm, 'id'), Ember.get(selectedField, 'id'), Ember.get(folder, 'id')).then(function () {
          return _this.send('closeAddExportModal');
        }).catch();
      },
      deleteExportCollection: function deleteExportCollection(connection) {
        var _this2 = this;

        var agx = Ember.get(this, 'agx');
        agx.deleteConnection(Ember.get(connection, 'id')).then(function () {
          return _this2.send('closeAddExportModal');
        }).catch();
      }
    }
  });

  _exports.default = _default;
});