define("superfly/pods/frame/dashboard/flights/flight/components/properties-overlays/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    mainMap: Ember.inject.service(),
    units: null,
    flight: null,
    selected: null,
    overlays: Ember.computed.alias('flight.sortedFilteredOverlays'),
    flightImageCount: null,
    selectedId: Ember.computed.alias('mainMap.currentOverlayId'),
    currentOverlay: Ember.computed('overlays', 'selectedId', function currentOverlay() {
      return Ember.get(this, 'overlays').findBy('id', Ember.get(this, 'selectedId'));
    }),
    activeOverlayId: Ember.computed('currentOverlay', 'mainMap.sketchfabActive', function () {
      var sketchfabVisible = Ember.get(this, 'mainMap.sketchfabActive');

      if (sketchfabVisible) {
        return 'sketchfab';
      }

      return Ember.get(this, 'currentOverlay.id');
    }),
    has3d: Ember.computed.bool('flight.sketchfabUid'),
    actions: {
      switchTo: function switchTo(overlay) {
        if (overlay === 'sketchfab') {
          Ember.set(this, 'mainMap.flightSketchfabViewable', Ember.get(this, 'flight'));
        } else {
          Ember.set(this, 'mainMap.flightSketchfabViewable', false);
          Ember.get(this, 'mainMap').changeTileOverlay(overlay);
        }
      }
    }
  });

  _exports.default = _default;
});