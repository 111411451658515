define("superfly/pods/application/components/delete-confirm-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isWaiting: false,
    objectName: null,
    objectType: null,
    confirmation: null,
    containerClass: Ember.computed('id', function () {
      return "".concat(this.get('componentCssClassName'), " modal-sm");
    }),
    didInsertElement: function didInsertElement() {
      this.$('input').focus();
    },
    canConfirm: Ember.computed('objectName', 'confirmation', function canConfirm() {
      var confirmation = Ember.get(this, 'confirmation');
      return "".concat(confirmation).trim() === 'DELETE';
    }),
    actions: {
      yes: function yes() {
        var canConfirm = Ember.get(this, 'canConfirm');

        if (canConfirm) {
          this.sendAction('confirm');
        }
      },
      no: function no() {
        this.sendAction('cancel');
      }
    }
  });

  _exports.default = _default;
});