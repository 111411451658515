define("superfly/pods/frame/plans/select-plan/components/plan-chooser/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    subscriptionManager: Ember.inject.service(),
    showMonthlyRates: false,
    monthlyPlans: Ember.computed.filterBy('plans', 'interval', 'monthly'),
    yearlyPlans: Ember.computed.filterBy('plans', 'interval', 'yearly'),
    plansToDisplay: Ember.computed('showMonthlyRates', function () {
      var _Ember$getProperties = Ember.getProperties(this, ['showMonthlyRates', 'monthlyPlans', 'yearlyPlans']),
          showMonthlyRates = _Ember$getProperties.showMonthlyRates,
          monthlyPlans = _Ember$getProperties.monthlyPlans,
          yearlyPlans = _Ember$getProperties.yearlyPlans;

      return showMonthlyRates ? monthlyPlans : yearlyPlans;
    }),
    actions: {
      setBillingRateToMonthly: function setBillingRateToMonthly() {
        Ember.set(this, 'showMonthlyRates', true);
      },
      setBillingRateToYearly: function setBillingRateToYearly() {
        Ember.set(this, 'showMonthlyRates', false);
      }
    }
  });

  _exports.default = _default;
});