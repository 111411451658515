define("superfly/pods/application/components/main-map-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mainMap: Ember.inject.service(),
    sketchfabActive: Ember.computed.alias('mainMap.sketchfabActive')
  });

  _exports.default = _default;
});