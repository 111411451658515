define("superfly/services/sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    isVisible: false,
    onShow: Ember.on('show', function onShow() {
      Ember.set(this, 'isVisible', true);
    }),
    onHide: Ember.on('hide', function onHide() {
      Ember.set(this, 'isVisible', false);
    })
  });

  _exports.default = _default;
});