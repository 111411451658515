define("superfly/helpers/length-with-units", ["exports", "numeral", "lodash", "superfly/utils/convert", "superfly/utils/unit-abbr"], function (_exports, _numeral, _lodash, _convert, _unitAbbr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lengthWithUnits = lengthWithUnits;
  _exports.default = void 0;

  function lengthWithUnits(params) {
    var value = params[0];
    var units = params[1];

    if (units === 'acres') {
      units = 'feet';
    }

    if (units === 'hectares') {
      units = 'meters';
    }

    if (value < 10) {
      return "".concat((0, _numeral.default)((0, _convert.default)(value).to(units)).format('0,0.00'), " ").concat((0, _unitAbbr.default)(units));
    } else if (value >= 10 && value < 100) {
      return "".concat((0, _numeral.default)((0, _convert.default)(value).to(units)).format('0,0.0'), " ").concat((0, _unitAbbr.default)(units));
    } else {
      return "".concat((0, _numeral.default)((0, _convert.default)(value).to(units)).format('0,0'), " ").concat((0, _unitAbbr.default)(units));
    }
  }

  var _default = Ember.Helper.helper(lengthWithUnits);

  _exports.default = _default;
});