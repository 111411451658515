define("superfly/pods/frame/plans/select-plan/components/route-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    subscriptionManager: Ember.inject.service(),
    plans: Ember.computed.alias('model.plans')
  });

  _exports.default = _default;
});