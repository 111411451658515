define("superfly/components/collapsed-action-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'c-a-m',
    icon: 'more_vert',
    dropdownMenuElement: null,
    isCamVisible: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().on('mouseleave', '.c-a-m-dropdown', function () {
        return _this.send('hide');
      });
      Ember.$('body').on('touchend', function () {
        return _this.send('hide');
      });
    },
    touchEnd: function touchEnd() {
      this.send('show');
      return false;
    },
    actions: {
      show: function show() {
        if (!Ember.get(this, 'isDestroyed') || !Ember.get(this, 'isDestroying')) {
          var $e = this.$();
          var elementTop = $e.offset().top;
          var windowTop = Ember.$(window).scrollTop();
          var halfWindowHeight = Ember.$(window).height() / 2;

          if (halfWindowHeight < elementTop - windowTop) {
            $e.addClass('open-up');
          }

          Ember.set(this, 'isCamVisible', true);
        }
      },
      hide: function hide() {
        if (!Ember.get(this, 'isDestroyed') || !Ember.get(this, 'isDestroying')) {
          Ember.set(this, 'isCamVisible', false);
        }
      }
    }
  });

  _exports.default = _default;
});