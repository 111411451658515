define("superfly/transforms/bounds", ["exports", "ember-data", "L"], function (_exports, _emberData, _L) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform; // checks for null because bounds is not required for site

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized !== null) {
        var southWest = _L.default.latLng(serialized.southWest.lat, serialized.southWest.lng);

        var northEast = _L.default.latLng(serialized.northEast.lat, serialized.northEast.lng);

        return _L.default.latLngBounds(southWest, northEast);
      } else {
        return serialized;
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized !== null) {
        return {
          southWest: {
            lat: deserialized._southWest.lat,
            lng: deserialized._southWest.lng
          },
          northEast: {
            lat: deserialized._northEast.lat,
            lng: deserialized._northEast.lng
          }
        };
      } else {
        return deserialized;
      }
    }
  });

  _exports.default = _default;
});