define("superfly/pods/frame/dashboard/folders/components/link-to/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/TWxkoCy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal-dialog\",null,[[\"containerClass\"],[[23,[\"containerClass\"]]]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"menu-header\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"Link to\"],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"pull-right\"],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"closeMenu\"],null]]],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"menu-body\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"integrations\",\"hasConnections\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"integrations\",\"isProcoreConnected\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"frame/dashboard/components/auto-export-procore\",null,[[\"folder\"],[[23,[\"folder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"integrations\",\"isEgnyteConnected\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"frame/dashboard/components/auto-export-egnyte\",null,[[\"folder\"],[[23,[\"folder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"integrations\",\"isAgxConnected\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"frame/dashboard/components/auto-export-agx\",null,[[\"folder\"],[[23,[\"folder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[4,\"link-to\",[\"frame.settings.integrations\"],[[\"class\"],[\"btn btn-block\"]],{\"statements\":[[0,\"Authorize integration\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/dashboard/folders/components/link-to/template.hbs"
    }
  });

  _exports.default = _default;
});