define("superfly/pods/frame/settings/profile/components/user-name/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['d-flex', 'flex-row', 'align-items-center'],
    classNameBindings: ['isEditing'],
    session: Ember.inject.service(),
    isEditing: false,
    user: Ember.computed.alias('session.currentUser'),
    canSubmit: Ember.computed('user.firstName', 'user.lastName', function canSubmit() {
      var user = Ember.get(this, 'user');

      var _Ember$getProperties = Ember.getProperties(user, ['firstName', 'lastName']),
          firstName = _Ember$getProperties.firstName,
          lastName = _Ember$getProperties.lastName;

      var changedAttributes = Ember.assign({}, user.changedAttributes());
      var attributesDidChange = Ember.get(changedAttributes, 'firstName') || Ember.get(changedAttributes, 'lastName');
      return firstName && lastName && attributesDidChange;
    }),
    actions: {
      startEdit: function startEdit() {
        Ember.set(this, 'isEditing', true);
      },
      stopEdit: function stopEdit() {
        Ember.set(this, 'isEditing', false);
      },
      cancelEdit: function cancelEdit() {
        var user = Ember.get(this, 'user');
        user.rollbackAttributes();
        this.send('stopEdit');
      },
      saveEdit: function saveEdit() {
        var _this = this;

        var user = Ember.get(this, 'user');

        if (Ember.get(this, 'canSubmit')) {
          user.save().then(function () {
            user.notifyPropertyChange('fullName');

            _this.send('stopEdit');
          });
        }
      }
    }
  });

  _exports.default = _default;
});