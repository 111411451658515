define("superfly/pods/auth/login/components/route-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'article',
    notify: Ember.inject.service(),
    params: null,
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'params.confirmationSuccess')) {
        Ember.get(this, 'notify').success('Confirmation Successful', {
          closeAfter: 10000
        });
      } else if (Ember.get(this, 'params.alreadyConfirmed')) {
        Ember.get(this, 'notify').info('Account already confirmed', {
          closeAfter: 10000
        });
      }
    }
  });

  _exports.default = _default;
});