define("superfly/pods/application/components/upload-image-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function alphanum(a, b) {
    function chunkify(t) {
      var tz = [];
      var x = 0,
          y = -1,
          n = 0,
          i,
          j;

      while (i = (j = t.charAt(x++)).charCodeAt(0)) {
        var m = i === 46 || i >= 48 && i <= 57;

        if (m !== n) {
          tz[++y] = '';
          n = m;
        }

        tz[y] += j;
      }

      return tz;
    }

    var aa = chunkify(a);
    var bb = chunkify(b);

    for (var x = 0; aa[x] && bb[x]; x++) {
      if (aa[x] !== bb[x]) {
        var c = Number(aa[x]),
            d = Number(bb[x]);

        if (c === aa[x] && d === bb[x]) {
          return c - d;
        } else return aa[x] > bb[x] ? 1 : -1;
      }
    }

    return aa.length - bb.length;
  }

  var _default = Ember.Component.extend({
    tagName: 'ul',
    collection: null,
    files: Ember.computed.sort('collection.files', function files(a, b) {
      var aExif = Boolean(Ember.get(a, 'exif'));
      var bExif = Boolean(Ember.get(b, 'exif'));
      var result = aExif - bExif;

      if (result === 0) {
        return alphanum(a.name, b.name);
      }

      return result;
    }),
    actions: {
      markIcon: function markIcon(file) {
        if (file.marker) {
          file.marker._icon.click();
        }
      },
      removeFile: function removeFile(file) {
        Ember.get(this, 'collection').removeFile(file);
      }
    }
  });

  _exports.default = _default;
});