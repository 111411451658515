define("superfly/pods/frame/sites/site/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T/Gy6i1B",
    "block": "{\"symbols\":[\"currentSite\"],\"statements\":[[4,\"liquid-bind\",[[23,[\"site\"]]],[[\"use\"],[\"fade\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row col-12 justify-content-center p-0 m-0\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-11 col-12 p-0 m-0\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-12 p-0\"],[9],[0,\"\\n        \"],[1,[27,\"frame/sites/site/components/site-header\",null,[[\"site\"],[[22,1,[]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"content-container col-12 p-0\"],[9],[0,\"\\n        \"],[1,[21,\"liquid-outlet\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/site/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});