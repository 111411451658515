define("superfly/pods/frame/dashboard/folders/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      transition.abort();
      var companyId = Ember.get(this, 'session.currentCompany.rootFolder.id');
      this.transitionTo('frame.dashboard.folders.folder', companyId);
    }
  });

  _exports.default = _default;
});