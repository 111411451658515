define("superfly/pods/frame/dashboard/flights/flight/components/properties-viewable-comment/component", ["exports", "superfly/mixins/map-editable"], function (_exports, _mapEditable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mapEditable.default, {
    classNames: ['comment-object', 'properties-viewable', 'flight-properties-viewable'],
    classNameBindings: ['measurement.color', 'isEditing:editing'],
    measurement: null,
    index: null,
    willRender: function willRender() {
      Ember.set(this, 'measurement.displayLabel', "".concat(Ember.get(this, 'displayNumber')));
    },
    displayNumber: Ember.computed('index', function displayNumber() {
      return Ember.get(this, 'index') + 1;
    }),
    displayName: Ember.computed('measurement.content', function displayName() {
      var content = Ember.get(this, 'measurement.content');

      if (content) {
        if (content.length > 20) {
          return "".concat(content.substring(0, 20), " ...");
        }

        return content;
      }

      return "Comment ".concat(Ember.get(this, 'displayNumber'));
    })
  });

  _exports.default = _default;
});