define("superfly/pods/frame/settings/integrations/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PtlieZeN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"frame/settings/components/settings-section\",null,null,{\"statements\":[[0,\"  \"],[1,[21,\"frame/settings/integrations/components/integration-procore-connector\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"frame/settings/components/settings-section\",null,null,{\"statements\":[[0,\"  \"],[1,[21,\"frame/settings/integrations/components/integration-egnyte-connector\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"frame/settings/components/settings-section\",null,null,{\"statements\":[[0,\"  \"],[1,[21,\"frame/settings/integrations/components/integration-agx-connector\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/integrations/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});