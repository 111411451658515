define("superfly/serializers/folder", ["exports", "superfly/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      return this._super(typeClass, Ember.assign({}, hash, {
        links: {
          folder: hash.folderId === 'root' ? "/folders/company/".concat(hash.companyId, "/root") : "/folders/".concat(hash.id),
          folders: "/folders/".concat(hash.id, "/folders"),
          flights: "/folders/".concat(hash.id, "/flights")
        }
      }));
    }
  });

  _exports.default = _default;
});