define("superfly/services/egnyte", ["exports", "superfly/mixins/integration-service", "superfly/config/environment"], function (_exports, _integrationService, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_integrationService.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    userId: Ember.computed.alias('session.currentUserID'),
    accessToken: Ember.computed.alias('session.accessToken'),
    connections: [],
    isConnected: false,
    directories: null,
    // Connection Statuses
    isCheckingConnection: false,
    isLoadingConnections: false,
    isLoadingAny: Ember.computed('id', function isLoadingAny() {
      return false;
    }),
    hasUserAndToken: Ember.computed.and('userId', 'accessToken'),
    shouldConnect: Ember.computed('hasUserAndToken', 'isConnected', function shouldConnect() {
      var _this$getProperties = this.getProperties(['hasUserAndToken', 'isConnected']),
          hasUserAndToken = _this$getProperties.hasUserAndToken,
          isConnected = _this$getProperties.isConnected;

      return hasUserAndToken && !isConnected;
    }),
    checkConnection: Ember.on('init', Ember.observer('userId', 'accessToken', function checkConnection() {
      var _this = this;

      var _this$getProperties2 = this.getProperties(['userId', 'accessToken', 'hasUserAndToken', 'ajax']),
          userId = _this$getProperties2.userId,
          accessToken = _this$getProperties2.accessToken,
          hasUserAndToken = _this$getProperties2.hasUserAndToken,
          ajax = _this$getProperties2.ajax;

      if (hasUserAndToken) {
        this.set('isCheckingConnection', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/egnyte/users/").concat(userId, "/identity?token=").concat(accessToken)).then(function () {
          return Ember.set(_this, 'isConnected', true);
        }).then(function () {
          return _this.getConnections();
        }).catch(function () {
          return Ember.set(_this, 'isConnected', false);
        }).finally(function () {
          return Ember.set(_this, 'isCheckingConnection', false);
        });
      }
    })),
    resetSelections: function resetSelections() {},
    connect: function connect(callbackURL) {
      var _this$getProperties3 = this.getProperties(['userId', 'accessToken', 'shouldConnect']),
          userId = _this$getProperties3.userId,
          accessToken = _this$getProperties3.accessToken,
          shouldConnect = _this$getProperties3.shouldConnect;

      if (shouldConnect) {
        var url = "".concat(_environment.default.BOTLINK_API_URL, "/integrations/egnyte/oauth/users/").concat(userId, "?token=").concat(accessToken, "&callback=").concat(callbackURL);
        window.location.replace(url);
      }
    },
    disconnect: function disconnect() {
      var _this2 = this;

      var _this$getProperties4 = this.getProperties(['userId', 'hasUserAndToken', 'ajax']),
          userId = _this$getProperties4.userId,
          hasUserAndToken = _this$getProperties4.hasUserAndToken,
          ajax = _this$getProperties4.ajax;

      if (hasUserAndToken) {
        this.set('isDisconnecting', true);
        ajax.delete("".concat(_environment.default.BOTLINK_API_URL, "/integrations/egnyte/users/").concat(userId), {
          dataType: 'text'
        }).then(function () {
          return Ember.set(_this2, 'isConnected', false);
        }).finally(function () {
          return Ember.set(_this2, 'isDisconnecting', false);
        });
      }
    },
    getDirectoriesAtPath: function getDirectoriesAtPath(path) {
      var _this3 = this;

      var _this$getProperties5 = this.getProperties(['userId', 'ajax']),
          userId = _this$getProperties5.userId,
          ajax = _this$getProperties5.ajax;

      return ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/egnyte/users/").concat(userId, "/files").concat(path ? path : '/')).finally(function () {
        return Ember.set(_this3, 'isLoading', false);
      });
    },
    getConnections: function getConnections() {
      var _this4 = this;

      var _this$getProperties6 = this.getProperties(['userId', 'shouldConnect', 'ajax']),
          userId = _this$getProperties6.userId,
          shouldConnect = _this$getProperties6.shouldConnect,
          ajax = _this$getProperties6.ajax;

      if (!shouldConnect) {
        Ember.set(this, 'isLoadingConnections', true);
        ajax.request("".concat(_environment.default.BOTLINK_API_URL, "/integrations/egnyte/users/").concat(userId, "/connections")).then(function (connections) {
          return Ember.set(_this4, 'connections', connections);
        }).finally(function () {
          return Ember.set(_this4, 'isLoadingConnections', false);
        });
      }
    },
    addConnection: function addConnection(botlinkFolderId, egnyteFolderId) {
      var _this5 = this;

      var _Ember$getProperties = Ember.getProperties(this, ['userId', 'hasUserAndToken', 'ajax']),
          userId = _Ember$getProperties.userId,
          hasUserAndToken = _Ember$getProperties.hasUserAndToken,
          ajax = _Ember$getProperties.ajax;

      if (hasUserAndToken) {
        var connection = {
          botlink_folder: botlinkFolderId,
          folder: egnyteFolderId
        };
        var data = JSON.stringify(connection);
        Ember.set(this, 'isLoading', true);
        return ajax.post("".concat(_environment.default.BOTLINK_API_URL, "/integrations/egnyte/users/").concat(userId, "/connections"), {
          data: data,
          dataType: 'text'
        }).then(function () {
          return _this5.getConnections();
        }).catch(function () {
          return Ember.set(_this5, 'isLoading', false);
        });
      }

      return Ember.RSVP.reject('addConnection failed.');
    },
    deleteConnection: function deleteConnection(connectionId) {
      var _this$getProperties7 = this.getProperties(['hasUserAndToken', 'ajax']),
          hasUserAndToken = _this$getProperties7.hasUserAndToken,
          ajax = _this$getProperties7.ajax;

      if (hasUserAndToken && connectionId) {
        var connections = this.get('connections');
        var connection = connections.findBy('id', connectionId);
        return ajax.delete("".concat(_environment.default.BOTLINK_API_URL, "/integrations/egnyte/connections/").concat(connectionId), {
          dataType: 'text'
        }).then(function () {
          return connections.removeObject(connection);
        });
      }

      return Ember.RSVP.reject('deleteConnection failed.');
    }
  });

  _exports.default = _default;
});