define("superfly/helpers/format-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatText = formatText;
  _exports.default = void 0;
  var Helper = Ember.Helper;
  var formatters = {
    capitalize: function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  function formatText(params) {
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var string = params[0] || '';
    var method = hash.method;

    if (!method || typeof formatters[method] !== 'function') {
      return '';
    }

    return formatters[method](string);
  }

  var _default = Helper.helper(formatText);

  _exports.default = _default;
});