define("superfly/pods/frame/sites/site/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nrmojp8X",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"frame/sites/site/index/components/route-component\",null,[[\"site\",\"flight\"],[[23,[\"site\"]],[23,[\"flight\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/site/index/template.hbs"
    }
  });

  _exports.default = _default;
});