define("superfly/components/unit-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ['dropdown-selector', 'd-flex', 'flex-row'],
    units: null,
    unitSelectVisible: false,
    unitsAvailable: [],
    init: function init() {
      this._super.apply(this, arguments);

      var unitsAvailable = ['meters', 'feet', 'yards', 'acres', 'hectares', 'miles', 'kilometers'];
      var user = Ember.get(this, 'session.currentUser');

      if (user) {
        unitsAvailable.push('preferred units');
      }

      this.set('unitsAvailable', unitsAvailable);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().on('mouseleave', '.dropdown-menu', function () {
        _this.set('unitSelectVisible', false);
      });
    },
    actions: {
      select: function select(value) {
        this.sendAction('select', value);
        this.set('unitSelectVisible', false);
      },
      openUnitOptions: function openUnitOptions() {
        this.set('unitSelectVisible', true);
      }
    }
  });

  _exports.default = _default;
});