define("superfly/pods/frame/sites/site/products/components/delete-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DvcH2vDR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal-dialog\",null,[[\"containerClass\"],[[23,[\"containerClass\"]]]],{\"statements\":[[0,\"  \"],[7,\"h3\"],[11,\"class\",\"title\"],[9],[0,\"Are you sure you want to delete?\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    Negative consequences can occur if you don't read this!\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    This action CANNOT be undone. This will PERMANENTLY delete the site product \\\"\"],[1,[23,[\"product\",\"product\",\"name\"]],false],[0,\"\\\"\\\" and linked product measurements.\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[27,\"form-text-input\",null,[[\"label\",\"placeholder\",\"value\"],[\"Please type DELETE to confirm\",\"DELETE\",[23,[\"deleteConfirmed\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"footer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"waiting\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"material-spinner\",null,[[\"size\"],[\"xs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[9],[0,\"cancel\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n      \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-primary \",[27,\"unless\",[[23,[\"canSubmit\"]],\"disabled\"],null]]]],[9],[0,\"save\"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/site/products/components/delete-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});