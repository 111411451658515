define("superfly/pods/frame/settings/components/settings-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oAVJMJBW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"frame.settings.profile\"],[[\"class\",\"afterNavigate\"],[\"nav-link\",[27,\"action\",[[22,0,[]],\"closeSidebar\"],null]]],{\"statements\":[[0,\"Profile\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"frame.settings.account\"],[[\"class\",\"afterNavigate\"],[\"nav-link\",[27,\"action\",[[22,0,[]],\"closeSidebar\"],null]]],{\"statements\":[[0,\"Account\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"frame.settings.billing\"],[[\"class\",\"afterNavigate\"],[\"nav-link\",[27,\"action\",[[22,0,[]],\"closeSidebar\"],null]]],{\"statements\":[[0,\"Billing\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"frame.settings.integrations\"],[[\"class\",\"afterNavigate\"],[\"nav-link\",[27,\"action\",[[22,0,[]],\"closeSidebar\"],null]]],{\"statements\":[[0,\"Integrations\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"frame.settings.units\"],[[\"class\",\"afterNavigate\"],[\"nav-link\",[27,\"action\",[[22,0,[]],\"closeSidebar\"],null]]],{\"statements\":[[0,\"Units\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/components/settings-nav/template.hbs"
    }
  });

  _exports.default = _default;
});