define("superfly/pods/frame/plans/select-plan/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UKTjuus6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"plan-title\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"title\"],[9],[0,\"Choose the right Botlink for you\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"subtitle\"],[9],[0,\"\\n    Upgrade your plan to get the most out of Botlink.\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"frame/plans/select-plan/components/plan-chooser\",null,[[\"plans\"],[[23,[\"plans\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/plans/select-plan/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});