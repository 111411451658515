define("superfly/pods/frame/plans/checkout/components/route-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    subscriptionManager: Ember.inject.service(),
    session: Ember.inject.service(),
    planId: null,
    paymentMethod: null,
    showDropdown: false,
    isLoading: false,
    triggerPaymentMethodSubmit: false,
    selectedPlan: Ember.computed('planId', function () {
      return Ember.get(this, 'store').peekRecord('plan', Ember.get(this, 'planId'));
    }),
    monthlyIntervalActive: Ember.computed('selectedPlan', function () {
      return Ember.get(this, 'selectedPlan.interval') === 'monthly';
    }),
    intervalText: Ember.computed('selectedPlan', function () {
      return Ember.get(this, 'selectedPlan.interval') === 'monthly' ? 'Billed Monthly' : 'Billed Annually';
    }),
    hasPaymentMethod: Ember.computed.bool('paymentMethod'),
    actions: {
      toggleDropdown: function toggleDropdown(state) {
        if (state) {
          var showDropdown = state === 'open' ? true : false;
          Ember.set(this, 'showDropdown', showDropdown);
        } else {
          this.toggleProperty('showDropdown');
        }
      },
      selectYearlyInterval: function selectYearlyInterval() {
        var yearlyPlan = Ember.get(this, 'store').peekAll('plan').filterBy('displayName', Ember.get(this, 'selectedPlan.displayName')).filterBy('interval', 'yearly')[0];
        Ember.set(this, 'planId', Ember.get(yearlyPlan, 'id'));
      },
      selectMonthlyInterval: function selectMonthlyInterval() {
        var monthlyPlan = Ember.get(this, 'store').peekAll('plan').filterBy('displayName', Ember.get(this, 'selectedPlan.displayName')).filterBy('interval', 'monthly')[0];
        Ember.set(this, 'planId', Ember.get(monthlyPlan, 'id'));
      },
      submit: function submit() {
        this.$('button').blur();
        Ember.set(this, 'isLoading', true);
        var hasPaymentMethod = Ember.get(this, 'hasPaymentMethod');

        if (hasPaymentMethod) {
          this.send('onPaymentMethodSuccess');
        } else {
          this.toggleProperty('triggerPaymentMethodSubmit');
        }
      },
      onPaymentMethodSuccess: function onPaymentMethodSuccess() {
        var _this = this;

        var _Ember$getProperties = Ember.getProperties(this, ['subscriptionManager', 'selectedPlan']),
            subscriptionManager = _Ember$getProperties.subscriptionManager,
            selectedPlan = _Ember$getProperties.selectedPlan;

        subscriptionManager.changePlan(selectedPlan.id).then(function () {
          return _this._handlePaymentSuccess();
        }).catch(function (e) {
          return _this._handlePaymentFailure(e);
        });
      },
      onPaymentMethodFailure: function onPaymentMethodFailure(e) {
        this._handlePaymentFailure(e);
      }
    },
    _handlePaymentSuccess: function _handlePaymentSuccess() {
      Ember.set(this, 'isLoading', false);
      Ember.get(this, 'notify').success('Payment Successful!');
      Ember.get(this, 'routing').transitionTo('settings.billing');
    },
    _handlePaymentFailure: function _handlePaymentFailure() {
      Ember.set(this, 'isLoading', false);
      Ember.get(this, 'notify').alert('Payment Failed');
    }
  });

  _exports.default = _default;
});