define("superfly/helpers/decamelize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (text) {
    if (typeof text[0] !== 'undefined') {
      return text[0].capitalize().replace(/([a-z])([A-Z])/g, function (text) {
        return text.charAt(0).toUpperCase() + ' ' + text.charAt(1).toUpperCase();
      });
    }
  });

  _exports.default = _default;
});