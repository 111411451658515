define("superfly/pods/application/components/action-type-icon-clouddownload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u72YyYz7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"class\",\"fill-primary\"],[11,\"d\",\"M17.016-8.016,12-3,6.984-8.016h3V-12h4.031v3.984Zm2.344-2.953a7.519,7.519,0,0,0-2.578-4.312A7.417,7.417,0,0,0,12-17.016a7.621,7.621,0,0,0-3.937,1.125,7.6,7.6,0,0,0-2.719,2.906,6.161,6.161,0,0,0-3.8,1.969A5.936,5.936,0,0,0,0-6.984,5.845,5.845,0,0,0,1.758-2.766,6.145,6.145,0,0,0,6-.984H18.984a4.946,4.946,0,0,0,3.563-1.477A5.017,5.017,0,0,0,24-6a4.926,4.926,0,0,0-1.359-3.4A5.029,5.029,0,0,0,19.359-10.969Z\"],[11,\"transform\",\"translate(18364 -16192)\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/action-type-icon-clouddownload/template.hbs"
    }
  });

  _exports.default = _default;
});