define("superfly/models/download-option", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    mapType: attr('string'),
    dataType: attr('string'),
    fileType: attr('string'),
    category: attr('string'),
    label: attr('string'),
    flight: belongsTo('flight')
  });

  _exports.default = _default;
});