define("superfly/utils/unit-abbr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = unitAbbr;

  function unitAbbr(units) {
    switch (units) {
      case 'feet':
        return 'ft';

      case 'paces':
        return 'p';

      case 'yards':
        return 'yd';

      case 'acres':
        return 'ac';

      case 'hectares':
        return 'ha';

      case 'miles':
        return 'mi';

      case 'kilometers':
        return 'km';

      default:
        return 'm';
    }
  }
});