define("superfly/pods/frame/sites/components/import-site-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQgSJc66",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal-dialog\",null,[[\"containerClass\"],[[23,[\"containerClass\"]]]],{\"statements\":[[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"material-spinner\",null,[[\"size\"],[\"xs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"close\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-close\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\\n    \"],[7,\"h3\"],[11,\"class\",\"title\"],[9],[0,\"Import Site\"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[9],[0,\"All sites will be imported. If you already have sites With the same names/id's you will end up with duplicates\"],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[9],[0,\"Import sites\"],[3,\"action\",[[22,0,[]],\"importSites\"]],[10],[0,\"\\n\\n    \"],[7,\"footer\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"waiting\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"material-spinner\",null,[[\"size\"],[\"xs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[9],[0,\"Close\"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/components/import-site-modal/template.hbs"
    }
  });

  _exports.default = _default;
});