define("superfly/pods/application/components/loading-placeholder-flight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bJbf758L",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"timeline-item\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"animated-background\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"footer\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mask topside\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mask leftside\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mask rightside\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mask bottomside\"],[9],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"mask header-top\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mask header-middle\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mask header-bottom\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mask sub-header-right\"],[9],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"mask icon\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"svgClass\"],[9],[0,\"\\n          \"],[7,\"svg\"],[11,\"width\",\"36px\"],[11,\"height\",\"36px\"],[9],[0,\"\\n            \"],[7,\"mask\"],[11,\"id\",\"mask\"],[9],[0,\"\\n              \"],[7,\"rect\"],[11,\"fill\",\"white\"],[11,\"width\",\"36px\"],[11,\"height\",\"36px\"],[11,\"fill-opacity\",\"1\"],[9],[10],[0,\"\\n              \"],[7,\"circle\"],[11,\"cx\",\"18\"],[11,\"cy\",\"18\"],[11,\"r\",\"18\"],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"rect\"],[11,\"mask\",\"url(#mask)\"],[11,\"fill\",\"white\"],[11,\"width\",\"36px\"],[11,\"height\",\"36px\"],[11,\"fill-opacity\",\"1\"],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mask icon-right\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/loading-placeholder-flight/template.hbs"
    }
  });

  _exports.default = _default;
});