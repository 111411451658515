define("superfly/pdfMake/pdfMakeExtension", ["exports", "pdfmake/build/pdfmake", "superfly/pdfMake/vfs_fonts"], function (_exports, _pdfmake, _vfs_fonts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Set default core.vue pdf fonts
  _pdfmake.default.vfs = _vfs_fonts.default;
  _pdfmake.default.fonts = {
    Arial: {
      normal: 'arial.ttf',
      bold: 'arialbd.ttf'
    }
  }; // TODO: We can move images into vfs if getDataUri causes performance issues
  // Extend pdfMake

  _pdfmake.default.getDataUri = function (url) {
    return new Promise(function (resolve, reject) {
      var image = new Image();

      image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size

        canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

        canvas.getContext('2d').drawImage(this, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };

      image.src = url;
    });
  };

  _pdfmake.default.horizontalLine = function (widthPercent, thickness, color) {
    widthPercent = widthPercent === undefined ? 100 : widthPercent;
    var length = 514 / 100 * widthPercent;

    if (!thickness) {
      thickness = 0.5;
    }

    if (!color) {
      color = '#000000';
    }

    var returnObj = {
      canvas: [{
        type: 'line',
        x1: 0,
        y1: 0,
        x2: length,
        y2: 0,
        lineWidth: thickness,
        lineColor: color
      }]
    };
    return returnObj;
  };

  var _default = _pdfmake.default;
  _exports.default = _default;
});