define("superfly/pods/frame/dashboard/folders/components/move-to/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['panel'],
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    agx: Ember.inject.service(),
    isWaiting: false,
    objectToBeMoved: null,
    currentFolderId: null,
    selectedFolderId: null,
    init: function init() {
      this._super.apply(this, arguments);

      var store = Ember.get(this, 'store');
      var root = store.peekAll('folder').findBy('isRoot', true);
      var rootId = Ember.get(root, 'id');
      Ember.set(this, 'rootId', rootId);
      Ember.set(this, 'currentFolderId', rootId);
      Ember.set(this, 'selectedFolderId', rootId);
    },
    isShowingMoveDialog: true,
    isSettingAgxSeason: false,
    folder: Ember.computed('currentFolderId', function () {
      var store = Ember.get(this, 'store');
      var currentFolderId = Ember.get(this, 'currentFolderId');
      return store.findRecord('folder', currentFolderId);
    }),
    containerClass: Ember.computed('id', function () {
      return "".concat(this.get('componentCssClassName'), " modal-xs");
    }),
    folders: Ember.computed('folder', function () {
      var objectToBeMovedId = Ember.get(this, 'objectToBeMoved.id');
      return _emberData.default.PromiseArray.create({
        promise: Ember.get(this, 'folder').then(function (folder) {
          return Ember.get(folder, 'folders');
        }).then(function (folders) {
          return folders.filter(function (folder) {
            return Ember.get(folder, 'id') !== objectToBeMovedId;
          });
        }).then(function (folders) {
          return folders.sortBy('name');
        })
      });
    }),
    moveButtonDisabled: Ember.computed('selectedFolderId', 'isWaiting', function moveButtonDisabled() {
      var _Ember$getProperties = Ember.getProperties(this, ['selectedFolderId', 'isWaiting', 'objectToBeMoved']),
          selectedFolderId = _Ember$getProperties.selectedFolderId,
          isWaiting = _Ember$getProperties.isWaiting,
          objectToBeMoved = _Ember$getProperties.objectToBeMoved;

      var objectToBeMovedParentId = Ember.get(objectToBeMoved, 'currentFolderId');
      return objectToBeMovedParentId === selectedFolderId || !selectedFolderId || isWaiting;
    }),
    moveButtonLabel: Ember.computed('selectedFolderId', 'currentFolderId', function moveButtonLabel() {
      var _Ember$getProperties2 = Ember.getProperties(this, ['selectedFolderId', 'currentFolderId']),
          selectedFolderId = _Ember$getProperties2.selectedFolderId,
          currentFolderId = _Ember$getProperties2.currentFolderId;

      return "Move".concat(selectedFolderId === currentFolderId ? ' here' : '');
    }),
    doMove: function doMove(objectToBeMoved, selectedFolder) {
      var _this = this;

      var notify = Ember.get(this, 'notify');
      Ember.set(this, 'isWaiting', true);
      Ember.set(objectToBeMoved, 'folder', selectedFolder);
      objectToBeMoved.save().then(function () {
        notify.success('Move successful');

        _this.sendAction('stopMove');
      }).catch(function () {
        notify.error('Could not move folder');

        if (!Ember.get(_this, 'isDestroying') && !Ember.get(_this, 'isDestroyed')) {
          Ember.set(_this, 'isWaiting', false);
        }
      });
    },
    actions: {
      selectFolder: function selectFolder(id) {
        var _Ember$getProperties3 = Ember.getProperties(this, ['selectedFolderId', 'currentFolderId']),
            selectedFolderId = _Ember$getProperties3.selectedFolderId,
            currentFolderId = _Ember$getProperties3.currentFolderId;

        if (selectedFolderId !== id) {
          Ember.set(this, 'selectedFolderId', id);
        } else {
          Ember.set(this, 'selectedFolderId', currentFolderId);
        }
      },
      navigateToFolder: function navigateToFolder(id) {
        Ember.set(this, 'currentFolderId', id);
        Ember.set(this, 'selectedFolderId', id);
      },
      cancel: function cancel() {
        Ember.get(this, 'stopMove')();
      },
      move: function move() {
        var _Ember$getProperties4 = Ember.getProperties(this, ['objectToBeMoved', 'selectedFolderId', 'moveButtonDisabled', 'notify', 'store']),
            objectToBeMoved = _Ember$getProperties4.objectToBeMoved,
            selectedFolderId = _Ember$getProperties4.selectedFolderId,
            moveButtonDisabled = _Ember$getProperties4.moveButtonDisabled,
            store = _Ember$getProperties4.store;

        if (!moveButtonDisabled) {
          var folder = store.peekRecord('folder', selectedFolderId);
          var type = objectToBeMoved._internalModel.modelName === 'folder' ? 'folder' : 'flight';
          var agxFolderIds = Ember.get(this, 'agx.connections') || [];

          if (type === 'flight' && agxFolderIds.findBy('botlink_folder', Ember.get(folder, 'id'))) {
            Ember.set(this, 'pendingMove', {
              flight: objectToBeMoved,
              folder: folder
            });
            Ember.set(this, 'isSettingAgxSeason', true);
            Ember.set(this, 'isShowingMoveDialog', false);
          } else {
            this.doMove(objectToBeMoved, folder);
          }
        }

        return false;
      },
      agxSaveSuccessful: function agxSaveSuccessful() {
        var _Ember$get = Ember.get(this, 'pendingMove'),
            flight = _Ember$get.flight,
            folder = _Ember$get.folder;

        this.doMove(flight, folder);
        Ember.set(this, 'pendingMove', null);
        Ember.get(this, 'stopMove')();
      },
      agxSaveFailure: function agxSaveFailure() {
        var notify = Ember.get(this, 'notify');
        notify.error("Could not save agX association.");
        Ember.set(this, 'pendingMove', null);
        Ember.get(this, 'stopMove')();
      },
      closeAgxModal: function closeAgxModal() {
        Ember.set(this, 'pendingMove', null);
        Ember.set(this, 'isSettingAgxSeason', false);
        Ember.set(this, 'isShowingMoveDialog', true);
      }
    }
  });

  _exports.default = _default;
});