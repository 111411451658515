define("superfly/pods/shared/locked/components/share-lock/component", ["exports", "ember-ajax/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    shareId: null,
    password: null,
    error: null,
    hasPassword: Ember.computed.bool('password'),
    isSubmitDisabled: Ember.computed('hasPassword', 'isLoading', function isSubmitDisabled() {
      return !Ember.get(this, 'hasPassword') || Ember.get(this, 'isLoading');
    }),
    didInsertElement: function didInsertElement() {
      this.$('input').focus();
    },
    actions: {
      submit: function submit() {
        var _this = this;

        if (Ember.get(this, 'hasPassword')) {
          var password = Ember.get(this, 'password');
          var shareId = Ember.get(this, 'shareId');
          var ajax = Ember.get(this, 'ajax');
          var adapter = Ember.get(this, 'store').adapterFor('share');
          Ember.set(this, 'error', null);
          Ember.set(this, 'isLoading', true);
          return ajax.post("".concat(adapter.buildURL('share', shareId), "/authenticate"), {
            data: {
              password: password
            }
          }).then(function (result) {
            Ember.set(_this, 'session.shareToken', result.token);
            Ember.get(_this, 'router').replaceWith('shared.index', shareId);
          }).catch(function (e) {
            if ((0, _errors.isUnauthorizedError)(e)) {
              Ember.set(_this, 'error', 'Password invalid');
            } else {
              Ember.set(_this, 'error', 'Authorization failed');
            }

            Ember.set(_this, 'isLoading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});