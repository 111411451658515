define("superfly/pods/frame/dashboard/flights/flight/components/overlay-properties-ndvi/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    overlay: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'colortable', [[255, 0, 0], [255, 50, 0], [255, 73, 0], [255, 92, 0], [255, 108, 0], [255, 122, 0], [255, 136, 0], [255, 149, 0], [255, 161, 0], [255, 174, 0], [255, 186, 0], [255, 198, 0], [255, 209, 0], [255, 221, 0], [255, 232, 0], [255, 244, 0], [255, 255, 0], [246, 255, 0], [236, 255, 0], [226, 255, 0], [217, 255, 0], [206, 255, 0], [196, 255, 0], [185, 255, 0], [174, 255, 0], [162, 255, 0], [150, 255, 0], [136, 255, 0], [122, 255, 0], [105, 255, 0], [86, 255, 0], [60, 255, 0]]);
    },
    hasNdviRangeData: Ember.computed('overlay.flight.ndviRange', function hasNdviRangeData() {
      return Ember.get(this, 'overlay.flight.ndviRange.length') > 0;
    }),
    colors: Ember.computed('colortable', function colors() {
      var colortable = Ember.get(this, 'colortable');
      var values = Ember.get(this, 'overlay.flight.ndviRange');
      var minValue = Ember.get(this, 'minValue');
      var maxValue = Ember.get(this, 'maxValue');
      var range = maxValue - minValue;
      var colorArray = [];
      colortable.forEach(function (color, index) {
        var start = (values[index] - minValue) / range * 100;
        var stop = (values[index + 1] - minValue) / range * 100;
        colorArray.pushObject({
          startOffset: start,
          stopOffset: stop,
          r: color[0],
          g: color[1],
          b: color[2]
        });
      });
      return colorArray;
    }),
    minValue: Ember.computed('overlay.overlay.flight.ndviRange', function minValue() {
      var values = Ember.get(this, 'overlay.flight.ndviRange');
      return Math.min.apply(Math, _toConsumableArray(values));
    }),
    maxValue: Ember.computed('overlay.overlay.flight.ndviRange', function minValue() {
      var values = Ember.get(this, 'overlay.flight.ndviRange');
      return Math.max.apply(Math, _toConsumableArray(values));
    }),
    normalizedValues: Ember.computed('overlay.overlay.flight.ndviRange', function normalizedValues() {
      var numberOfTicks = 9;
      var minValue = Ember.get(this, 'minValue');
      var maxValue = Ember.get(this, 'maxValue');
      var range = maxValue - minValue;
      var increment = range / (numberOfTicks - 1);
      var table = [];

      for (var i = 0; i < numberOfTicks; i++) {
        table.push(minValue + i * increment);
      }

      return table;
    })
  });

  _exports.default = _default;
});