define("superfly/pods/frame/sites/site/components/site-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'col', 'p-0'],
    site: null,
    actions: {
      onShareClick: function onShareClick() {
        console.log('On share click, TODO: Implement');
      },
      onSiteDetailsPopoverClick: function onSiteDetailsPopoverClick() {
        console.log('On onSiteDetailsPopover click, TODO: Implement');
      }
    }
  });

  _exports.default = _default;
});