define("superfly/pods/auth/components/route-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['container-fluid', 'pt-3', 'pb-3']
  });

  _exports.default = _default;
});