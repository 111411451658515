define("superfly/pods/auth/signup/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bey6X9IL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"title mt-4 mb-2 mt-lg-5 mb-lg-3\"],[9],[0,\"\\n  \"],[7,\"h4\"],[11,\"class\",\"mb-2\"],[9],[0,\"Sign up\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"d-none d-sm-block\"],[9],[0,\"\\n    Get started with a 14-day free trial of Botlink Professional. \"],[7,\"br\"],[9],[10],[0,\"\\n    No credit card needed.\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"auth/signup/components/signup-form\",null,[[\"appBeingUsed\",\"platformBeingUsed\",\"deviceString\",\"appVer\"],[[23,[\"appBeingUsed\"]],[23,[\"platformBeingUsed\"]],[23,[\"deviceString\"]],[23,[\"appVer\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"signup-action\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"Already have an account?\"],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"btn btn-text\"],[12,\"href\",[28,[[27,\"href-to\",[\"auth.login\"],null]]]],[9],[0,\"Sign in\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/auth/signup/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});