define("superfly/pods/application/components/set-agx-data-dialog/component", ["exports", "superfly/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    flight: null,
    selectedSeason: null,
    containerClass: Ember.computed('id', function () {
      return "".concat(Ember.get(this, 'componentCssClassName'), " modal-xs");
    }),
    actions: {
      seasonSelected: function seasonSelected(season) {
        Ember.set(this, 'selectedSeason', season);
      },
      save: function save() {
        var _this = this;

        var data = {
          user_id: +Ember.get(this, 'session.currentUser.id'),
          flight_id: Ember.get(this, 'flight.id'),
          season_id: Ember.get(this, 'selectedSeason.id')
        };
        return Ember.get(this, 'ajax').post("".concat(_environment.default.BOTLINK_API_URL, "/integrations/agx/users/").concat(data.user_id, "/flights"), {
          data: JSON.stringify(data)
        }).then(function () {
          Ember.get(_this, 'onSuccess')();
        }).catch(function () {
          Ember.get(_this, 'onFailure')();

          _this.send('closeModal');
        });
      },
      closeModal: function closeModal() {
        Ember.set(this, 'selectedSeason', null);
        Ember.get(this, 'close')();
      }
    }
  });

  _exports.default = _default;
});