define("superfly/pods/application/components/upload-browser-trigger/component", ["exports", "superfly/mixins/upload-browseable"], function (_exports, _uploadBrowseable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_uploadBrowseable.default, {});

  _exports.default = _default;
});