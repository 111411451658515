define("superfly/pods/frame/plans/select-plan/components/plan-chooser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qC/w9iU7",
    "block": "{\"symbols\":[\"plan\"],\"statements\":[[7,\"div\"],[11,\"class\",\"billing-rate-toggle\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"btn monthly-billing \",[27,\"unless\",[[23,[\"showMonthlyRates\"]],\"disabled\"],null]]]],[9],[0,\"\\n    Billed monthly\\n  \"],[3,\"action\",[[22,0,[]],\"setBillingRateToMonthly\"]],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"btn yearly-billing \",[27,\"if\",[[23,[\"showMonthlyRates\"]],\"disabled\"],null]]]],[9],[0,\"\\n    Billed annually\"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"small\"],[9],[0,\"2 MONTHS FREE\"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"setBillingRateToYearly\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-12 col-md-8 ml-md-auto mr-md-auto\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"plans\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"plansToDisplay\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"frame/plans/select-plan/components/plan-card\",null,[[\"plan\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/plans/select-plan/components/plan-chooser/template.hbs"
    }
  });

  _exports.default = _default;
});