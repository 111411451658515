define("superfly/pods/frame/sites/components/route-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YBJwC+11",
    "block": "{\"symbols\":[],\"statements\":[[4,\"application/components/site-sidebar\",null,[[\"classNames\"],[\"sites-nav\"]],{\"statements\":[[0,\"  \"],[1,[27,\"frame/sites/components/sites-nav\",null,[[\"sites\",\"products\"],[[23,[\"model\",\"sites\"]],[23,[\"model\",\"products\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/sites/components/route-component/template.hbs"
    }
  });

  _exports.default = _default;
});