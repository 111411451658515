define("superfly/pods/frame/plans/components/plans-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oq2O0foV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"dashboard\"],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"src\",\"/assets/images/botlink_logo_small.png\"],[11,\"alt\",\"botlink logo\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/plans/components/plans-header/template.hbs"
    }
  });

  _exports.default = _default;
});