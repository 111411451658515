define("superfly/utils/layer-area", ["exports", "lgeo"], function (_exports, _lgeo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = layerArea;

  function layerArea(layer) {
    if (!layer) {
      return null;
    }

    return _lgeo.default.area(layer).toFixed(2); // meters
  }
});