define("superfly/utils/normalize-flight-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeFlightType;

  function normalizeFlightType(type) {
    switch (type.toLowerCase()) {
      case 'ndvi':
        return 'Vegetation Index';

      case 'threedimensional':
        return '3D Model';

      default:
        return 'Survey';
    }
  }
});