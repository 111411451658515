define("superfly/helpers/static-flight-map", ["exports", "superfly/utils/static-flight-map"], function (_exports, _staticFlightMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.staticFlightMap = staticFlightMap;
  _exports.default = void 0;

  function staticFlightMap(params, hash) {
    var flight = hash.flight,
        width = hash.width,
        height = hash.height;
    return (0, _staticFlightMap.default)(flight, width, height);
  }

  var _default = Ember.Helper.helper(staticFlightMap);

  _exports.default = _default;
});