define("superfly/pods/application/components/user-fab-dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['user-fab'],
    session: Ember.inject.service(),
    user: Ember.computed.alias('session.currentUser'),
    showMenu: false,
    email: Ember.computed('user.email', function email() {
      return Ember.get(this, 'user.email') || 'user@botlink.com';
    }),
    actions: {
      toggleMenu: function toggleMenu() {
        if (Ember.get(this, 'showMenu') === true) {
          this.send('close');
        } else {
          this.send('open');
        }
      },
      open: function open() {
        var _this = this;

        Ember.set(this, 'showMenu', true);
        this.$().on('mouseleave', '.user-fab-dropdown-container', function () {
          return _this.send('close');
        });
      },
      close: function close() {
        var _this2 = this;

        Ember.set(this, 'showMenu', false);
        this.$().off('mouseleave', '.user-fab-dropdown-container', function () {
          return _this2.send('close');
        });
      },
      linkTo: function linkTo(route) {
        this.get('router').transitionTo(route);
      }
    }
  });

  _exports.default = _default;
});