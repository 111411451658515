define("superfly/services/facebook-pixel", ["exports", "superfly/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    id: _environment.default.FACEBOOK_PIXEL_ID,
    isEnabled: Ember.computed.notEmpty('id'),
    setup: Ember.on('init', function () {
      if (this.get('isEnabled')) {
        fbq('init', this.id);
      }
    }),
    track: function track(event, params) {
      if (this.get('isEnabled')) {
        fbq('track', event, params);
      }
    }
  });

  _exports.default = _default;
});