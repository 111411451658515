define("superfly/pods/application/components/upload-status-tray/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TcETBc1Z",
    "block": "{\"symbols\":[\"collection\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"upload-menu \",[27,\"unless\",[[23,[\"isVisible\"]],\"hidden\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"status\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isCurrentlyUploading\"]]],null,{\"statements\":[[0,\"        Photos Uploading...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Uploads\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"minimize\"]],[10],[0,\"\\n\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"body \",[27,\"unless\",[[23,[\"isListVisible\"]],\"hidden\"],null]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"collectionsToDisplay\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"application/components/flight-upload-status\",null,[[\"collection\",\"dismissUploadSet\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"dismissUploadSet\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/upload-status-tray/template.hbs"
    }
  });

  _exports.default = _default;
});