define("superfly/pods/frame/settings/billing/components/payment-method-form-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    subscriptionManager: Ember.inject.service(),
    buttonText: Ember.computed('subscriptionManager.paymentSource', function () {
      var paymentSource = Ember.get(this, 'subscriptionManager.paymentSource');
      return paymentSource ? 'Update Card' : 'Add Card';
    }),
    isLoading: false,
    errorMsg: null,
    modalContainerClass: Ember.computed('id', function containerClass() {
      return "".concat(Ember.get(this, 'componentCssClassName'), " update-card-form-modal");
    }),
    actions: {
      submit: function submit() {
        Ember.set(this, 'isLoading', true);
        Ember.set(this, 'errorMsg', null);
        this.toggleProperty('triggerPaymentMethodSubmit');
      },
      onSuccess: function onSuccess() {
        Ember.set(this, 'isLoading', false);
        Ember.set(this, 'errorMsg', null);
        Ember.get(this, 'onSuccess')();
      },
      onFailure: function onFailure(err) {
        Ember.set(this, 'isLoading', false);
        Ember.get(this, 'onFailure')(err);

        if (err) {
          if (err.error) {
            Ember.set(this, 'errorMsg', err.error.message);
          } else {
            Ember.set(this, 'errorMsg', err.message);
          }
        }
      },
      closeModal: function closeModal() {
        Ember.get(this, 'closeModal')();
      }
    }
  });

  _exports.default = _default;
});