define("superfly/models/stitched-asset", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    agxStitchedPath: attr('string'),
    assetType: attr('string'),
    bounds: attr(),
    dsmPath: attr('string'),
    fileData: attr(),
    fileName: attr('string'),
    filePath: attr('string'),
    fileUrl: attr('string'),
    filterType: attr('string'),
    flightID: attr('string'),
    isProcessed: attr('boolean'),
    jpegPath: attr('string'),
    pngPath: attr('string'),
    shpPath: attr('string'),
    thumbnailPath: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    deletedAt: attr('date'),
    flight: belongsTo('flight'),
    company: belongsTo('company'),
    displayType: Ember.computed('filterType', function () {
      var filterType = Ember.get(this, 'filterType');

      switch (filterType) {
        case 'index':
          return 'reflectance map';

        case 'ndvi':
          return Ember.get(this, 'isProcessed') ? 'NDVI' : 'orthomosaic';

        default:
          return 'orthomosaic';
      }
    })
  });

  _exports.default = _default;
});