define("superfly/initializers/component-styles", ["exports", "ember-component-css/initializers/component-styles"], function (_exports, _componentStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _componentStyles.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _componentStyles.initialize;
    }
  });
});