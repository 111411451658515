define("superfly/services/raven", ["exports", "ember-data", "ember-cli-deploy-sentry/services/raven", "ember-ajax/errors"], function (_exports, _emberData, _raven, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raven.default.extend({
    session: Ember.inject.service(),
    unhandledPromiseErrorMessage: '',
    sessionContext: function sessionContext() {
      var context = {
        extra: {}
      };
      var user = Ember.get(this, 'session.currentUser');

      if (user) {
        context.user = user;
      }

      var company = Ember.get(this, 'session.currentCompany');

      if (company) {
        context.extra.company = company;
      }

      return context;
    },
    captureException: function captureException(error) {
      console.log(error);

      if (!(error instanceof _emberData.default.AdapterError) && !(0, _errors.isAjaxError)(error)) {
        this._super(error, this.sessionContext());
      }
    },
    captureMessage: function captureMessage(message) {
      console.log(message);
      return this._super(message, this.sessionContext());
    },
    enableGlobalErrorCatching: function enableGlobalErrorCatching() {
      return this._super.apply(this, arguments);
    },
    ignoreError: function ignoreError() {
      return this._super();
    },
    callRaven: function callRaven() {
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});