define("superfly/pods/application/components/main-map-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wTENmoN9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"map-wrap \",[27,\"if\",[[23,[\"sketchfabActive\"]],\"hidden\"],null]]]],[9],[0,\"\\n  \"],[1,[21,\"application/components/map-controls\"],false],[0,\"\\n  \"],[1,[21,\"application/components/style-selector\"],false],[0,\"\\n  \"],[1,[21,\"application/components/main-map\"],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"map-attribution\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"https://www.mapbox.com/about/maps/\"],[11,\"target\",\"_system\"],[9],[0,\"© Mapbox © OpenStreetMap\"],[10],[0,\" \"],[7,\"a\"],[11,\"class\",\"mapbox-improve-map\"],[11,\"href\",\"https://www.mapbox.com/map-feedback/\"],[11,\"target\",\"_system\"],[9],[0,\"Improve this map\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"sketchfabActive\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"application/components/sketchfab-viewer\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/application/components/main-map-group/template.hbs"
    }
  });

  _exports.default = _default;
});