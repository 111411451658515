define("superfly/pods/frame/settings/components/settings-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['nav', 'flex-column'],
    sidebar: Ember.inject.service(),
    actions: {
      closeSidebar: function closeSidebar() {
        Ember.get(this, 'sidebar').trigger('hide');
      }
    }
  });

  _exports.default = _default;
});